<template>
  <div class="background unvergessen-shadow">
    <v-img :src="srcUrl" :lazy-src="lazy_src" :alt="altTag" v-if="srcUrl !== ''" max-height="600px">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <p class="font-weight-regular" style="margin: 0; padding-top: 30px; text-align: center">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: ['srcUrl', 'altTag'],
  data () {
    return {
      imageFound: true
    }
  },
  methods: {
    lazyTransformation (str) {
      var strArray = str.split('.')
      strArray[strArray.length - 2] = strArray[strArray.length - 2] + '_lazy'
      return strArray.join('.')
    }
  },
  computed: {
    lazy_src () {
      return (this.srcUrl) ? this.lazyTransformation(this.srcUrl) : this.srcUrl
    }
  }
}
</script>

<style scoped>
  .background {
    background-color: white;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 4%;
    border-radius: 7px
  }

  .loading {
    min-height: 425px !important;
    min-width: 638px !important
  }
</style>
