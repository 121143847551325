import { render, staticRenderFns } from "./Subfooter.vue?vue&type=template&id=98c43932&scoped=true&"
var script = {}
import style0 from "./Subfooter.vue?vue&type=style&index=0&id=98c43932&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98c43932",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VIcon,VSpacer,VTabs})
