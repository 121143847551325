<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100" viewBox="0 0 1920 100" :style="{ 'background-color': background }">
    <path id="Pfad_834" data-name="Pfad 834" :style="{ fill: fill }" d="M0-114.553S552.07-38.623,985.566-31.3,1920-114.553,1920-114.553V311.324H0Z" transform="translate(1920 -30) rotate(180)"/>
  </svg>
</template>

<script>
export default {
  props: ['background', 'fill']
}
</script>
