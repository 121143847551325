<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px" v-ripple="false">
      <!-- Heading -->
      <div class="font-weight-medium" :class="{ headline: !$vuetify.breakpoint.xs, title: $vuetify.breakpoint.xs }" style="padding: 10px; text-align: center">
        Der Ort, an dem Erinnerungen <br v-if="$vuetify.breakpoint.xs">ein Zuhause finden
      </div>

      <div style="background-size: cover" :style="{ 'background-image': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'url(\'' + 'https://resources.eternio.com/static/home/Home_Familienfest_Weich_m.jpg' + '\')' : 'url(\'' + 'https://resources.eternio.com/static/home/Home_Familienfest_Weich.jpg' + '\')' }">
        <div style="background-color: rgba(255, 255, 255, 0.3)">
          <v-container>
            <v-row>
              <v-col>
                <v-img :width="$vuetify.breakpoint.xs ? '80%' : '55%'" style="margin: auto" src="https://resources.eternio.com/static/home/Familienfest.png" alt="Familienfest"></v-img>
              </v-col>
              <v-col>
                <div
                  :class="{ title: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm, headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  style="text-align: center;"
                  :style="{ 'margin-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0' : '60px' }"
                >
                  <div :style="{ width: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '65%' : '90%' }" class="font-weight-medium white--text" style="margin: auto;">
                    <p v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin-bottom: 30px"><i>Denn die wirklich traurigen Geschichten sind die, die niemand mehr erzählt</i></p>
                    <p v-else style="font-size: 16px">Erinnerungen teilen • Trauer heilen</p>
                    <unvergessen-button :mobile="true" @click="dialog=true">Erstelle eine Gedenkseite</unvergessen-button>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>

    <unvergessen-new-page-dialog :dialog="dialog" @close="dialog = false"></unvergessen-new-page-dialog>
  </div>
</template>

<script>
import NewPageDialog from '@/components/global/NewPageDialog.vue'

export default {
  data () {
    return {
      lazy: false,
      dialog: false
    }
  },
  components: {
    unvergessenNewPageDialog: NewPageDialog
  }
}
</script>
