import axios from 'axios'
import router from '@/router/router'

const state = {
  page: {},
  createData: {},
  candles: []
}

const getters = {
  description: state => {
    return state.page.description || ''
  },
  pageSettings: state => {
    return state.page.settings
  },
  banner: state => {
    return (state.page.banner) ? state.page.banner : ''
  },
  skeleton: state => {
    return (state.page.pageSkeleton) ? state.page.pageSkeleton : ''
  },
  comments: state => {
    return (state.page.comments) ? state.page.comments : []
  },
  footage: state => {
    return (state.page.footage) ? state.page.footage : []
  },
  flowers: state => {
    return state.page.flowers || {}
  },
  memorial_dates: state => {
    return (state.page.memorial_dates) ? state.page.memorial_dates : ''
  },
  editable: state => {
    return (state.page.editable) ? state.page.editable === 'true' : false
  },
  premium: state => {
    return (state.page.premium) ? (state.page.premium) : false
  },
  createData: state => {
    return state.createData || {}
  },
  isVerified: state => {
    return (state.page.pageSkeleton) ? state.page.pageSkeleton.isV || false : false
  },
  donation: state => {
    return state.page.donation || {}
  },
  partner: state => {
    return state.page.verified ? state.page.verified.institution || false : false
  },
  verificationStatus: state => {
    return state.page.verified ? state.page.verified.status || false : false
  },
  pageInTransfer: state => {
    return state.page.verified ? state.page.verified.transfer || false : false
  },
  partnerWebpage: state => {
    return state.page.verified ? state.page.verified.web || false : false
  },
  getCandles: state => {
    return state.page.candles
  },
  sympathy: state => {
    return state.page.sympathy
  },
  visitors: state => {
    return state.page.visitors
  },
  flower: state => {
    return state.page.flower || { color: 'white' }
  },
  products: state => {
    return state.page.products || false
  }
}

const mutations = {
  clearPage: state => {
    state.page = {}
    state.createData = {}
    state.candles = []
  },
  deleteProductPreview: state => {
    state.page.products = []
  },
  incSympathy: state => {
    state.page.sympathy = state.page.sympathy + 1
  },
  setPage: (state, payload) => {
    state.page = payload
    if (state.page.pageSkeleton.obit.includes('T')) {
      state.page.pageSkeleton.obit = state.page.pageSkeleton.obit.split('T')[0]
    }
  },
  setCandles: (state, payload) => {
    state.page.candles = payload
  },
  addPremiumCandle: (state, payload) => {
    state.page.candles.unshift(payload)
  },
  setCreateData: (state, payload) => {
    state.createData = payload
  },
  setFootage: (state, payload) => {
    for (var picture of payload.footage) state.page.footage.unshift(picture)
  },
  deletePicture: (state, payload) => {
    for (var i = 0; i < state.page.footage.length; i++) {
      if (state.page.footage[i]._id === payload) {
        state.page.footage.splice(i, 1)
        return
      }
    }
  },
  setSkeleton: (state, payload) => {
    state.page.pageSkeleton = payload
  },
  setDescription: (state, payload) => {
    state.page.description = payload
  },
  addToMemorialDates: (state, payload) => {
    payload.heading = payload.title
    payload.text = payload.description
    delete payload.description
    delete payload.title
    state.page.memorial_dates.push(payload)
  },
  editMemorialDate: (state, payload) => {
    payload.heading = payload.title
    payload.text = payload.description
    delete payload.description
    delete payload.title
    for (var i = 0; i < state.page.memorial_dates.length; i++) {
      if (state.page.memorial_dates[i]._id === payload._id) {
        state.page.memorial_dates[i] = payload
      }
    }
  },
  deleteMemorialDate: (state, payload) => {
    var i = 0
    while (i < state.page.memorial_dates.length) {
      if (state.page.memorial_dates[i]._id === payload) break
      i++
    }
    state.page.memorial_dates.splice(i, 1)
  },
  setBanner: (state, payload) => {
    state.page.banner = payload
  },
  setDonation: (state, payload) => {
    state.page.donation = payload
  },
  addComment: (state, payload) => {
    state.page.comments.content.unshift(payload)
  },
  loadComments: (state, payload) => {
    state.page.comments.content = state.page.comments.content.concat(payload)
  },
  deleteComment: (state, payload) => {
    var i = 0
    while (i < state.page.comments.content.length) {
      if (state.page.comments.content[i]._id === payload.id) break
      i++
    }
    state.page.comments.content.splice(i, 1)
  }
}

const actions = {
  createFromAd: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/page/createFromAd', { page_id: payload.page_id })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  incSympathy: (context) => {
    context.commit('incSympathy')
  },
  setPage: (context, payload) => {
    payload.description = payload.description ? payload.description : ''
    payload.sympathy = payload.sympathy || 0
    payload.visitors = payload.visitors || 0
    payload.banner = payload.banner || process.env.VUE_APP_STORAGE + 'banner/Titelbild_Ast_mit_Schnee.jpg'
    payload.donation = payload.donation || {}
    payload.comments = payload.comments || { content: [] }
    context.commit('setPage', payload)
  },
  setCandles: (context, payload) => {
    context.commit('setCandles', payload)
  },
  setBanner: (context, payload) => {
    context.commit('setBanner', payload)
  },
  reloadPage: (context) => {
    axios.get('/page', { params: { id: router.history.current.params.page_id } })
      .then(res => {
        context.commit('setPage', res.data)
      })
      .catch(error => {
        this.$router.push({ name: 'Home' })
      })
  },
  setCreateData: (context, payload) => {
    context.commit('setCreateData', payload)
  },
  setFootage: (context, payload) => {
    context.commit('setFootage', payload)
  },
  deletePicture: (context, payload) => {
    context.commit('deletePicture', payload)
  },
  setSkeleton: (context, payload) => {
    context.commit('setSkeleton', payload)
  },
  setDescription: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/edit/memorialText', { page_id: payload.page_id, heading: payload.data.heading, text: payload.data.content })
        .then(res => {
          context.commit('setDescription', payload.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateDescription: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/edit/memorialText', { page_id: payload.page_id, heading: payload.data.heading, text: payload.data.content })
        .then(res => {
          context.commit('setDescription', payload.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addToMemorialDates: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/edit/memorialDate', { page_id: payload.page_id, memdate: payload.memDate })
        .then(res => {
          if (res.data.id) payload.memDate._id = res.data.id
          context.commit('addToMemorialDates', payload.memDate)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editMemorialDate: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/edit/memorialDate', { page_id: payload.page_id, memdate: payload.memDate, dateID: payload.memDate._id })
        .then(res => {
          context.commit('editMemorialDate', payload.memDate)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteMemorialDate: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.delete('/edit/memorialDate', { data: { page_id: payload.page_id, dateID: payload.id } })
        .then(res => {
          context.commit('deleteMemorialDate', payload.id)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setDonation: (context, payload) => {
    context.commit('setDonation', payload)
  },
  addComment: (context, payload) => {
    context.commit('addComment', payload)
  },
  loadComments: (context, payload) => {
    context.commit('loadComments', payload)
  },
  deleteComment: (context, payload) => {
    context.commit('deleteComment', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
