<template>
  <v-card>
    <div style="width: 98%; padding-top: 2%; position: absolute">
      <v-icon color="primary" style="float: right; cursor: pointer" @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div style="padding: 5% 10%; text-align: center">
      <div :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm }" v-if="bears">
        Du möchtest ein Unvergessen Bärchen?
      </div>
      <div :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm }" v-else>
        Du benötigst Unterstützung?
      </div>
      <div class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding: 2% 0">
        Hier kannst du einfach mit uns Kontakt aufnehmen. Im Normalfall erhältst du noch am selben Tag, spätestens aber am nächsten Tag eine Antwort.
        <v-alert type="error" v-model="error">
          Deine Nachricht konnte nicht abgeschickt werden. Bitte versuche es erneut
        </v-alert>
      </div>

      <v-form @submit.prevent="submit" onSubmit="return false;"
        ref="form"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              :dense="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              v-model="author.prename"
              outlined
              required
              :rules="nonEmptyRules"
              label="Vorname"
              placeholder="Vornamen eingeben"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :dense="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              v-model="author.surname"
              outlined
              required
              :rules="nonEmptyRules"
              label="Nachname"
              placeholder="Nachnamen eingeben"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          :dense="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          v-model="email"
          outlined
          required
          :rules="emailRule"
          label="E-Mail-Adresse"
          placeholder="E-Mail-Adresse eingeben"
        ></v-text-field>

        <v-text-field
          :dense="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          v-model="subject"
          outlined
          label="Betreff"
          placeholder="Betreff eingeben"
        ></v-text-field>

        <v-textarea
          v-model="text"
          outlined
          required
          :rules="nonEmptyRules"
          label="Nachricht"
          rows="4"
          placeholder="Deine Nachricht..."
        ></v-textarea>

        <v-checkbox
          v-model="checked"
          style="margin-top: 0"
        >
          <template v-slot:label>
            <div>
              Ich habe die
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a target="_blank" href="/Datenschutz" @click.stop v-on="on">Datenschutzerklärung</a>
              </template>
              <p>
                Öffnet sich in einem neuen Fenster.
              </p>
              </v-tooltip>
              gelesen und stimme dieser zu.
            </div>
          </template>
        </v-checkbox>
      </v-form>
      <div>
        <unvergessen-button :mobile="true" @click="sendMessage">
          Nachricht senden
        </unvergessen-button>
      </div>
    </div>
    <unvergessen-newsletter-dialog :dialog="newsletterDialog" @dialog="newsletterDialog = false"></unvergessen-newsletter-dialog>
    <unvergessen-success-dialog :dialog="success" @close="success = false" buttonText="OK" heading="Vielen Dank!" subheading="Deine Nachricht wurde erfolgreich abgeschickt">
      Wir werden uns zeitnah bei dir melden!<br>
      Mit unserem <span class="primary--text" style="cursor: pointer" @click="newsletterDialog = true; success = false">Newsletter</span> kannst du auch generell auf dem Laufenden bleiben.
    </unvergessen-success-dialog>
  </v-card>
</template>

<script>
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import NewsletterDialog from '@/components/global/NewsletterDialog.vue'
import axios from 'axios'

export default {
  props: ['bears'],
  data () {
    return {
      newsletterDialog: false,
      text: '',
      subject: '',
      email: '',
      author: {
        prename: '',
        surname: ''
      },
      checked: false,
      success: false,
      error: false,
      // rulse
      nonEmptyRules: [
        v => !!v || 'Eintrag wird benötigt'
      ],
      emailRule: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (pattern.test(value)) {
            this.isMailValid = true
            return pattern.test(value)
          } else {
            this.isMailValid = false
            return 'Bitte gib eine gültige E-Mail-Adresse ein.'
          }
        }
      ]
    }
  },
  methods: {
    sendMessage () {
      if (this.$refs.form.validate() && this.checked) {
        var data = {
          text: this.text,
          author: this.author.prename + ' ' + this.author.surname,
          mail: this.email,
          subject: this.subject
        }
        axios.post('/contact', data)
          .then(res => {
            this.success = true
            this.error = false
            this.text = ''
            this.author = {
              prename: '',
              surname: ''
            }
            this.email = ''
            this.subject = ''
            this.$emit('close')
          })
          .catch(error => {
            this.error = true
            this.success = false
          })
      }
    }
  },
  components: {
    unvergessenSuccessDialog: SuccessDialog,
    unvergessenNewsletterDialog: NewsletterDialog
  }
}
</script>
