import axios from 'axios'

const state = {
  user: {}
}

const getters = {
  prename: state => {
    return state.user.prename || ''
  },
  surname: state => {
    return state.user.surname || ''
  },
  birth: state => {
    return state.user.birth || ''
  },
  email: state => {
    return state.user.email || ''
  },
  profile_image: state => {
    return state.user.profile_image || ''
  },
  userPages: state => {
    return state.user.pages || []
  },
  settings: state => {
    var data = {
      md: false,
      nf: false,
      pa: false,
      nl: false
    }
    return state.user.notifications || data
  },
  isP: state => {
    return state.user.isP
  },
  isFbUser: state => {
    return state.user.fb_id !== undefined
  }
}

const mutations = {
  setUser: (state, payload) => {
    state.user = payload
  },
  setPrename: (state, payload) => {
    state.user.prename = payload
  },
  setSurname: (state, payload) => {
    state.user.surname = payload
  },
  setBirth: (state, payload) => {
    state.user.birth = payload
  },
  setEmail: (state, payload) => {
    state.user.email = payload
  },
  setProfileImage: (state, payload) => {
    state.user.profile_image = payload
  },
  setSettings: (state, payload) => {
    state.user.notifications = payload
  },
  setUserSkeleton: (state, payload) => {
    for (var i = 0; i < state.user.pages.length; i++) {
      if (state.user.pages[i].page_id === payload.page_id) {
        state.user.pages[i] = payload.skeleton
      }
    }
  },
  deleteUser: (state) => {
    state.user = {}
  }
}

const actions = {
  setUser: (context, payload) => {
    context.commit('setUser', payload)
  },
  loadUser: context => {
    return new Promise((resolve, reject) => {
      axios.get('/user')
        .then(res => {
          var data = {
            mscg: res.data.mscg || 0,
            fb_id: res.data.fb_id || undefined,
            notes: res.data.notes || '',
            pages: res.data.pages || [],
            prename: res.data.prename || '',
            surname: res.data.surname || '',
            birth: res.data.birth || '',
            email: res.data.email || '',
            isP: res.data.isP || false,
            isV: res.data.isV || false,
            profile_image: res.data.profile_image || '',
            notifications: (res.data.notifications) ? {
              md: res.data.notifications.md,
              nf: res.data.notifications.nf,
              pa: res.data.notifications.pa,
              nl: res.data.notifications.nl
            } : {
              md: false,
              nf: false,
              pa: false,
              nl: false
            }
          }
          context.commit('setUser', data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setPrename: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { prename: payload } })
        .then(res => {
          context.commit('setPrename', payload)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setSurname: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { surname: payload } })
        .then(res => {
          context.commit('setSurname', payload)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setBirth: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { birth: payload } })
        .then(res => {
          context.commit('setBirth', payload)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setEmail: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { email: payload } })
        .then(res => {
          context.commit('setEmail', payload)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setProfileImage: (context, payload) => {
    context.commit('setProfileImage', payload)
  },
  setSettings: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/notifications', { newSettings: payload })
        .then(res => {
          context.commit('setSettings', payload)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setUserSkeleton: (context, payload) => {
    context.commit('setUserSkeleton', payload)
  },
  deleteUser: (context) => {
    context.commit('deleteUser')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
