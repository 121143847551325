<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      nudge-bottom="20"
      offset-y
      left
      :nudge-width="300"
    >
      <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon class="unvergessen-nav-bar-icon" style="background-color: rgba(64,64,64,0.3)"><v-icon color="primary" large v-on="on" @click="loadInfo()">$account-white</v-icon></v-app-bar-nav-icon>
      </template>
      <v-card>
        <div style="max-height: 80vh; overflow: auto">
          <unvergessen-page-settings :page="page" v-for="page in pages" :key="page.page_id"></unvergessen-page-settings>

        <v-divider></v-divider>

        <v-list flat dense class="unvergessen-list">
          <v-list-item class="unvergessen-list-item">
            <v-list-item-content class="unvergessen-list-item-content">
              <v-list-item-title>Mein Konto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-hover v-slot:default="{ hover }">
              <v-list-item
                :class="hover ? 'unvergessen-hover' : ''"
                :to="{ name: 'Account' }"
              >
                <v-list-item-icon>
                  <v-icon color="primary">$account</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="unvergessen-list-item-content">
                  <v-list-item-title>
                    Zu meinem Konto
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
            <v-divider></v-divider>
            <v-hover v-slot:default="{ hover }">
              <v-list-item
                :class="hover ? 'unvergessen-hover' : ''"
                @click="addMemorialPage()"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-plus-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="unvergessen-list-item-content">
                  <v-list-item-title>
                    Neue Gedenkseite erstellen
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
            <v-divider></v-divider>
            <v-hover v-slot:default="{ hover }">
              <v-list-item
                :class="hover ? 'unvergessen-hover' : ''"
                :to="{ name: 'AccountSettings' }"
              >
                <v-list-item-icon>
                  <v-icon color="primary">$cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="unvergessen-list-item-content">
                  <v-list-item-title>
                    Kontoeinstellungen
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
            <v-divider></v-divider>
            <v-hover v-slot:default="{ hover }">
              <v-list-item
                :class="hover ? 'unvergessen-hover' : ''"
                :to="{ name: 'Profile' }"
              >
                <v-list-item-icon>
                  <v-icon color="primary">$lock</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="unvergessen-list-item-content">
                  <v-list-item-title>
                    Profil & Zugangsdaten
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
            <v-divider></v-divider>
            <v-hover v-slot:default="{ hover }">
              <v-list-item
                :class="hover ? 'unvergessen-hover' : ''"
                @click="logout()"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="unvergessen-list-item-content">
                  <v-list-item-title>
                    Abmelden
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </v-list-item-group>
        </v-list>
        </div>
      </v-card>
    </v-menu>
    <unvergessen-new-page-dialog :dialog="dialog" @close="closeDialog"></unvergessen-new-page-dialog>
  </div>
</template>

<script>
import PageSettings from './menu_dropdowns/MemorialPageSettings.vue'
import NewPageDialog from '@/components/global/NewPageDialog.vue'

export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    logout () {
      // if route contains /page stay and log out
      if (this.$route.path.includes('/Gedenkseite') && !this.$route.path.includes('/Zahlung') && !this.$route.path.includes('/Einstellungen') && this.isV) {
        this.$store.dispatch('logout')
      } else {
        this.$router.push({ name: 'Home' }).catch(error => {})
        this.$store.dispatch('logout')
      }
    },
    loadInfo () {
      this.$store.dispatch('loadUser')
    },
    addMemorialPage () {
      // Placeholder Message => Later vuex
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  },
  computed: {
    pages () {
      return this.$store.getters.userPages
    },
    isV () {
      return this.$store.getters.isVerified
    },
    isP () {
      return this.$store.getters.isP
    }
  },
  components: {
    'unvergessen-page-settings': PageSettings,
    'unvergessen-new-page-dialog': NewPageDialog
  }
}
</script>

<style scoped>
  .unvergessen-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .unvergessen-list-item {
    background-color: var(--v-secondary-base)
  }

  .unvergessen-list-item-content {
    padding: 0px;
  }

  .unvergessen-hover {
    background-color: var(--v-secondary-hover-base)
  }
</style>
