<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px" v-ripple="false">
      <!-- Heading -->
      <div class="font-weight-medium" :class="{ headline: !$vuetify.breakpoint.xs, title: $vuetify.breakpoint.xs }" style="padding: 10px; text-align: center">
        Der Unvergessen Blog: <br v-if="$vuetify.breakpoint.xs">Keine Tabus. Offen und ehrlich.
      </div>

      <div style="background-size: cover; background-image: url('https://eternio-static-loads.s3.eu-central-1.amazonaws.com/team/Kind_alleine_auf_Bank.jpg')">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8)">
          <v-carousel
            hide-delimiters
            hide-delimiter-background
            :height="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '250px' : ''"
            style="overflow: visible"
          >
            <v-carousel-item v-for="(article, i) in articles" :key="i" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) }">
              <div style="margin: auto; padding: 2%" :style="{ width: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '60%' : '50%', 'margin-top': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '4px' : '' }">
                <v-row>
                  <v-col cols="12">
                    <v-card height="100%" style="border-radius: 10px" class="unvergessen-shadow" @click="$router.push({ name: 'BlogArticle', params: { article_id: article.id } })">
                      <v-img :src="article.src" width="100%" :alt="article.text"></v-img>
                      <div class="white" style="padding: 10px" :style="{ 'font-size': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '12px' : '', 'min-height': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '70px' : '85px' }">
                        {{ article.text }}
                      </div>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="6" class="hidden-sm-and-down">
                    <v-card height="100%" style="border-radius: 10px" class="unvergessen-shadow" @click="$router.push({ name: 'BlogArticle', params: { article_id: article.id } })">
                      <v-img :src="article.src" width="100%" :alt="article.text"></v-img>
                      <div class="white" style="padding: 10px" :style="{ 'font-size': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '12px' : '', 'min-height': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '70px' : '85px' }">
                        {{ article.text }}
                      </div>
                    </v-card>
                  </v-col> -->
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      articles: []
    }
  },
  created () {
    axios.get('/blog')
      .then(res => {
        this.articles = res.data
      })
      .catch(() => {})
  }
}
</script>
