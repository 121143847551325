const state = {
  payment: {
    paymentInfo: {},
    pricing: ''
  },
  spk: process.env.VUE_APP_STRIPE_SPK,
  stripe: undefined,
  pageDonation: {
    variant: '',
    amount: 0
  }
}

const getters = {
  pricing: state => {
    return state.payment.pricing || ''
  },
  paymentInfo: state => {
    return state.payment.paymentInfo || {}
  },
  spk: state => {
    return state.spk
  },
  stripe: state => {
    return state.stripe
  },
  pageDonation: state => {
    return state.pageDonation
  }
}

const mutations = {
  setPricing: (state, payload) => {
    state.payment.pricing = payload
  },
  setPageDonation: (state, payload) => {
    state.pageDonation = payload
  },
  setPageDonationVariant: (state, payload) => {
    state.pageDonation.variant = payload
  },
  setPageDonationAmount: (state, payload) => {
    state.pageDonation.amount = payload
  },
  setPaymentInfo: (state, payload) => {
    state.payment.paymentInfo = payload
  },
  setStripe: (state, payload) => {
    state.stripe = payload
  }
}

const actions = {
  setPricing: (context, payload) => {
    context.commit('setPricing', payload)
  },
  setPageDonation: (context, payload) => {
    context.commit('setPageDonation', payload)
  },
  setPaymentInfo: (context, payload) => {
    context.commit('setPaymentInfo', payload)
  },
  setStripe: (context, payload) => {
    context.commit('setStripe', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
