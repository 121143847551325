import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=286b5924&scoped=true&"
var script = {}
import style0 from "./Account.vue?vue&type=style&index=0&id=286b5924&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286b5924",
  null
  
)

export default component.exports