<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px" v-ripple="false">
      <!-- Heading -->
      <div class="font-weight-medium" :class="{ headline: !$vuetify.breakpoint.xs, title: $vuetify.breakpoint.xs }" style="padding: 10px; text-align: center">
        Unvergessen.de ist bekannt aus
      </div>

      <div style="background-size: cover" :style="{ 'background-image': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'url(\'' + 'https://resources.eternio.com/static/home/Home_Zeitungen_m.jpg' + '\')' : 'url(\'' + 'https://resources.eternio.com/static/home/Home_Zeitungen.jpg' + '\')' }">
        <div style="background-color: rgba(255, 255, 255, 0.8)" :style="{ padding: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '30px 0 10px' : '40px 5% 0' }" >
          <v-carousel
            :light="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
            :hide-delimiters="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
            hide-delimiter-background
            :height="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '550px' : '400px'"
            style="overflow: visible"
            :show-arrows="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
          >
            <v-carousel-item v-for="(quote, i) in quotes" :key="i" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) }">
              <unvergessen-quote-card :quote="quote"></unvergessen-quote-card>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import QuoteCard from '@/components/home/old/QuoteCard.vue'
export default {
  data () {
    return {
      lazy: false,
      quotes: [
        {
          link: '',
          logo: '/logos/Horizont.png',
          institution: 'Horizont',
          text: 'Unvergessen.de verfolgt die Mission, den Trauernden Tag für Tag einen modernen und gesunden Weg zu bieten, mit dem Verlust eines geliebten Menschen umzugehen. (…)\nMit ihrer Arbeit versuchen sie täglich die Art und Weise zu verändern, wie die Welt mit Trauer und Trauernden umgeht.',
          date: '',
          logoWidth: '200px'
        },
        {
          link: '',
          logo: '/logos/OMR.png',
          institution: 'OMR',
          text: 'Über 50.000 Menschen haben bereits auf der Plattform Unvergessen.de Gedenkseiten für Verstorbene angelegt und Kerzen angezündet. (…)\nSie schaffen einen Ort, wo Trauernde zusammenkommen und ihrem Verlust Ausdruck verleihen können.',
          date: '2020',
          logoWidth: '200px'
        },
        {
          link: 'https://www.rheinpfalz.de/lokal/saarland_artikel,-unvergessen-digitaler-gedenk-friedhof-im-internet-_arid,5102999.html?reduced=true',
          logo: '/logos/1200px-Die_Rheinpfalz_logo.jpg',
          institution: 'Die Rheinpfalz',
          text: 'Gehen alle Erinnerungen (…) nach dem Tod verloren?\nNein. Auf der Internetseite (…) werden Erinnerungen für die Zukunft bewahrt.',
          date: '2020',
          logoWidth: '200px'
        },
        {
          link: 'https://www.saarbruecker-zeitung.de/saarland/saar-wirtschaft/start-up-unvergessen-aus-saarbruecken-bietet-plattform-fuer-trauer-online_aid-52571853',
          logo: '/logos/341px-Saarbrücker_Zeitung_Logo.jpg',
          institution: 'Saarbrücker Zeitung',
          text: 'Wer seine Trauer ausdrücken will, kann auf einer Gedenkseite alles veröffentlichen, was an den geliebten Verstorbenen erinnert.',
          date: '2021',
          logoWidth: '100px'
        },
        {
          link: 'https://www.deutsche-startups.de/tag/unvergessen/',
          logo: '/logos/logo@2.png',
          institution: 'Deutsche Startups',
          text: 'Die Zeit ist längst reif für zeitgemäße und digitale Traueranzeigen. Unvergessen kann (...) diesen Markt aufrollen.',
          date: '2021',
          logoWidth: '300px'
        },
        {
          link: 'https://www.uni-saarland.de/universitaet/aktuell/artikel/nr/22271.html',
          logo: '/logos/Logo-Universität_des_Saarlandes.jpg',
          institution: 'Universität des Saarlandes',
          text: 'Die Folgen von Covid-19 betreffen auch Trauerfälle, denn sie erschweren (…) das persönliche Abschiednehmen auf der Trauerfeier. Die Gründer betreten einen Markt, der noch nicht im Zeitalter der Digitalisierung angekommen ist.',
          date: '2020',
          logoWidth: '200px'
        },
        {
          link: 'https://www.saarbruecker-zeitung.de/saarland/saar-wirtschaft/start-up-unvergessen-aus-saarbruecken-bietet-plattform-fuer-trauer-online_aid-52571853',
          logo: '/logos/341px-Saarbrücker_Zeitung_Logo.jpg',
          institution: 'Saarbrücker Zeitung',
          text: 'Mehr als nur eine Traueranzeige im Netz (…).\nHier können Hinterbliebene online ihrer Verstorbenen gedenken (…) und Hilfe finden mit ihrem Verlust umzugehen.',
          date: '2020',
          logoWidth: '100px'
        }
      ]
    }
  },
  components: {
    unvergessenQuoteCard: QuoteCard
  }
}
</script>

<style scoped>
  * >>> .v-carousel__item {
    overflow: visible
  }
</style>
