<template>
  <div>
    <v-card
      :max-width="maxWidth"
      :min-width="maxWidth"
      elevation="0"
      @click="navigate()"
    >
      <v-row style="width: 100%; margin: 0 !important;">
        <v-col cols="1" lg="2" class="unvergessen-col" :class="{'unvergessen-message': !read}" :style="{ 'padding-left': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '1%' : '25px' }">
          <v-list-item-avatar
              style="border-radius: 50%;"
              :size="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '40' : '50'"
              color="secondary"
          ><v-icon color="primary" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '30px' : '40px', height: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '30px' : '40px' }">$account</v-icon></v-list-item-avatar>
        </v-col>
        <v-col cols="11" lg="10" class="unvergessen-col" :class="{'unvergessen-message': !read}">
          <v-list-item two-line style="padding-right: 0">
            <v-list-item-content style="padding-bottom: 5px; padding-top: 5px">
              <v-row>
                <v-col cols="9" lg="8" class="unvergessen-col">
                  <v-list-item-title class="title font-weight-regular mb-1">{{ message.from.name }}</v-list-item-title>
                </v-col>
                <v-col cols="3" lg="4" class="unvergessen-col" style="padding: 0">
                  <div class="overline">{{ date }}</div>
                </v-col>
              </v-row>
              <v-list-item-subtitle>{{ message.msg }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['message', 'maxWidth'],
  data () {
    return {
      date: ''
    }
  },
  methods: {
    navigate () {
      // if message is not read yet
      if (!this.read) {
        // emit 'readMessage' event to parent to decrement messageCounter
        this.$store.dispatch('decrementMessageCounter')
        // set read message to true
        this.$store.dispatch('readMessage', this.message._id)
        // tell backend, that message has been read
        axios.patch('/message', { m_id: this.message._id })
      }
      this.$store.dispatch('setMessageClicked', true)
      this.$router.push({ name: 'Messages', query: { id: this.message._id } }).catch(error => {})
    }
  },
  created () {
    var tmpStr = this.message.date.split('T')[0]
    var dateArray = tmpStr.split('-')
    this.date = dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0]
  },
  computed: {
    read () {
      var messages = this.$store.getters.messages
      for (var i = 0; i < messages.length; i++) {
        if (messages[i]._id === this.message._id) {
          return messages[i].read === true
        }
      }
      return true
    }
  }
}
</script>

<style scoped>
  .unvergessen-col {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px
  }

  .unvergessen-message {
    background-color: #f0f0f0;
    font-style: bold;
  }
</style>
