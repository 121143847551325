<template>
  <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="dialogComputed" @click:outside="$emit('dialog')" max-width="815px">
    <v-card>
      <span style="position: absolute; left: 2%; top: 2%; z-index: 100" class="primary--text" @click="$emit('dialog')" v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
        <v-icon color="primary" @click="closeDialog()">mdi-arrow-left</v-icon> zurück
      </span>
      <div style="width: 98%; padding-top: 2%; position: absolute">
        <v-icon color="primary" style="float: right; cursor: pointer" @click="$emit('dialog')">mdi-close</v-icon>
      </div>
      <v-card-title>
        <p :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '10%' : 'auto' }" class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">Newsletter-Anmeldung</p>
      </v-card-title>
      <v-card-text :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '5%' : '0 15%' }">
        <v-alert type="error" v-model="error">
          Deine Anmeldung konnte nicht durchgeführt werden. Bitte versuche es erneut
        </v-alert>
        <v-alert type="error" v-model="mailExists">
          Diese E-Mail-Adresse erhält bereits unseren Newsletter. Du brauchst sie nicht erneut anzumelden.
        </v-alert>
        <v-form @submit.prevent="submit" onSubmit="return false;"
          ref="form"
        >
          <p class="font-weight-regular" style="text-align: center; margin-bottom: 0.1in" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
            Wir informieren dich 1-2 Mal pro Monat über Neuigkeiten zu Unvergessen.de. Wir werden es damit nicht übertreiben und du kannst dich auch jederzeit wieder abmelden.
          </p>
          <div style="padding: 2% 0">
            <v-text-field class="font-weight-regular" :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" label="Email" :rules="emailRules" placeholder="Deine E-Mail-Adresse eingeben" outlined required v-model="email"></v-text-field>
          </div>

          <v-checkbox
            v-model="checkbox"
            style="margin-left: 10px"
            require
            :rules="nonEmptyRules"
          >
            <template v-slot:label>
              <div>
                Ich habe die
                <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="/Datenschutz" @click.stop v-on="on">Datenschutzerklärung</a>
                </template>
                <p>
                  Öffnet sich in einem neuen Fenster.
                </p>
                </v-tooltip>
                gelesen und stimme dieser zu.
              </div>
            </template>
          </v-checkbox>
        </v-form>

        <div style="padding: 5% 15%">
          <unvergessen-button :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" @click="addToNewsletter()">Anmelden</unvergessen-button>
        </div>
      </v-card-text>
    </v-card>
    <unvergessen-success-dialog :dialog="success" @close="success = false" heading="Danke für deine Anmeldung" buttonText="Erledige ich sofort!">
      Du hast eine E-Mail erhalten. Bitte bestätige deine Adresse {{ email }}, indem du auf den Button in der E-Mail klickst.
    </unvergessen-success-dialog>
  </v-dialog>
</template>

<script>
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import axios from 'axios'

export default {
  props: ['dialog'],
  data () {
    return {
      email: '',
      checkbox: false,
      success: false,
      error: false,
      mailExists: false,
      emailRules: [
        v => !!v || 'E-Mail-Adresse wird benötigt',
        v => /.+@.+\..+/.test(v) || 'Bitte gib eine valide E-Mail-Adresse ein'
      ],
      nonEmptyRules: [
        v => !!v || 'Dieses Feld wird benötigt'
      ]
    }
  },
  computed: {
    dialogComputed () {
      return this.dialog
    }
  },
  methods: {
    addToNewsletter () {
      var valid = this.$refs.form.validate()
      if (valid && this.checkbox) {
        axios.post('/newsletter/subscribe', { mail: this.email })
          .then(res => {
            this.$emit('dialog')
            this.success = true
            this.email = ''
            this.error = false
          })
          .catch(error => {
            if (error.response.data.message === 'Mail exists') {
              this.mailExists = true
              this.error = false
              this.success = false
            } else {
              this.mailExists = false
              this.error = true
              this.success = false
            }
          })
      }
    }
  },
  components: {
    'unvergessen-success-dialog': SuccessDialog
  },
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        this.$emit('dialog')
        next(false)
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  }
}
</script>
