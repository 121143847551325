<template>
  <div style="height: 100%">
    <v-btn height="100%" color="transparent" depressed class="nav-btn" @click="login">Anmelden</v-btn>
    <unvergessen-login-dialog :dialog="dialog" @dialog="dialog = false" :persistent="false"></unvergessen-login-dialog>
  </div>
</template>

<script>
import LoginDialog from '@/components/global/LoginDialog.vue'

export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    login () {
      this.dialog = true
    }
  },
  components: {
    'unvergessen-login-dialog': LoginDialog
  }
}
</script>

<style scoped>
  div {
    margin: auto
  }

  .login-btn {
    background-color: white;
    margin: auto;
    height: 45px;
    width: 186px;
    border-radius: 15px;
    font-size: large !important;
  }

  .login-icon {
    background-color: var(--v-text-base);
    border-radius: 50%;
    height: 25px !important;
    width: 25px !important;
  }
</style>
