import axios from 'axios'

const state = {
  messages: [],
  messageCounter: 0,
  messageClicked: false
}

const getters = {
  messages: state => {
    return state.messages
  },
  messageCounter: state => {
    return state.messageCounter
  },
  messageClicked: state => {
    return state.messageClicked
  }
}

const mutations = {
  setMessages: (state, payload) => {
    state.messages = payload
  },
  addMessages: (state, payload) => {
    state.messages = state.messages.concat(payload)
  },
  setMessageCounter: (state, payload) => {
    state.messageCounter = payload
  },
  incrementMessageCounter: state => {
    state.messageCounter++
  },
  decrementMessageCounter: state => {
    state.messageCounter--
  },
  readMessage: (state, payload) => {
    for (var i = 0; i < state.messages.length; i++) {
      if (state.messages[i]._id === payload) {
        state.messages[i].read = true
      }
    }
  },
  setMessageClicked: (state, payload) => {
    state.messageClicked = payload
  }
}

const actions = {
  setMessages: (context, payload) => {
    context.commit('setMessages', payload)
  },
  addMessages: (context, payload) => {
    context.commit('addMessages', payload)
  },
  loadUnreadMessages: (context) => {
    axios.get('/message/unread')
      .then(res => {
        var messageCounter = res.data.unread
        context.commit('setMessageCounter', messageCounter)
      })
      .catch(error => {
      })
  },
  incrementMessageCounter: context => {
    context.commit('incrementMessageCounter')
  },
  decrementMessageCounter: context => {
    context.commit('decrementMessageCounter')
  },
  readMessage: (context, payload) => {
    context.commit('readMessage', payload)
  },
  setMessageClicked: (context, payload) => {
    context.commit('setMessageClicked', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
