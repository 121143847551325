<template>
    <v-app-bar
      app
      class="unvergessen-nav-bar"
      :color="(windowTop < 50 && exception) ? 'rgba(247, 238, 230, 0.3)' : 'secondary'"
      :elevation="windowTop < 50 && exception ? '' : '4'"
      :class="{ 'unvergessen-shadow-soft': windowTop < 50 && exception }"
      dark
      fixed
      :height="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '50px' : '64px'"
      :style="{ padding: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '0 90px' : '' }"
    >
      <img src="../../assets/unvergessen-logo-grey.png" alt="unvergessen logo" class="desktop-logo hidden-md-and-down" @click="mobileRoute({ name: 'Home' })">
      <img src="../../assets/unvergessen-logo-grey.png" alt="unvergessen logo" class="mobile-logo hidden-lg-and-up" @click="mobileRoute({ name: 'Home' })">
      <v-tabs light class="hidden-md-and-down" optional style="margin-left: 40px">
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn height="100%" color="transparent" v-bind="attrs" v-on="on" depressed class="nav-btn" @click="$router.push({ name: 'PageLanding' })">Trauer <v-icon>mdi-menu-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'PageLanding'}">
              <v-list-item-title>Gedenkseiten</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'FAQ'}">
              <v-list-item-title>Häufige Fragen</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Blog' }">
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'Help'}">
              <v-list-item-title>Hilfe finden</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn height="100%" color="transparent" v-bind="attrs" v-on="on" depressed class="nav-btn">Über Uns</v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'About'}">
              <v-list-item-title>Gründungsgeschichte</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'FAQ'}">
              <v-list-item-title>Häufige Fragen</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'Blog'}">
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'Help'}">
              <v-list-item-title>Hilfe finden</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <!-- <v-btn height="100%" color="transparent" depressed class="nav-btn" :to="{name: 'About'}">Über Uns</v-btn> -->
        <v-btn height="100%" color="transparent" depressed class="nav-btn" :to="{name: 'About'}">
          Über Uns
        </v-btn>
        <v-btn height="100%" color="transparent" depressed class="nav-btn" :to="{name: 'MemorialPostLanding'}" @click="$ga.event('Gedenk-Post', 'NavBarNavigate', $route.name, 0)" v-if="false">
          <v-badge color="error" content="Neu">
           Gedenk-Post
          </v-badge>
        </v-btn>
        <v-btn height="100%" color="transparent" depressed class="nav-btn" :to="{name: 'Bears'}" @click="$ga.event('Bears', 'NavBarNavigate', $route.name, 0)">
          <v-badge color="error" content="Neu">
           Unvergessen Bärchen
          </v-badge>
        </v-btn>

        <v-spacer></v-spacer>
        <template v-if="!isLoggedIn">
          <unvergessen-login-btn></unvergessen-login-btn>
        </template>
      </v-tabs>
      <v-btn class="title font-weight-regular" :class="{ success: !isLoggedIn, white: isLoggedIn, 'text--text': isLoggedIn }" style="text-transform: none; padding: 25px 20px" rounded v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md)" @click="newPage = true">Gedenkseite erstellen</v-btn>
      <template v-if="isLoggedIn">
        <unvergessen-messages-btn class="hidden-md-and-down" style="margin-left: 15px"></unvergessen-messages-btn>
        <unvergessen-notifications-btn class="hidden-md-and-down"></unvergessen-notifications-btn>
        <unvergessen-account-btn class="hidden-md-and-down"></unvergessen-account-btn>
      </template>
      <v-spacer></v-spacer>
      <unvergessen-mobile-menu></unvergessen-mobile-menu>
      <!-- Dialogs -->
      <unvergessen-new-page-dialog :dialog="newPage" @close="newPage = false"></unvergessen-new-page-dialog>
    </v-app-bar>
</template>

<script>
import Message from '../navbar/MessageButton.vue'
import Notifications from '../navbar/NotificationButton.vue'
import Account from '../navbar/AccountButton.vue'
import Login from '../navbar/LoginButton.vue'
import MobileMenu from '../navbar/MobileMenu.vue'
import NewPageDialog from '@/components/global/NewPageDialog.vue'

export default {
  data () {
    return {
      newPage: false,
      windowTop: 0
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    exception () {
      return this.$route.name && (this.$route.name === 'MemorialDays' || this.$route.name === 'Page' || this.$route.name === 'Home' || this.$route.name.includes('PageLanding') || this.$route.name === 'MemorialPostLanding')
    }
  },
  methods: {
    navigateToBlog () {
      window.open('/testblog', '_self')
    },
    login () {
      this.$store.dispatch('login')
    },
    mobileRoute (route) {
      this.$router.push(route)
        .catch(error => {
        })
    },
    onScroll (e) {
      this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  components: {
    'unvergessen-messages-btn': Message,
    'unvergessen-notifications-btn': Notifications,
    'unvergessen-account-btn': Account,
    'unvergessen-login-btn': Login,
    'unvergessen-mobile-menu': MobileMenu,
    unvergessenNewPageDialog: NewPageDialog
  }
}
</script>

<style scoped>
  .desktop-logo {
    height: 70%;
    cursor: pointer;
  }

  .mobile-logo {
    height: 65%;
    cursor: pointer;
  }

  .unvergessen-search-field{
    margin-right: 15px;
  }
</style>

<style>
  .unvergessen-nav-bar-icon {
    background-color: white;
    margin: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
  }

  .unvergessen-nav-bar-icon:hover {
    background-color: #e0e0e0;
    transition: 0.3s;
  }
</style>
