<template>
  <div>
    <!-- <v-text-field
      v-model="searchString"
      append-icon="mdi-magnify"
      clear-icon="mdi-close-circle"
      clearable
      label="Suchen"
      type="text"
      class="shrink hidden-lg-and-up"
      @click:append="search"
      @keydown.enter="search"
      style="margin-top: 11px; margin-right: 5px; width: 75%; float: left;"
    ></v-text-field> -->
    <v-icon class="hidden-lg-and-up mx-2" light v-if="isLoggedIn" @click="$router.push({ name: 'Messages' })">$mail-text</v-icon>
    <v-menu offset-y left>
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon v-on="on" style="margin-top: -7px" class="hidden-lg-and-up caption text--text" right>
          <v-icon style="position: absolute" light>mdi-menu</v-icon>
          <span style="padding-top: 30px; font-size: 8px">
            Menü
          </span>
        </v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="konto" @click="addMemorialPage()" v-if="!isLoggedIn">
          <v-icon color="primary" style="margin-right: 9px">mdi-plus-circle</v-icon>
          <v-list-item-title>Gedenkseite erstellen</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="page" :to="{ name: 'PageLanding' }">
          <v-icon color="primary" style="margin-right: 8px">mdi-file-search</v-icon>
          <v-list-item-title>Gedenkseiten</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="Blog" :to="{ name: 'Blog' }">
          <v-icon color="primary" style="margin-right: 8px">mdi-newspaper-variant-multiple</v-icon>
          <v-list-item-title>Blog</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="faq" :to="{ name: 'FAQ' }">
          <v-icon color="primary" style="margin-right: 8px">mdi-help-circle</v-icon>
          <v-list-item-title>Häufige Fragen</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="about" :to="{ name: 'About' }">
          <v-icon color="primary" style="margin-right: 10px; width: 25px; height: 25px">$about-us</v-icon>
          <v-list-item-title>Über Uns</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="help-mobile" :to="{ name: 'Help' }">
          <v-icon color="primary" style="margin-right: 8px; width: 28px; height: 28px">$hand-orange</v-icon>
          <v-list-item-title>Hilfe finden</v-list-item-title>
        </v-list-item>
        <!-- <v-divider></v-divider> -->
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="post-mobile" :to="{ name: 'MemorialPostLanding' }" @click="$ga.event('Gedenk-Post', 'NavBarNavigateMobile', $route.name, 0)" v-if="false">
          <v-icon color="primary" style="margin-right: 8px; width: 28px; height: 28px">$postbox</v-icon>
          <v-list-item-title>Gedenk-Post</v-list-item-title>
          <v-chip
            color="red"
            text-color="white"
            x-small
            style="width: 52px"
          >
            NEU
          </v-chip>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="bears-mobile" :to="{ name: 'Bears' }" @click="$ga.event('Bears', 'NavBarNavigateMobile', $route.name, 0)">
          <v-icon color="primary" style="margin-right: 8px; width: 36px; height: 36px">$bear</v-icon>
          <v-list-item-title>Bärchen</v-list-item-title>
          <v-chip
            color="red"
            text-color="white"
            x-small
            style="width: 52px"
          >
            NEU
          </v-chip>
        </v-list-item>
        <v-divider></v-divider>
        <template v-if="isLoggedIn">
          <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="konto" :to="{ name: 'Account' }">
            <v-icon color="primary" style="margin-right: 11px; width: 25px; height: 25px">$account</v-icon>
            <v-list-item-title>Dein Konto und deine Seiten</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="new" @click="addMemorialPage()">
            <v-icon color="primary" style="margin-right: 9px">mdi-plus-circle</v-icon>
            <v-list-item-title>Neue Gedenkseite erstellen</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="logout" @click="logout">
            <v-icon color="primary" style="margin-right: 8px">mdi-logout</v-icon>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template v-else>
          <v-list-item style="min-height: 35px !important; padding: 0 5%; width: 250px" key="login" @click="dialog=true">
            <v-icon color="primary" style="margin-right: 8px">mdi-login</v-icon>
            <v-list-item-title>Anmelden</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <unvergessen-login-dialog :dialog="dialog" @dialog="dialog = !dialog"></unvergessen-login-dialog>
    <unvergessen-new-page-dialog :dialog="newPage" @close="newPage=false"></unvergessen-new-page-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LoginDialog from '@/components/global/LoginDialog.vue'
import NewPageDialog from '@/components/global/NewPageDialog.vue'

export default {
  data () {
    return {
      searchString: '',
      dialog: false,
      newPage: false
    }
  },
  methods: {
    search () {
      this.$router.push({ name: 'Search', query: { str: this.searchString } })
      this.searchString = ''
    },
    ...mapActions([
      'login'
    ]),
    addMemorialPage () {
      this.newPage = true
    },
    logout () {
      // if route contains /page stay and log out
      if (this.$route.path.includes('/Gedenkseite') && !this.$route.path.includes('/Zahlung') && !this.$route.path.includes('/Einstellungen') && this.isV) {
        this.$store.dispatch('logout')
      } else {
        this.$router.push({ name: 'Home' }).catch(error => {})
        this.$store.dispatch('logout')
      }
    }
  },
  components: {
    'unvergessen-login-dialog': LoginDialog,
    unvergessenNewPageDialog: NewPageDialog
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>

<style scoped>
  div {
    margin: auto
  }

  .unvergessen-search-field{
    margin-right: 15px;
  }
</style>
