<template>
  <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" class="new-page" v-model="dialog" max-width="815px" @click:outside="closeDialog">
    <v-card style="padding-bottom: 2%">
      <div style="width: 98%; padding-top: 2%; position: absolute">
        <v-icon color="primary" style="float: right; cursor: pointer" @click="closeDialog">mdi-close</v-icon>
      </div>
      <div style="width: 100%; padding: 2%">
        <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), 'font-weight-medium': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)}">
          Wer war die Person?
        </p>
      </div>
      <div style="padding: 1% 14% 2% 14%;" :style="{ 'padding-bottom': $vuetify.breakpoint.smAndDown ? '200px' : '20px' }">
        <v-card-text style="padding: 0;">
          <v-form @submit.prevent="submit" onSubmit="return false;"
            ref="form"
            lazy-validation
          >
            <v-text-field
              class="font-weight-regular"
              :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
              label="Vorname"
              :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
              outlined
              required
              :rules="nonEmptyRules"
              v-model="prename"
              placeholder="Vorname der verstorbenen Person"
            >
            </v-text-field>

            <v-text-field
              class="font-weight-regular"
              :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
              label="Nachname"
              :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
              outlined
              required
              :rules="nonEmptyRules"
              v-model="surname"
              placeholder="Nachname der verstorbenen Person"
            >
            </v-text-field>

            <v-text-field
              class="font-weight-regular"
              :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
              label="Wohnort"
              :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
              outlined
              required
              :rules="nonEmptyRules"
              v-model="residence"
              placeholder="Letzter Wohnort"
            >
            </v-text-field>

            <p class="text--text font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)}">
              Verstorben am
            </p>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateFormatted"
                  class="font-weight-regular"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  placeholder="Datum eingeben (TT.MM.JJJJ)"
                  outlined
                  @keydown="menu=false"
                  required
                  :rules="dateRule"
                  @blur="date = parseDate(dateFormatted)"
                  :max="formatDate(new Date().toISOString().substr(0, 10))"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker min="1000-01-01" ref="picker" locale="de" v-model="date" :max="new Date().toISOString().substr(0, 10)" @change="save"></v-date-picker>
            </v-menu>

            <p class="font-weight-regular" style="text-align: left; margin-bottom: 10px">
              Wenn du die Gedenkseite erstellst, ist diese zunächst nur für dich sichtbar.
              Du kannst später einstellen, ob die Seite öffentlich oder nur für ausgewählte Personen einsehbar sein soll.
            </p>
            <unvergessen-button @click="create()" :green="true" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Gedenkseite anlegen</unvergessen-button>
          </v-form>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import backButton from '@/lib/backButton.js'
import axios from 'axios'
var jwtDecode = require('jwt-decode')

export default {
  props: ['dialog'],
  data () {
    return {
      prename: '',
      surname: '',
      residence: '',
      go: false,
      pageCreate: false,
      // date picker
      date: null,
      dateFormatted: null,
      menu: false,
      // rules
      nonEmptyRules: [
        v => !!v || 'Eintrag wird benötigt'
      ],
      dateRule: [
        v => {
          if (this.date === undefined) return true
          if (v !== null) {
            var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
            if (!format.test(v)) {
              return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
            }
            var today = new Date()
            var date = v.split('.')
            if (date[1] === '02' && parseInt(date[0]) > 29) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
            if (date[1] === '02' && parseInt(date[0]) > 28 && parseInt(date[2]) % 4 !== 0) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
            if (parseInt(date[0]) > 30 && (date[1] === '04' || date[1] === '06' || date[1] === '09' || date[1] === '11')) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
            date = new Date(date[2], date[1] - 1, date[0])
            return today.getTime() - date.getTime() > 0 || 'Bitte gib ein Datum in der Vergangenheit ein'
          } else {
            return 'Eintrag wird benötigt'
          }
        }
      ]
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    create () {
      if (this.$refs.form.validate()) {
        this.$emit('close')
        this.go = true
        var data = {
          prename: this.prename,
          surname: this.surname,
          residence: this.residence,
          obit: this.date
        }
        this.$store.dispatch('setCreateData', data)
        this.pageCreate = true
        if (localStorage.unvergessenToken) {
          data.token = localStorage.unvergessenToken
        }
        axios.post('/page/new', data)
          .then(res => {
            axios.post('/analytics/session/event', { event: { category: 'Page_Onboarding', action: 'Created_Page', label: this.$route.path, value: 0 } })
            const pId = res.data.page_id
            window.fbq('track', 'AddToWishlist')
            this.$ga.event('Onboarding', 'Create Page', res.data.page_id, 0)
            if (!this.isLoggedIn) {
              const token = res.data.token
              const jwt = jwtDecode(token)
              localStorage.setItem('unvergessenToken', token)
              this.$store.dispatch('setCreateData', {})
              axios.defaults.headers.common.Authorization = 'Bearer ' + token
              this.$router.push({ name: 'Page', params: { page_id: jwt.pages[0] } }).catch(error => {})
            } else {
              const tok = localStorage.unvergessenToken
              this.$store.dispatch('renewAuth', { page_id: res.data.page_id, keepMeLoggedIn: tok.exp - tok.iat > 10000 })
                .then(res => {
                  this.$store.dispatch('setCreateData', {})
                  this.$store.dispatch('loadUser')
                    .then(res => {
                      this.$router.push({ name: 'Page', params: { page_id: pId } }).catch(error => {})
                    })
                    .catch(error => {
                    })
                })
                .catch(error => {})
            }
          })
          .catch(error => {
          })
        // this.$router.push({ name: 'PageCreate' }).catch(error => {})
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  },
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog && !this.pageCreate) {
        this.$emit('close')
        next(false)
      } else {
        this.pageCreate = false
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },
  mixins: [
    backButton('go', 'closeDialog')
  ],
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
    dialog () {
      if (this.dialog) this.$ga.event('Onboarding', 'ClickOnCreatePage', '', 0)
    }
  }
}
</script>
