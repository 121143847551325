<template>
  <v-card style="margin: auto; width: 100%" class="flex d-flex flex-column grey4 unvergessen-shadow">
    <v-list class="grey4" style="padding: 15px">
      <v-list-item>
        <div class="font-weight-bold title text--text">
          Du möchtest alle Neuigkeiten?
        </div>
      </v-list-item>
      <v-alert type="error" v-model="error">
        Deine Anmeldung konnte nicht durchgeführt werden. Bitte versuche es erneut
      </v-alert>
      <v-alert type="error" v-model="mailExists">
        Diese E-Mail-Adresse erhält bereits unseren Newsletter. Du brauchst sie nicht erneut anzumelden.
      </v-alert>
      <v-list-item>
        <div class="font-weight-regular">
          Newsletter-Anmeldung
        </div>
        <v-list-item-icon>
          <unvergessen-tooltip>
            Wir informieren dich in unserem Newsletter 1-2 Mal pro Monat über Neuigkeiten zu Unvergessen.de. Wir werden es damit nicht übertreiben und du kannst dich auch jederzeit wieder abmelden.
          </unvergessen-tooltip>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-content style="height: 90px">
          <v-text-field
            label="Newsletter"
            solo
            :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
            background-color="white"
            v-model="input"
            :placeholder="placeholder"
            :rules="[rules.email]"
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-checkbox
            v-model="checkbox"
            style="margin-left: 10px"
          >
           <template v-slot:label>
             <div style="font-size: 14px !important">
               Ich habe die
               <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="/Datenschutz" @click.stop v-on="on">Datenschutzerklärung</a>
                </template>
                <p>
                  Öffnet sich in einem neuen Fenster.
                </p>
               </v-tooltip>
               gelesen und stimme dieser zu.
              </div>
           </template>
          </v-checkbox>
        </v-list-item-content>
      </v-list-item>
      <div style="padding: 0 5%; padding-bottom: 15px">
        <unvergessen-button style="height: 80px !important" @click="send()" :disabled="!checkbox || !isMailValid">Anmelden</unvergessen-button>
      </div>
    </v-list>
    <unvergessen-success-dialog :dialog="success" @close="success = false; input = ''" heading="Danke für deine Anmeldung" buttonText="Erledige ich sofort!">
      Du hast eine E-Mail erhalten. Bitte bestätige deine Adresse {{ input }}, indem du auf den Button in der E-Mail klickst.
    </unvergessen-success-dialog>
  </v-card>
</template>

<script>
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import axios from 'axios'

export default {
  data () {
    return {
      input: '',
      success: false,
      error: false,
      mailExists: false,
      isMailValid: false,
      placeholder: 'Deine E-Mail-Adresse',
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (pattern.test(value)) {
            this.isMailValid = true
            return pattern.test(value)
          } else {
            this.isMailValid = false
            return 'Bitte gib eine gültige E-Mail-Adresse ein.'
          }
        }
      },
      checkbox: false
    }
  },
  methods: {
    send () {
      axios.post('/newsletter/subscribe', { mail: this.input })
        .then(res => {
          this.success = true
          this.error = false
          this.mailExists = false
        })
        .catch(error => {
          if (error.response.data.message === 'Mail exists') {
            this.mailExists = true
            this.error = false
            this.success = false
          } else {
            this.mailExists = false
            this.error = true
            this.success = false
          }
        })
    }
  },
  components: {
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>

<style scoped>
  .v-text-field input {
    font-size: 1.9em;
  }
</style>
