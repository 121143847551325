<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 2560 151" :style="{ 'background-color': background }" style="position: relative" preserveAspectRatio="xMaxYMin meet">
    <path class="a" d="M2560-94.667S1912.258-231.348,1272.258-231.348,0-94.667,0-94.667H2560Z" transform="translate(0 246.35)" :style="{ fill: fill }" />
  </svg>
</template>

<script>
export default {
  props: ['background', 'fill']
}
</script>
