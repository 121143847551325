<template>
  <div>
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="dialog" persistent max-width="700px" @click:outside="persistent ? $emit('void') : $emit('dialog')" @keydown.enter="login()">
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="onpage ? $emit('close') : $emit('dialog')">mdi-close</v-icon>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '2% 5%' : '0 20% 1% 20%' }">
          <v-card-title>
            <p class="display-1 unvergessen-dialog-heading">Anmelden</p>
          </v-card-title>

          <!-- Login With Facebook
          <v-facebook-login
            :app-id="appId"
            logout="Abmelden"
            working="Bitte warten..."
            @sdk-init="handleSdkInit"
            @login="fbLogin($event)"
            @logout="fbLogout($event)"
            style="width: 100%"
          >
            <template v-slot:login>
              Mit Facebook anmelden
            </template>
            <template v-slot:logout>
              Abmelden
            </template>
            <template v-slot:working>
              Bitte warten...
            </template>
          </v-facebook-login> -->

          <!-- Login With Google -->
          <!-- <unvergessen-google-login :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></unvergessen-google-login> -->

          <!-- <v-divider class="my-5"></v-divider> -->

          <v-alert type="error" v-if="errorText">
            E-Mail-Adresse oder Passwort ist ungültig... Bitte versuche es erneut
          </v-alert>
          <v-alert type="error" v-if="errorNE">
            <span>Zu der eingegebenen E-Mail-Adresse existiert noch kein Konto. Klicke</span> <unvergessen-register-dialog @guard="guard = true" @closeParent="$emit('dialog')" @RegisterDialog="guard=true; $emit('LoginDialog')" @dialog="$emit('dialog')" :persistent="persistent" :onpage="onpage" :here="true"></unvergessen-register-dialog> <span>um dich zu registrieren.</span>
          </v-alert>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                class="font-weight-regular"
                :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                label="Email"
                :rules="emailRules"
                outlined
                type="email"
                required
                v-model="email"
              ></v-text-field>
              <v-text-field
                class="font-weight-regular"
                :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                label="Passwort"
                :rules="pwdRules"
                :append-icon="!showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPwd ? 'text' : 'password'"
                @click:append="showPwd = !showPwd"
                counter
                outlined
                required
                v-model="pwd"
              ></v-text-field>
              <v-checkbox style="margin: 0" label="Angemeldet bleiben" v-model="keepMeLoggedIn"></v-checkbox>
              <unvergessen-button @click="login()" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Anmelden</unvergessen-button>
            </v-form>
            <p class="font-weight-regular unvergessenLink primary--text" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding-top: 0.3in; text-align: center; cursor: pointer" @click="openResetPwdDialog()">
              Passwort vergessen?
            </p>
            <v-divider></v-divider>
            <div style="padding: 0 0.1in">
              <v-row>
                <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
                  Du hast noch kein Konto?
                </p>
                <v-spacer></v-spacer>
                <unvergessen-register-dialog @guard="guard = true" @closeParent="$emit('dialog')" @RegisterDialog="guard = true; $emit('LoginDialog')" @dialog="$emit('dialog')" :persistent="persistent" :onpage="onpage"></unvergessen-register-dialog>
              </v-row>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Enter Mail -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="resetPwd" max-width="700px" persistent>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="resetPwd = false; $emit('dialog')">mdi-close</v-icon>
        </div>
        <div style="padding: 0 10% 10% 10%">
          <div style="padding: 20px 0">
            <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">Du hast dein Passwort vergessen?</p>
          </div>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="mailForm"
              v-model="valid"
              lazy-validation
            >
              <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="text-align: center; margin-bottom: 0.1in">Das ist kein Problem. Gib hier einfach deine E-Mail-Adresse ein, mit der du dich bei Unvergessen.de registriert hast.</p>
              <v-alert type="error" v-model="noUser">
                Deine eingegebene E-Mail Adresse besitzt noch kein Konto bei Unvergessen.de. Bitte überprüfe deine Eingabe und versuche es erneut.
              </v-alert>
              <v-alert type="error" v-model="errorMail">
                Etwas ist schief gelaufen. Bitte versuche es später erneut.
              </v-alert>
              <div style="padding: 0.2in">
                <v-text-field class="font-weight-regular"
                :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" label="Email" :rules="emailRules" placeholder="E-Mail-Adresse eingeben" outlined required v-model="resetEmailAddress"></v-text-field>
              </div>
              <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '5%' : '0 1in' }">
                <unvergessen-button @click="sendMail()" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Weiter</unvergessen-button>
              </div>
            </v-form>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Enter Auth Code -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="enterAuth" max-width="700px" persistent>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="enterAuth = false; $emit('dialog')">mdi-close</v-icon>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '2% 2%' : '0 14% 2% 14%' }">
          <v-card-title>
            <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">Verifizierungscode eingeben</p>
          </v-card-title>
          <v-alert type="error" v-if="errorAuth">
            Verifizierungscode ist ungültig... Bitte überprüfe ihn auf Fehler und gib ihn erneut ein
          </v-alert>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="AuthcodeForm"
              v-model="valid"
              lazy-validation
            >
              <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="text-align: center; margin-bottom: 0.1in">
                Wir haben dir soeben an {{ resetEmailAddress }} einen Verifizierungscode gesendet. Bitte gib diesen hier ein:
              </p>

              <div style="padding: 0.2in; padding-bottom: 0">
                <v-text-field class="font-weight-regular"
                :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" label="Verifizierungscode" placeholder="Verifizierungscode eingeben" outlined required v-model="verificationCode"></v-text-field>
              </div>
              <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 20%' : '0 1in' }">
                <unvergessen-button @click="checkAuthCode()" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Weiter</unvergessen-button>
              </div>
              <p style="text-align: center; margin-top: 0.5in; margin-bottom: 0.1in">
                Du hast keine E-Mail erhalten? Überprüfe bitte, ob die E-Mail-Adresse korrekt geschrieben wurde oder ob die E-Mail vielleicht in deinem Spam-Ordner gelandet ist.
              </p>
              <v-card-actions>
                <a :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="resetPwd = true; enterAuth = false">E-Mail-Adresse korrigieren</a>
                <v-spacer></v-spacer>
                <a :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="sendMail()">Code erneut senden</a>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Enter new pwd -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="enterPwd" max-width="700px" persistent>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="enterPwd = false; $emit('dialog')">mdi-close</v-icon>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '2%' : '0 14% 2% 14%' }">
          <v-card-title>
            <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">Neues Passwort festlegen</p>
          </v-card-title>
          <v-alert type="error" v-if="errorPwd">
            Wiederholtes Passwort entspricht nicht dem neuen Passwort... Bitte versuche es erneut
          </v-alert>
          <v-alert type="error" v-if="errorNotPossible">
            Dein Passwort konnte nicht geändert werden... Bitte versuche es erneut
          </v-alert>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="newPwdForm"
              v-model="valid"
              lazy-validation
            >
              <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="text-align: center; margin-bottom: 0.1in">
                Tipp: Wähle zu deiner eigenen Sicherheit bitte ein Passwort mit mindestens acht Zeichen und mindestens einer Zahl oder einem Sonderzeichen
              </p>
              <div style="padding: 0.2in">
                <v-text-field
                  class="font-weight-regular"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  label="Passwort"
                  :rules="pwdRules"
                  :append-icon="!showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd2 ? 'text' : 'password'"
                  @click:append="showPwd2 = !showPwd2"
                  counter
                  outlined
                  placeholder="Neues Passwort"
                  required
                  v-model="newPwd"
                ></v-text-field>
                <v-text-field
                  class="font-weight-regular"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  label="Passwort"
                  :rules="pwdRules"
                  :append-icon="!showPwd3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd3 ? 'text' : 'password'"
                  @click:append="showPwd3 = !showPwd3"
                  counter
                  outlined
                  placeholder="Neues Passwort erneut eingeben"
                  required
                  v-model="newPwdAgain"
                ></v-text-field>
              </div>
              <div style="margin-bottom: 0.2in" :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 0.5in' : '0 0.5in' }">
                <unvergessen-button @click="changePassword()" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Neues Passwort speichern</unvergessen-button>
              </div>
            </v-form>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm E-Mail -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="confirmDialog" max-width="700px" persistent>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="closeConfirm()">mdi-close</v-icon>
        </div>
        <div style="padding: 4% 10% 4% 10%">
          <v-card-title>
            <p class="display-1 unvergessen-dialog-heading">Email-Adresse bestätigen</p>
          </v-card-title>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="AuthcodeForm"
              v-model="valid"
              lazy-validation
            >
              <p class="title font-weight-regular" style="text-align: center; margin-bottom: 0.3in">
                Wir haben dir soeben an {{ mail }} eine E-Mail gesendet. Bitte überprüfe deinen E-Mail Posteingang und klicke auf "E-Mail-Adresse bestätigen", um deine E-Mail-Adresse zu bestätigen.
              </p>
              <v-alert type="success" v-model="resendMail">
                Wir haben dir erneut eine E-Mail gesendet
              </v-alert>
              <v-alert type="error" v-model="resendError">
                Wir konnten dir keine E-Mail senden. Bitte versuche es erneut
              </v-alert>
              <div style="padding: 0 1in">
                <unvergessen-button @click="closeConfirm();">Weiter</unvergessen-button>
              </div>
              <p style="text-align: center; margin-top: 0.5in; margin-bottom: 0.1in">
                Du hast keine E-Mail erhalten? Überprüfe bitte, ob die E-Mail-Adresse korrekt geschrieben wurde oder ob die E-Mail vielleicht in deinem Spam-Ordner gelandet ist.
              </p>
              <v-card-actions>
                <a class="title" @click="dialog = true; enterAuth = false; registerError = false">E-Mail-Adresse korrigieren</a>
                <v-spacer></v-spacer>
                <a class="title" @click="sendMail()">E-Mail erneut senden</a>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Success -->
    <unvergessen-success-dialog :dialog="success" @close="success=false" buttonText="Schließen">
      Dein Passwort wurde erfolgreich geändert
    </unvergessen-success-dialog>
  </div>
</template>

<script>
import RegisterDialog from '@/components/global/RegisterDialog.vue'
// import GoogleLogin from 'vue-google-login'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

export default {
  props: ['dialog', 'persistent', 'onpage'],
  data () {
    return {
      // -----------------------------------
      response: {},
      // -----------------------------------
      valid: true,
      loginData: {},
      // inputs
      email: '',
      pwd: '',
      resetEmailAddress: '',
      verificationCode: '',
      resetToken: '',
      newPwd: '',
      newPwdAgain: '',
      keepMeLoggedIn: true,
      // Google Login
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
      },
      renderParams: {
        width: '100%',
        height: 50,
        longtitle: true
      },
      // Facebook Login
      appId: process.env.VUE_APP_FB_APP_ID,
      model: {},
      // error messages
      errorText: false,
      errorAuth: false,
      errorPwd: false,
      // show pwd icons
      showPwd: false,
      showPwd2: false,
      showPwd3: false,
      // dialog flags
      noUser: false,
      errorMail: false,
      resetPwd: false,
      enterAuth: false,
      enterPwd: false,
      success: false,
      guard: false,
      errorNotPossible: false,
      confirmDialog: false,
      resendMail: false,
      resendError: false,
      errorNE: false,
      // rules
      emailRules: [
        v => !!v || 'E-Mail-Adresse wird benötigt',
        v => /.+@.+\..+/.test(v) || 'Bitte gib eine valide E-Mail-Adresse ein'
      ],
      pwdRules: [
        v => !!v || 'Passwort wird benötigt',
        v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen enthalten'
      ]
    }
  },
  methods: {
    login () {
      this.valid = this.$refs.form.validate()
      if (this.valid) {
        this.guard = true
        this.$store.dispatch('login', { email: this.email, pwd: this.pwd, keepMeLoggedIn: this.keepMeLoggedIn, onpage: this.onpage })
          .then(res => {
            this.$ga.event('User', 'Login', this.email, 0)
            if (this.createData.page_id) {
              axios.post('/page/createFromAd', { page_id: this.createData.page_id })
                .then(res => {
                  var tok = jwtDecode(localStorage.unvergessenToken)
                  this.$store.dispatch('renewAuth', { page_id: this.createData.page_id, keepMeLoggedIn: tok.exp - tok.iat > 10000 })
                  this.$store.dispatch('setCreateData', {})
                  this.$store.dispatch('loadUser')
                })
                .catch(error => {
                })
            } else {
              this.$emit('LoginDialog')
            }
            this.$emit('dialog')
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.guard = false
              this.errorText = true
              this.errorNE = false
            } else {
              this.guard = false
              this.errorText = false
              this.errorNE = true
            }
          })
      }
    },
    // -----------   Facebook Login   -----------
    handleSdkInit ({ FB, scope }) {
      FB.getLoginStatus(function (response) {
        this.response = response
        this.statusChangeCallback(response)
      })
      this.$store.commit('setFb', FB)
      this.$store.commit('setFbScope', scope)
    },
    statusChangeCallback (response) {
      if (response.status === 'connected') {
        // do nothing
        this.response = response
      } else if (response.status === 'not_authorized') {
        // some code
      } else if (response.status === 'unknown') {
        this.FB.login(function (response) {
          this.statusChangeCallback(response)
        })
      } else {
        // some code
      }
    },
    fbLogin (e) {
      axios.post('/auth/loginFB', { data: e })
        .then(res => {
          this.guard = true
          this.loginData = {
            u_id: res.data.u_id,
            token: res.data.token,
            keepMeLoggedIn: false
          }

          this.$store.dispatch('setRenewTimer', { time: 7200, keepMeLoggedIn: false })
          if (!res.data.first) {
            this.$store.commit('login', this.loginData)
          }

          this.$store.dispatch('loadUser')

          if (this.createData.page_id) {
            axios.post('/page/createFromAd', { page_id: this.createData.page_id })
              .then(res => {
                var tok = jwtDecode(localStorage.unvergessenToken)
                this.$store.dispatch('renewAuth', { page_id: this.createData.page_id, keepMeLoggedIn: tok.exp - tok.iat > 10000 })
                this.$store.dispatch('setCreateData', {})
                this.$store.dispatch('loadUser')
              })
              .catch(error => {
              })
          } else {
            const r = this.$route.query.r
            const tft = this.$route.query.tft
            if (res.data.first) {
              this.confirmDialog = true
            } else {
              if (r && !tft) {
                this.$router.push({ name: 'Verify', params: { page_id: r } }).catch(error => {})
              } else {
                if (this.onpage) {
                  this.$emit('dialog')
                  this.$emit('LoginDialog')
                } else {
                  this.$router.push({ name: 'Account' }).catch(error => {})
                }
              }
            }
          }
          this.$emit('dialog')
          this.$emit('LoginDialog')
        })
        .catch(error => {
          this.$store.commit('logout')
        })
    },
    fbLogout (e) {
    },
    // ----------------------------------------
    closeConfirm () {
      this.guard = true
      this.confirmDialog = false
      this.$store.commit('login', this.loginData)
      const r = this.$route.query.r
      const tft = this.$route.query.tft
      if (r && !tft) {
        this.$router.push({ name: 'Verify', params: { page_id: r } })
      } else {
        if (this.onpage) {
          this.guard = true
          this.$emit('dialog')
          this.$emit('LoginDialog')
        } else {
          this.$router.push({ name: 'Account' })
        }
      }
    },
    openResetPwdDialog () {
      this.resetPwd = true
    },
    sendMail () {
      var val = this.$refs.mailForm.validate()
      if (val) {
        axios.post('/user/resetPWD', { mail: this.resetEmailAddress })
          .then(res => {
            this.resetPwd = false
            this.enterAuth = true
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 404) {
                this.noUser = true
              }
              if (error.response.status === 500) {
                this.errorMail = true
              }
            }
          })
      }
    },
    changePassword () {
      this.valid2 = this.$refs.newPwdForm.validate()
      if (this.valid2 && this.newPwd === this.newPwdAgain) {
        const config = {
          headers: {
            Authorization: 'Bearer ' + this.resetToken
          }
        }
        axios.patch('/user/resetPWD', { pwd: this.newPwd }, config)
          .then(res => {
            this.success = true
            this.enterPwd = false
            this.errorPwd = false
          })
          .catch(error => {
            this.errorPwd = false
            this.errorNotPossible = true
          })
      } else {
        this.errorPwd = true
      }
    },
    checkAuthCode () {
      axios.post('/user/checkCode', { code: this.verificationCode, mail: this.resetEmailAddress })
        .then(res => {
          this.enterAuth = false
          this.enterPwd = true
          this.errorAuth = false
          this.resetToken = res.data.token
        })
        .catch(error => {
          this.errorAuth = true
        })
    },
    // Google Login
    onSuccess (googleUser) {
    },
    onFailure (error) {
    }
  },
  components: {
    'unvergessen-register-dialog': RegisterDialog// ,
    // unvergessenGoogleLogin: GoogleLogin
  },
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog && !this.guard) {
        this.$emit('dialog')
        this.resetPwd = false
        this.enterAuth = false
        this.enterPwd = false
        this.success = false
        next(false)
      } else {
        this.guard = false
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },
  computed: {
    FB () {
      return this.$store.getters.fb.FB
    },
    scope () {
      return this.$store.getters.fb.scope
    },
    mail () {
      return this.$store.getters.email
    },
    createData () {
      return this.$store.getters.createData
    }
  }
}
</script>
