<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px" v-ripple="false">
      <!-- Name -->
      <div class="grey4 font-weight-medium" style="padding: 5px 10px">
        <v-skeleton-loader
          type="heading"
        >
        </v-skeleton-loader>
      </div>

      <!-- Page -->
      <div>
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8)" :style="{ padding: ($vuetify.breakpoint.xs) ? '0 2px' : '0 5%' }">
          <v-container :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 10px' : '' }">
            <v-row>
              <v-col cols="4" :style="{ 'font-size': $vuetify.breakpoint.xs ? '13px' : '' }" style="padding-top: 30px; padding-right: 0">
                <div>
                  <v-skeleton-loader type="text@2"></v-skeleton-loader>
                </div>
              </v-col>
              <v-col cols="4" style="text-align: center">
                <v-skeleton-loader type="avatar"></v-skeleton-loader>
              </v-col>
              <v-col cols="4" style="text-align: right; padding-top: 30px; padding-left: 0" :style="{ 'font-size': $vuetify.breakpoint.xs ? '13px' : '' }">
                <v-skeleton-loader type="text@2"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>

      <!-- Buttons -->
      <div v-ripple style="width: 100%; padding: 4px; text-align: center; cursor: pointer" class="grey4">
        Zur Gedenkseite
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['page'],
  computed: {
    bannerUrl () {
      return this.skeleton.banner || 'https://resources.eternio.com/static/banner/Titelbild_Ast_mit_Schnee.jpg'
    },
    skeleton () {
      return this.page || {}
    }
  },
  methods: {
    transformDate (date) {
      if (!date) return null

      var tmp = date.split('T')[0]
      tmp = tmp.split('-')
      return tmp[2] + '.' + tmp[1] + '.' + tmp[0]
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 1000px) {
  * >>> .v-skeleton-loader__avatar {
    height: 70px;
    width: 70px;
    margin: auto
  }
}

@media only screen and (min-width: 1000px) {
  * >>> .v-skeleton-loader__avatar {
    height: 110px;
    width: 110px;
    margin: auto
  }
}
</style>
