import { event } from 'vue-analytics'

const state = {
  returnTo: {}
}

const getters = {
  returnTo: state => {
    return state.returnTo
  }
}

const mutations = {
  setReturnTo: (state, payload) => {
    state.returnTo = payload
  }
}

const actions = {
  sendTo404: (commit, payload) => {
    event('404', 'SentTo404', payload, 0)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
