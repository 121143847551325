const state = {
  locations: {
    formatted_address: ''
  }
}

const getters = {
  locations: state => {
    return state.locations
  }
}

const mutations = {
  setLocations: (state, payload) => {
    state.locations = payload
  }
}

const actions = {
  setLocations: (context, payload) => {
    context.commit('setLocations', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
