<template>
  <v-btn
    :disabled="disabled"
    @click="$emit('click')"
    :loading="loading"
    width="100%"
    :min-height="mobile ? '50px' : '80px'"
    :color="lightBackground ? '#ffffff' : green ? 'success' : 'primary'"
    :style="{ 'font-size': mobile ? '16px !important' : 'auto' }"
    class="font-weight-regular title"
    style="border-radius: 15px; text-transform: none; box-shadow: 0 4px 8px 0 rgba(170, 170, 170, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: ['disabled', 'loading', 'lightBackground', 'mobile', 'green']
}
</script>
