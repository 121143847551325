<template>
  <div>
    <div class="home" :style="{ padding: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '125px 15% 30px' : $vuetify.breakpoint.md ? '85px 10% 30px' : '85px 12px 0px' }">
      <!-- Top -->
      <div :style="{ 'text-align': !$vuetify.breakpoint.xs ? 'center' : '' }">
        <!-- Heading -->
        <unvergessen-home-heading>
          Der schönste <template v-slot:underline>Erinnerungsort</template> <template v-slot:behind> für deine Liebsten</template>
        </unvergessen-home-heading>

        <!-- Subheading -->
        <h2 style="color: #552b00; margin-top: -10px; margin-bottom: 10px" class="font-weight-regular" :style="{ 'font-size': $vuetify.breakpoint.xs ? '14px' : '' }">
          Hier sind die Menschen und ihre Geschichten unvergessen.
        </h2>

        <div style="max-width: 550px; margin: auto">
          <v-container style="padding: 0">
            <v-row>
              <v-col cols="12" sm="6" style="padding-bottom: 0">
                <v-btn class="primary unvergessen-shadow" :width="!$vuetify.breakpoint.xs ? '100%' : ''" style="border-radius: 10px; margin-bottom: 10px; text-transform: none;" @click="dialog = true">
                  Erstelle eine Gedenkseite
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" style="padding-bottom: 0">
                <v-btn
                  class="unvergessen-shadow"
                  :width="!$vuetify.breakpoint.xs ? '100%' : ''"
                  style="color: #552b00; margin-bottom: 20px; border-radius: 10px; text-transform: none; border-width: 1px; border-style: solid; border-color: var(--v-primary-base)"
                  @click="$router.push({ name: 'ExploreNewPages' })"
                >
                  Gedenkseite suchen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>

    <!-- Line -->
    <unvergessen-wave-home background="white" style="overflow: visible;" :style="{ 'margin-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '' }" fill="var(--v-grey4-base)"></unvergessen-wave-home>

    <!-- Main -->
    <v-container :style="{'max-width': $vuetify.breakpoint.xl ? '1400px' : ''}">
      <v-row>
        <!-- Left -->
        <v-col cols="12" md="8">

          <!-- Featured -->
          <unvergessen-focus style="margin-bottom: 70px" :page="featured"></unvergessen-focus>

          <!-- Candle Feed -->
          <unvergessen-popular-pages style="margin-bottom: 70px"></unvergessen-popular-pages>

          <!-- Candle Feed -->
          <unvergessen-candle-feed style="margin-bottom: 70px"></unvergessen-candle-feed>

          <!-- VIP Pages -->
          <unvergessen-home-heading>
          Neue <template v-slot:underline>Gedenkseiten</template>
          </unvergessen-home-heading>
          <!-- Loader -->
          <div v-if="pages.length <= 0">
            <unvergessen-page-preview-loader style="margin-bottom: 10px" v-for="i in 3" :key="i"></unvergessen-page-preview-loader>
          </div>
          <div v-for="(page, i) in pages" :key="i">
            <unvergessen-page-preview-big :page="page" style="margin-bottom: 10px" v-if="page.last_name !== 'HITLER'"></unvergessen-page-preview-big>
          </div>
          <div style="text-align: center; padding-top: 10px; margin-bottom: 70px">
            <span v-if="!hideBtn" @click="loadBlock">
              <v-icon color="text" style="margin-right: 10px" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">mdi-plus-circle</v-icon>
              <span style="text-decoration: underline">
                Mehr Gedenkseiten anzeigen
              </span>
            </span>
          </div>

          <!-- Candles -->
          <!-- <unvergessen-candles-home></unvergessen-candles-home> -->

        </v-col>
        <!-- Right -->
        <v-col cols="12" md="4">

          <!-- Memorial Pages -->
          <!-- <unvergessen-memorial-pages style="margin-bottom: 20px" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"></unvergessen-memorial-pages> -->

          <!-- Memorial Post -->
          <unvergessen-memorial-ad v-if="false" style="margin-bottom: 20px" :style="{ 'margin-top': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? '70px' : '40px' }"></unvergessen-memorial-ad>

           <!-- Bear CTA -->
          <unvergessen-cta
            style="margin-bottom: 20px"
            :style="{ 'margin-top': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? '70px' : '0px' }"
            banner="https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Sonnenuntergang_Meer.jpg"
            headline="Das Unvergessen Bärchen"
            :images="['https://resources.eternio.com/static/recent/Unvergessenbaerchen_1.jpg']"
            text="Handarbeit aus der Kleidung Verstorbener"
            subtext="Individuell ・ Mit Liebe handgemacht ・ Hochwertig"
            target="Bears"
            type="bears"
          ></unvergessen-cta>

          <!-- Categories -->
          <unvergessen-categories style="margin-bottom: 20px"></unvergessen-categories>

          <!-- Regions -->
          <!-- <unvergessen-regions style="margin-bottom: 20px" v-if="false"></unvergessen-regions> -->

          <!-- Numbers -->
          <unvergessen-numbers :style="{ 'margin-bottom': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md ? '0' : '20px' }"></unvergessen-numbers>

          <unvergessen-facebook-follow :home="true" class="hidden-md-and-down"></unvergessen-facebook-follow>
        </v-col>
      </v-row>
    </v-container>

    <v-container :style="{'max-width': $vuetify.breakpoint.xl ? '1400px' : ''}">
      <!-- Build Page -->
      <unvergessen-build-page style="margin-bottom: 20px"></unvergessen-build-page>

      <!-- Blog Articles -->
      <unvergessen-blog-article-card style="margin-bottom: 20px"></unvergessen-blog-article-card>

      <!-- Popular -->
      <unvergessen-known-from style="margin-bottom: 20px"></unvergessen-known-from>

      <!-- About -->
      <unvergessen-about style="margin-bottom: 20px"></unvergessen-about>
    </v-container>
    <unvergessen-new-page-dialog :dialog="dialog" @close="dialog = false"></unvergessen-new-page-dialog>
  </div>
</template>

<script>
import MemorialAd from '@/components/memorial_post/MemorialAdLight.vue'
import Categories from '@/components/home/landing/Categories.vue'
import Numbers from '@/components/home/landing/Numbers.vue'
// import Regions from '@/components/home/landing/Regions.vue'
import PagePreviewBig from '@/components/home/landing/PagePreviewBig.vue'
import Focus from '@/components/home/landing/Focus.vue'
import CandleFeed from '@/components/home/landing/CandlesHome.vue'
import PopularPages from '@/components/home/landing/PopularPages.vue'
// import MemorialPages from '@/components/home/landing/MemorialPages.vue'
import BuildPage from '@/components/home/landing/BuildPageCard.vue'
import BlogArticleCard from '@/components/home/landing/BlogArticleCard.vue'
import FacebookFollow from '@/components/footer/FacebookFollow.vue'
import KnownFrom from '@/components/home/landing/KnownFrom.vue'
import About from '@/components/home/landing/About.vue'
import NewPageDialog from '@/components/global/NewPageDialog.vue'
import PagePreviewLoader from '@/components/home/landing/PagePreviewLoader.vue'
import HomeHeading from '@/components/home/landing/HomeHeading.vue'
import CTABlog from '@/components/global/CTABlog.vue'
// import CandlesHome from '@/components/home/landing/CandlesHome.vue'
import axios from 'axios'

export default {
  name: 'Home',
  data () {
    return {
      imageUrls: [],
      pages: [],
      hideBtn: false,
      searchString: '',
      dialog: false,
      featured: {}
    }
  },
  methods: {
    search () {
      this.$router.push({ name: 'Search', query: { str: this.searchString } })
    },
    loadBlock () {
      if (this.pages) {
        axios.get('/search/newPagesLandingPage', { params: { set: Math.ceil(this.pages.length / 3) } })
          .then(res => {
            if (res.data.length > 0) {
              this.pages = this.pages.concat(res.data)
            } else {
              this.hideBtn = true
            }
          })
          .catch(error => {
          })
      }
    }
  },
  created () {
    // Get Recent
    axios.get('/search/newPagesLandingPage')
      .then(res => {
        this.pages = res.data
      })
      .catch(error => {
      })
    axios.get('/search/featured')
      .then(res => {
        this.featured = res.data
      })
      .catch(error => {})
  },
  components: {
    // unvergessenMemorialPages: MemorialPages,
    unvergessenMemorialAd: MemorialAd,
    unvergessenCategories: Categories,
    unvergessenPagePreviewBig: PagePreviewBig,
    unvergessenNumbers: Numbers,
    // unvergessenRegions: Regions,
    unvergessenBuildPage: BuildPage,
    unvergessenBlogArticleCard: BlogArticleCard,
    unvergessenKnownFrom: KnownFrom,
    unvergessenAbout: About,
    unvergessenNewPageDialog: NewPageDialog,
    unvergessenFacebookFollow: FacebookFollow,
    unvergessenPagePreviewLoader: PagePreviewLoader,
    unvergessenFocus: Focus,
    unvergessenHomeHeading: HomeHeading,
    unvergessenCta: CTABlog,
    unvergessenCandleFeed: CandleFeed,
    unvergessenPopularPages: PopularPages
    // unvergessenCandlesHome: CandlesHome
  }
}
</script>

<style scoped>
  .home {
    background-color: var(--v-grey4-base)
  }

  * >>> .v-input__slot {
    background-color: white !important;
    box-shadow: 0px 0px 15px #3f3f3f2e;
    height: 55px;
    margin-top: -15px
  }
</style>
