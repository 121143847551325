<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px; margin-bottom: 15px" v-ripple="false" @click="isMemDay ? $router.push({ name: 'MemorialDays', params: { page_id: skeleton.page_id } }) : $router.push({ name: 'Page', params: { page_id: skeleton.page_id } })">
      <!-- Name -->
      <div class="font-weight-medium" :class="{ title: !$vuetify.breakpoint.xs }" style="padding: 5px 10px">
        <template v-if="isMemDay">Gedenkseite für {{ skeleton.name}}</template>
        <template v-else>{{ skeleton.title }} {{ skeleton.first_name}} {{skeleton.last_name}}</template>
      </div>
      <v-divider></v-divider>

      <!-- Page -->
      <div :style="{ 'background-image': 'url(\'' + bannerUrl + '\')' }" style="background-size: cover">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8)" :style="{ padding: ($vuetify.breakpoint.xs) ? '0 2px' : '0 5%' }">
          <v-container :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 10px' : '' }">
            <v-row>
              <v-col cols="4" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'padding-top':  skeleton.type !== 'ad' ? $vuetify.breakpoint.xs ? '25px' : '35px' : '10px' }" style="padding-right: 0">
                <template v-if="!isMemDay">
                  <div>{{ skeleton.residence !== ' ' && skeleton.residence !== '' && skeleton.residence !== null ? 'aus ' + skeleton.residence : '' }}</div>
                  <div v-if="skeleton['birth_name']">geb. {{ skeleton['birth_name'] }}</div>
                </template>
              </v-col>
              <v-col cols="4" style="text-align: center">
                <!-- If Image -->
                <v-avatar :size="$vuetify.breakpoint.xs ? '70' : '110'" v-if="skeleton.profile_image && skeleton.type !== 'ad'" style="border-color: white; border-style: solid; border-width: 2px">
                  <v-img :src="skeleton.profile_image" alt="Profile Picture"></v-img>
                </v-avatar>
                <v-icon :style="{ width: $vuetify.breakpoint.xs  ? '70px' : '110px', height: $vuetify.breakpoint.xs ? '70px' : '110px' }" style="border-color: white; border-style: solid; border-width: 2px; border-radius: 50%" v-if="!skeleton.profile_image && skeleton.type !== 'ad'">$account-circle</v-icon>
              </v-col>
              <v-col cols="4" style="text-align: right; padding-left: 0" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'padding-top':  skeleton.type !== 'ad' ? $vuetify.breakpoint.xs ? '25px' : '35px' : '10px' }">
                <template v-if="!isMemDay">
                  <div v-if="skeleton.birth"><span style="margin-left: 3%; margin-right: 5px">*</span> {{ transformDate(skeleton.birth) }}</div>
                  <div><v-icon :size="$vuetify.breakpoint.xs ? '14' : '18'" style="margin-top: -5px" color="text">mdi-christianity</v-icon> {{ transformDate(skeleton.obit) }}</div>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>

      <!-- Buttons -->
      <div v-ripple style="width: 100%; padding: 4px; text-align: center; cursor: pointer" class="grey4">
        {{ page.type === 'ad' ? 'Zum Trauerfall' : 'Zur Gedenkseite' }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['page', 'noElevation', 'candles'],
  data () {
    return {
      expand: false
    }
  },
  computed: {
    skeleton () {
      return this.page || {}
    },
    bannerUrl () {
      return this.skeleton.type === 'ad' ? '' : this.skeleton.banner ? this.skeleton.banner : ''
    },
    isMemDay () {
      return this.skeleton.type === 'memorial_day'
    }
  },
  methods: {
    transformDate (date) {
      if (date) {
        var tmp = date.split('T')[0]
        tmp = tmp.split('-')
        return tmp[2] + '.' + tmp[1] + '.' + tmp[0]
      }
      return ''
    },
    lazyTransformation (str) {
      if (str) {
        var strArray = str.split('.')
        strArray[strArray.length - 2] = strArray[strArray.length - 2] + '_lazy'
        return strArray.join('.')
      }
      return ''
    }
  }
}
</script>
