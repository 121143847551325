<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" :viewBox="($vuetify.breakpoint.xs) ? '0 0 2560 152.383' : ($vuetify.breakpoint.sm) ? '0 0 2560 72.383' : '0 0 2560 52.383'" :style="{ 'background-color': background }" style="overflow: visible; position: relative" preserveAspectRatio="xMaxYMin meet">
    <path id="Pfad_1172" data-name="Pfad 1172" :style="{ fill: fill }" class="cls-1" d="M2560-2.156s-260.111,24.9-696.958,27.339S1288.7,11.019,812.612,7.6,0,35.911,0,35.911V-16.472H2560Z" transform="translate(0 16.472)"/>
  </svg>
</template>

<script>
export default {
  props: ['background', 'fill']
}
</script>
