import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Candle from '@/assets/customIcons/Candle.vue'
import Movie from '@/assets/customIcons/Movie.vue'
import Book from '@/assets/customIcons/Book.vue'
import World from '@/assets/customIcons/World.vue'
import Security from '@/assets/customIcons/Security.vue'
import People from '@/assets/customIcons/People.vue'
import HandHeart from '@/assets/customIcons/HandHeart.vue'
import Newspaper from '@/assets/customIcons/Newspaper.vue'
import NewspaperDark from '@/assets/customIcons/NewspaperDark.vue'
import HandBlack from '@/assets/customIcons/HandBlack.vue'
import HandOrange from '@/assets/customIcons/HandOrange.vue'
import LetterBlack from '@/assets/customIcons/LetterBlack.vue'
import CalendarBlack from '@/assets/customIcons/CalendarBlack.vue'
import NavigationBlack from '@/assets/customIcons/NavigationBlack.vue'
import Polaroid from '@/assets/customIcons/Polaroid.vue'
import ShareBlack from '@/assets/customIcons/ShareBlack.vue'
import NewPicture from '@/assets/customIcons/NewPicture.vue'
import Update from '@/assets/customIcons/Update.vue'
import Pen from '@/assets/customIcons/Pen.vue'
import CandleSad from '@/assets/customIcons/CandleSad.vue'
import FAQMan from '@/assets/customIcons/FAQMan.vue'
import LoginUser from '@/assets/customIcons/LoginUser.vue'
import Cog from '@/assets/customIcons/Cog.vue'
import CogWhite from '@/assets/customIcons/CogWhite.vue'
import Account from '@/assets/customIcons/Account.vue'
import AccountCircle from '@/assets/customIcons/AccountCircle.vue'
import Mail from '@/assets/customIcons/Mail.vue'
import MailText from '@/assets/customIcons/MailText.vue'
import AccountWhite from '@/assets/customIcons/AccountWhite.vue'
import MailWhite from '@/assets/customIcons/MailWhite.vue'
import Lock from '@/assets/customIcons/Lock.vue'
import LockWhite from '@/assets/customIcons/LockWhite.vue'
import Flame from '@/assets/customIcons/Flame.vue'
import Diamond from '@/assets/customIcons/Diamond.vue'
import InfinityIcon from '@/assets/customIcons/Infinity.vue'
import AboutUs from '@/assets/customIcons/AboutUs.vue'
import PostBox from '@/assets/customIcons/PostBox.vue'
import Dove from '@/assets/customIcons/Dove.vue'
import Heart from '@/assets/customIcons/Heart.vue'
import MoneyBack from '@/assets/customIcons/MoneyBack.vue'
import Tree from '@/assets/customIcons/Tree.vue'
import Trees from '@/assets/customIcons/Trees.vue'
import TreesTwo from '@/assets/customIcons/TreesTwo.vue'
import TreesFive from '@/assets/customIcons/TreesFive.vue'
import TreesTen from '@/assets/customIcons/TreesTen.vue'
import PremiumCandle from '@/assets/customIcons/PremiumCandle.vue'
import Bear from '@/assets/customIcons/Bear.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#febf7c', // '#f8ac5b',
        'primary-hover': '#eb9234',

        secondary: '#f7eee6',
        'secondary-hover': '#ebd9c7',

        btnsPage: '#f9dabe',
        text: '#404040',

        orange: '#fba346',
        orange2: '#e19c53',
        orange3: '#febf7c',
        orange4: '#fbcc9a',

        grey: '#bbbbbb',
        grey2: '#d0d0d0',
        grey3: '#e8e8e8',
        grey4: '#fafafa',

        beige: '#eee3d8',

        brown: '#835637',
        brown2: '#9e653e',
        brown3: '#a35418',
        brown4: '#cc7a3b',

        skin: '#bf8e75',
        skin2: '#d3a489',
        skin3: '#eeb08f',
        skin4: '#eab599',
        skin5: '#f6caae',

        gold: '#db9d5c',
        gold2: '#e1a560',
        gold3: '#fbd879',
        gold4: '#fbe096',

        green: '#79c28c',
        green2: '#90dca3',

        red: '#f14242',
        red2: '#f55858',

        success: '#24bf77', // '#47DA6C',
        error: '#f90000'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    values: {
      candle: {
        component: Candle
      },
      movie: {
        component: Movie
      },
      book: {
        component: Book
      },
      world: {
        component: World
      },
      security: {
        component: Security
      },
      people: {
        component: People
      },
      dove: {
        component: Dove
      },
      heart: {
        component: Heart
      },
      'heart-in-hand': {
        component: HandHeart
      },
      newspaper: {
        component: Newspaper
      },
      'newspaper-dark': {
        component: NewspaperDark
      },
      'hand-dark': {
        component: HandBlack
      },
      'hand-orange': {
        component: HandOrange
      },
      'letter-dark': {
        component: LetterBlack
      },
      'calendar-dark': {
        component: CalendarBlack
      },
      'navigation-dark': {
        component: NavigationBlack
      },
      polaroid: {
        component: Polaroid
      },
      'share-dark': {
        component: ShareBlack
      },
      'new-picture': {
        component: NewPicture
      },
      update: {
        component: Update
      },
      'pen-orange': {
        component: Pen
      },
      'candle-sad': {
        component: CandleSad
      },
      'faq-man': {
        component: FAQMan
      },
      'login-user': {
        component: LoginUser
      },
      cog: {
        component: Cog
      },
      'cog-white': {
        component: CogWhite
      },
      account: {
        component: Account
      },
      'account-circle': {
        component: AccountCircle
      },
      mail: {
        component: Mail
      },
      'mail-text': {
        component: MailText
      },
      'account-white': {
        component: AccountWhite
      },
      'mail-white': {
        component: MailWhite
      },
      lock: {
        component: Lock
      },
      'lock-white': {
        component: LockWhite
      },
      flame: {
        component: Flame
      },
      diamond: {
        component: Diamond
      },
      infinity: {
        component: InfinityIcon
      },
      'about-us': {
        component: AboutUs
      },
      postbox: {
        component: PostBox
      },
      'money-back': {
        component: MoneyBack
      },
      tree: {
        component: Tree
      },
      trees: {
        component: Trees
      },
      'trees-two': {
        component: TreesTwo
      },
      'trees-five': {
        component: TreesFive
      },
      'trees-ten': {
        component: TreesTen
      },
      'premium-candle': {
        component: PremiumCandle
      },
      bear: {
        component: Bear
      }
    }
  }
})
