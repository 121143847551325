<template>
  <div>
    <div v-if="blockTooltip">
      <div :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'free'">
        <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }" v-if="active === undefined || active">
          <div class="candle-flame"></div>
        </div>
        <div style="height: 20px" v-if="!active && active !== undefined"></div>
        <img src="@/assets/Kerze_Grau.png" alt="" class="candle-image">
      </div>
      <div :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'premium2'">
        <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }" v-if="active === undefined || active">
          <div class="candle-flame"></div>
        </div>
        <div style="height: 20px" v-if="!active && active !== undefined"></div>
        <img src="@/assets/Kerze_Bronze.png" alt="" class="candle-image">
      </div>
      <div :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'premium1'">
        <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }" v-if="active === undefined || active">
          <div class="candle-flame"></div>
        </div>
        <div style="height: 20px" v-if="!active && active !== undefined"></div>
        <img src="@/assets/Kerze_Creme.png" alt="" class="candle-image">
      </div>
      <div :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'forever'">
        <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }" v-if="active === undefined || active">
          <div class="candle-flame"></div>
        </div>
        <div style="height: 20px" v-if="!active && active !== undefined"></div>
        <img src="@/assets/Kerze_Blau.png" alt="" class="candle-image" v-if="color === 'blue'">
        <img src="@/assets/Kerze_Gold.png" alt="" class="candle-image" v-else>
      </div>
    </div>
    <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }">
        <div :class="{ glow: candle.highlighted || userAssigned }" :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'free'" v-on="on" v-bind="attrs">
          <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }">
            <div class="candle-flame"></div>
          </div>
          <img src="@/assets/Kerze_Grau.png" alt="" class="candle-image">
          <div style="margin-top: -10px; text-align: center" class="white--text" v-if="candle.highlighted">
            Neu
          </div>
          <div style="margin-top: -10px; text-align: center;" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'line-height': $vuetify.breakpoint.xs ? '14px' : '16px' }" class="white--text" v-if="!candle.highlighted && userAssigned">
            Deine Kerze
          </div>
        </div>
        <div :class="{ glow: candle.highlighted || userAssigned }" :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'premium1'" v-on="on" v-bind="attrs">
          <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }">
            <div class="candle-flame"></div>
          </div>
          <img src="@/assets/Kerze_Creme.png" alt="" class="candle-image">
          <div style="margin-top: -10px; text-align: center" class="white--text" v-if="candle.highlighted">
            Neu
          </div>
          <div style="margin-top: -10px; text-align: center;" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'line-height': $vuetify.breakpoint.xs ? '14px' : '16px' }" class="white--text" v-if="!candle.highlighted && userAssigned">
            Deine Kerze
          </div>
        </div>
        <div :class="{ glow: candle.highlighted || userAssigned }" :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'premium2'" v-on="on" v-bind="attrs">
          <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }">
            <div class="candle-flame"></div>
          </div>
          <img src="@/assets/Kerze_Bronze.png" alt="" class="candle-image">
          <div style="margin-top: -10px; text-align: center" class="white--text" v-if="candle.highlighted">
            Neu
          </div>
          <div style="margin-top: -10px; text-align: center;" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'line-height': $vuetify.breakpoint.xs ? '14px' : '16px' }" class="white--text" v-if="!candle.highlighted && userAssigned">
            Deine Kerze
          </div>
        </div>
        <div :class="{ glow: candle.highlighted || userAssigned }" :style="{ width: size }" style="margin: auto; position: relative; z-index: 1" v-if="type === 'forever'" v-on="on" v-bind="attrs">
          <div :class="{ 'candle-flame-container_mobile': $vuetify.breakpoint.xs, 'candle-flame-container': !$vuetify.breakpoint.xs }">
            <div class="candle-flame"></div>
          </div>
          <img src="@/assets/Kerze_Blau.png" alt="" class="candle-image" v-if="color === 'blue'">
          <img src="@/assets/Kerze_Gold.png" alt="" class="candle-image" v-else>
          <div style="margin-top: -10px; text-align: center" class="white--text" v-if="candle.highlighted">
            Neu
          </div>
          <div style="margin-top: -10px; text-align: center;" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '', 'line-height': $vuetify.breakpoint.xs ? '14px' : '16px' }" class="white--text" v-if="!candle.highlighted && userAssigned">
            Deine Kerze
          </div>
        </div>
      </template>
      <div style="max-width: 300px; padding: 10px 2px 0" v-if="candle">
        <div class="font-weight-bold white--text">
          {{ candle.type === "free" ? 'Wochenkerze von ' : candle.type === 'premium1' ? 'Jahreskerze von ' : candle.type === 'premium2' ? 'Monatskerze von ' : 'Ewige Kerze von ' }}{{ candle.by }}
        </div>
        <p class="white--text" style="margin-top: 2px">
          {{ candle.content }}
        </p>
      </div>
      <div class="white--text" style="max-width: 300px" v-else>
        Eine schön animierte Kerze, die ein angenehm warmes Licht erzeugt. Sie wird im direkt sichtbaren oberen Bereich der Gedenkseite angezündet.
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ['candle', 'type', 'size', 'blockTooltip', 'color', 'active'],
  computed: {
    userAssigned () {
      var query = this.$route.query
      return query.highlightCandle && query.cid === this.candle._id
    }
  }
}
</script>

<style scoped>
  .candle-flame {
    background-image: url('~@/assets/flame.svg');
    background-size: 100% 100%;
    width: 30%;
    padding-top: 40%;
    margin-left: 36%;
    animation-name: flame;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 0 100%;
    animation-delay: inherit;
  }

  .candle-flame-container_mobile {
    margin-bottom: 4px
  }

  .candle-flame-container {
    margin-bottom: 2px
  }

  .candle-image {
    display: block;
    width: 100%
  }

  @keyframes flame {
    0% {
      transform: translateY(.5rem) scaleY(1);
    }
    20% {
      transform: translateY(.5rem) scaleY(.95) skew(2deg);
    }
    30% {
      transform: translateY(.5rem) scaleY(.96) skew(-1deg);
    }
    60% {
      transform: translateY(.5rem) scaleY(1) skew(1deg);
    }
    80% {
      transform: translateY(.5rem) scaleY(.94) skew(-1deg);
    }
    100% {
      transform: translateY(.5rem) scaleY(1);
    }
  }

  .glow {
    border-radius: 50%;
    background-color: #cccc;
    box-shadow: 0 0 40px 20px #cbcbcb;
  }
</style>
