import axios from 'axios'
import router from '@/router/router'
import store from '@/store/store'

var jwtDecode = require('jwt-decode')

const state = {
  loggedIn: false,
  userId: null,
  token: null,
  keepMeLoggedIn: false,
  fb: {
    scope: {},
    model: {},
    FB: {}
  }
}

const getters = {
  isLoggedIn: state => {
    return state.loggedIn
  },
  token: state => {
    return state.token
  },
  fb: state => {
    return state.fb
  }
}

const mutations = {
  login: (state, payload) => {
    state.userId = payload.u_id
    state.token = 'Bearer ' + payload.token
    axios.defaults.headers.common.Authorization = 'Bearer ' + payload.token
    localStorage.setItem('unvergessenToken', payload.token)
    if (payload.keepMeLoggedIn) {
      state.keepMeLoggedIn = true
    }
    state.loggedIn = true
  },
  setFbScope: (state, payload) => {
    state.fb.scope = payload
  },
  setFb: (state, payload) => {
    state.fb.FB = payload
  },
  setLoggedIn: state => {
    state.loggedIn = true
    state.keepMeLoggedIn = true
  },
  logout: state => {
    state.token = null
    state.userId = null
    delete axios.defaults.headers.common.Authorization
    localStorage.removeItem('unvergessenToken')
    if (state.keepMeLoggedIn) {
      state.keepMeLoggedIn = false
    }
    if (state.fb.scope.logout) {
      state.fb.scope.logout()
    }
    state.loggedIn = false
  },
  updateToken: (state, payload) => {
    state.token = 'Bearer' + payload.data.token
    axios.defaults.headers.common.Authorization = 'Bearer ' + payload.data.token
    localStorage.setItem('unvergessenToken', payload.data.token)
    state.loggedIn = true
    if (payload.page_id) {
      router.push({ name: 'Page', params: { page_id: payload.page_id } }).catch(error => {})
    }
  }
}

const actions = {
  initAnalytics: (context, source) => {
    if (localStorage.sid) {
      axios.defaults.headers.common.sid = localStorage.sid
    } else {
      axios.post('/analytics/session/init', { source: source })
        .then(res => {
          localStorage.setItem('sid', res.data.id)
          axios.defaults.headers.common.sid = res.data.id
          if (router.currentRoute) {
            axios.post('/analytics/session/event', { event: { category: 'Init', action: 'Entered_Page', label: router.currentRoute.path, value: 0 } })
          }
        })
        .catch(() => {})
    }
  },
  login: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', { mail: payload.email, pwd: payload.pwd, long: payload.keepMeLoggedIn })
        .then(res => {
          var data = {
            u_id: res.data.u_id,
            token: res.data.token,
            keepMeLoggedIn: payload.keepMeLoggedIn
          }
          if (!payload.keepMeLoggedIn) {
            context.dispatch('setRenewTimer', { time: 7200, keepMeLoggedIn: payload.keepMeLoggedIn })
          }
          context.commit('login', data)

          context.dispatch('loadUser')
          const r = router.history.current.query.r
          const tft = router.history.current.query.tft
          const pid = router.history.current.query.pid
          if (r && !tft) {
            router.push({ name: 'Verify2', params: { page_id: r } }).catch(error => {})
          } else if (pid && !r && !tft) {
            router.push({ name: 'PageDonation', params: { page_id: pid } })
          } else {
            if (payload.onpage) {
            } else {
              router.push({ name: 'Account' }).catch(error => {})
            }
          }
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setLoggedIn: context => {
    var tok = jwtDecode(localStorage.unvergessenToken)
    if (tok.exp > Math.floor(new Date().getTime() / 1000)) {
      context.dispatch('renewAuth', { keepMeLoggedIn: tok.exp - tok.iat > 10000 })
      context.commit('setLoggedIn')
      context.dispatch('loadUser')
    }
  },
  logout: context => {
    axios.post('/auth/logout')
    context.commit('logout')
    context.commit('deleteUser')
    context.commit('setMessages', [])
    if (router.history.current.name === 'Page') {
      axios.get('/page', { params: { id: router.history.current.params.page_id } })
        .then(res => {
          context.commit('setPage', res.data)
        })
        .catch(error => {
          store.dispatch('sendTo404', router.currentRoute.path)
          router.push({ name: '404' })
        })
    }
  },
  register: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/auth/register', payload)
        .then(res => {
          res.data = {
            u_id: res.data.u_id,
            token: res.data.token,
            keepMeLoggedIn: true
          }
          context.dispatch('setRenewTimer', { time: 7200, keepMeLoggedIn: payload.keepMeLoggedIn })
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  renewAuth: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.get('/auth/renew', { params: { long: payload.keepMeLoggedIn } })
        .then(res => {
          context.dispatch('setRenewTimer', { time: 7200, keepMeLoggedIn: payload.keepMeLoggedIn })
          context.commit('updateToken', { data: res.data, page_id: payload.page_id })
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setRenewTimer: (context, payload) => {
    setTimeout(() => {
      context.dispatch('renewAuth', { keepMeLoggedIn: payload.keepMeLoggedIn })
    }, (payload.time * 1000) - 60000)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
