import { render, staticRenderFns } from "./Infinity.vue?vue&type=template&id=22c9f50a&scoped=true&"
var script = {}
import style0 from "./Infinity.vue?vue&type=style&index=0&id=22c9f50a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c9f50a",
  null
  
)

export default component.exports