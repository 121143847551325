<template>
  <div>
    <!-- Heading -->
    <unvergessen-home-heading>
      Heute <template v-slot:underline>im Fokus</template>
    </unvergessen-home-heading>

    <!-- Featured Page -->
    <div v-if="page && Object.keys(page).length === 0 && page.constructor === Object">
      <unvergessen-page-preview-loader style="margin-bottom: 70px"></unvergessen-page-preview-loader>
    </div>
    <div v-else>
      <unvergessen-page-preview :page="page"></unvergessen-page-preview>
    </div>
  </div>
</template>

<script>
import PagePreviewBig from '@/components/search/SearchSkeleton2.vue'
import HomeHeading from '@/components/home/landing/HomeHeading.vue'
import PagePreviewLoader from '@/components/home/landing/PagePreviewLoader.vue'

export default {
  props: ['page'],
  components: {
    unvergessenPagePreview: PagePreviewBig,
    unvergessenHomeHeading: HomeHeading,
    unvergessenPagePreviewLoader: PagePreviewLoader
  }
}
</script>
