<template>
  <div>
    <v-card class="unvergessen-shadow" style="text-align: center; padding: 20px" :style="{ 'border-radius': home ? '10px' : '' }">
      <v-img src="@/assets/simple-facebook.png" alt="facebook logo" style="max-width: 40px; margin: 10px auto"></v-img>
      <div class="title">
        Du bist nicht alleine
      </div>
      <div style="margin-bottom: 10px">
        Folge uns auf Facebook und werde Teil einer tollen und großen Gemeinschaft, die dich und deine Trauer versteht.
      </div>
      <v-btn color="#3916C9" rounded width="100%" max-width="300px" class="white--text" style="text-transform: none" @click="pushToFacebook">
        Auf Facebook folgen
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['home'],
  methods: {
    pushToFacebook () {
      window.open('https://www.facebook.com/UnvergessenDuFehlst', '_blank')
    }
  }
}
</script>
