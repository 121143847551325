const state = {
  partnerPayment: {
    mail: ''
  },
  relativePayment: {
    method: ''
  }
}

const getters = {
  partnerMail: state => {
    return state.partnerPayment.mail
  },
  relativePayment: state => {
    return state.relativePayment.method
  }
}

const mutations = {
  setPartnerMail: (state, payload) => {
    state.partnerPayment.mail = payload
  },
  setRelativePayment: (state, payload) => {
    state.relativePayment.method = payload
  }
}

const actions = {
  setPartnerMail: (context, payload) => {
    context.commit('setPartnerMail', payload)
  },
  setRelativePayment: (context, payload) => {
    context.commit('setRelativePayment', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
