<template>
  <div style="margin: 2% 0">
    <v-card style="padding: 2%; border-radius: 0 !important">
      <v-img :src="srcUrl" :lazy-src="lazyTransformation(srcUrl)" max-height="70vh"></v-img>
      <div style="padding-top: 2%">
        <v-row>
          <v-col cols="7" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '7px' : 'auto' }">
            <unvergessen-button
              class="font-weight-regular text-uppercase"
              style="background-color: #bbbbbb !important;"
              :class="{
                'category-button': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm),
                'category-button-mobile': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm),
                'body-2': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm),
                'caption': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
              }"
              @click="$router.push({ name: 'BlogCategory', params: { category: category } })"
            >{{ category }}</unvergessen-button>
            <span v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Lesedauer ca. {{ duration }} Minuten</span>
          </v-col>
          <v-col style="padding: 0;" :style="{ 'padding-right': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '5px' : '0' }">
            <div>
              <div v-ripple class="social-icon" @click="share('mail')" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '7px' : '6px' }" style="float: right">
                <v-icon color="primary" :size="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '20' : '35'" style="margin-bottom: 5px">mdi-email-edit-outline</v-icon>
              </div>
              <div class="hidden-md-and-up">
                <div v-ripple class="social-icon" @click="share('messenger-mobile')" style="float: right">
                  <img src="@/assets/messenger.svg" loading="lazy" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '15' : '25'" style="display: block; margin: 10px auto">
                </div>
              </div>
              <div class="hidden-sm-and-down">
                <div v-ripple class="social-icon" @click="share('messenger-desktop')" style="float: right">
                  <img src="@/assets/messenger.svg" loading="lazy" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '15' : '25'" style="display: block; margin: 10px auto">
                </div>
              </div>
              <div v-ripple class="social-icon" @click="share('facebook')" style="float: right">
                <img src="@/assets/facebook.png" loading="lazy" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '15' : '25'" style="display: block; margin: 10px auto">
              </div>
              <div v-ripple class="social-icon" @click="share('whatsapp')" style="float: right">
                <img src="@/assets/whatsapp.svg" loading="lazy" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '22' : '32'" :style="{ 'margin-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '7px' : '6px' }" style="display: block; margin: 10px auto;">
              </div>
            </div>
          </v-col>
        </v-row>
        <span v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Lesedauer ca. {{ duration }} Minuten</span>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['srcUrl', 'category', 'duration'],
  methods: {
    lazyTransformation (str) {
      var strArray = str.split('.')
      strArray[strArray.length - 2] = strArray[strArray.length - 2] + '_lazy'
      return strArray.join('.')
    },
    share (platform) {
      switch (platform) {
        case 'whatsapp':
          window.open('https://api.whatsapp.com/send?text=https://unvergessen.de/Blog/' + this.$route.params.article_id, '_blank')
          break
        case 'facebook':
          window.open('https://www.facebook.com/sharer/sharer.php?u=https://unvergessen.de/Blog/' + this.$route.params.article_id, '_blank')
          break
        case 'messenger-desktop':
          window.open('http://www.facebook.com/dialog/send?app_id=551508878857250&link=https://unvergessen.de/Blog/' + this.$route.params.article_id + '&redirect_uri=https://unvergessen.de/Blog/' + this.$route.params.article_id, '_blank')
          break
        case 'messenger-mobile':
          window.open('fb-messenger://share?app_id=551508878857250&link=' + encodeURIComponent('https://unvergessen.de/Blog/' + this.$route.params.article_id), '_blank')
          break
        case 'mail':
          window.open('mailto:?subject=Ein interessanter Artikel für dich' + '&body=Den Link zum Artikel findest du hier:%0Ahttps://unvergessen.de/Blog/' + this.$route.params.article_id, '_blank')
          break
        case 'instagram':
          window.open('https://api.whatsapp.com/send?text=https://unvergessen.de/Blog/' + this.$route.params.article_id, '_blank')
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>
  .category-button {
    min-height: 25px !important;
    height: 25px !important;
    width: 200px !important;
    border-radius: 5px !important;
    margin-right: 20px;
    float: left
  }

  .category-button-mobile {
    min-height: 20px !important;
    height: 20px !important;
    width: 150px !important;
    border-radius: 5px !important;
    margin-right: 20px;
    margin-top: 0px !important;
    float: left
  }

  .social-icon {
    float: left;
    padding: 0
  }
</style>
