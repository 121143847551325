<template>
  <v-list flat dense class="unvergessen-list">
    <v-list-item class="unvergessen-list-item">
      <v-list-item-content class="unvergessen-list-item-content">
        <v-list-item-title>Gedenkseite von {{ page.first_name + " " + page.last_name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item-group>
      <v-hover v-slot:default="{ hover }">
        <v-list-item
          :class="hover ? 'unvergessen-hover' : ''"
          :to="{ name:'Page', params: { page_id: page.page_id } }"
        >
          <v-list-item-icon>
            <v-icon color="primary" style="width: 25px; height: 25px">$account</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="unvergessen-list-item-content">
            <v-list-item-title>
              Zur Gedenkseite
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
      <v-divider></v-divider>
      <v-hover v-slot:default="{ hover }">
        <v-list-item
          :class="hover ? 'unvergessen-hover' : ''"
          :to="{ name:'PageSettings', params: { page_id: page.page_id } }"
        >
          <v-list-item-icon>
            <v-icon color="primary">$cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="unvergessen-list-item-content">
            <v-list-item-title>
              Seiteneinstellungen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
      <v-divider></v-divider>
      <v-hover v-slot:default="{ hover }">
        <v-list-item
          :class="hover ? 'unvergessen-hover' : ''"
          :to="{ name:'PagePayment', params: { page_id: page.page_id } }"
          v-if="page.isV"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="unvergessen-list-item-content">
            <v-list-item-title>
              Laufzeit
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: ['page'],
  methods: {
  }
}
</script>

<style scoped>
  .unvergessen-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .unvergessen-list-item {
    background-color: var(--v-secondary-base)
  }

  .unvergessen-list-item-content {
    padding: 0px;
  }

  .unvergessen-hover {
    background-color: var(--v-secondary-hover-base)
  }
</style>
