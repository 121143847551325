<template>
  <v-tooltip top color="rgba(30, 30, 30, 0.95)">
    <template v-slot:activator="{ on }">
        <v-icon @click.stop="" :class="{ 'primary--text': !color && !grey, 'white--text': color, 'text--text': grey }" :large="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && !dense" v-on="on">mdi-information-outline</v-icon>
    </template>
    <span>
      <p class="wrapper" style="width: 300px; margin: 0;">
        <span class="font-weight-regular">
          <slot></slot>
        </span>
      </p>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['color', 'dense', 'grey']
}
</script>
