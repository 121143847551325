<template>
  <v-app>
    <unvergessen-nav-bar style="z-index: 200"></unvergessen-nav-bar>
    <v-content class="text--text">
      <router-view :style="{ 'margin-top': (!memorialpost || !($route.name && $route.name !== 'Page' && $route.name.includes('MemorialDays') && !$route.name.includes('PageLanding') && !$route.name.includes('Verify') && !$route.name.includes('MemorialPostAbo') && !$route.name === 'MemorialPostLanding')) && exception ? '-64px' : '' }">
      </router-view>

    </v-content>
    <unvergessen-footer class="text--text" v-if="footerException"></unvergessen-footer>
    <unvergessen-cookie-law :class="{ 'mobile-cookie-container': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" v-if="!cookies && !isSet && (!$route.name.includes('Page') || $route.name === 'PageLanding')" style="background-color: var(--v-secondary-base); z-index: 100">
      <div style="padding: 0 10%; width: 100%; font-size: 14px" :class="{ 'mobile-cookie': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
        <v-row style="padding: 0">
          <v-col cols="12" lg="8" style="padding: 0">
            <div data-nosnippet>
              Wir verwenden Cookies auf Unvergessen.de, um dir auf unserer Seite eine schönere Zeit zu ermöglichen. Manche der Cookies sind leider essenziell notwendig, um eine Webseite zu betreiben.
              Für die Verwendung der anderen Cookies, bitten wir dich um deine Zustimmung. Du kannst deine Entscheidung später jederzeit in unserer <a style="color: grey !important; text-decoration: underline" target="_blank" href="/Datenschutz">Datenschutzerklärung</a> ändern.
            </div>
          </v-col>
          <v-col cols="12" lg="4" style="padding: 0;">
            <div style="height: 100%" :style="{ float: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'left' : 'right' }">
              <v-btn class="grey--text" style="height: 50px; margin-right: 15px; float: left; font-size: 12px" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '120px' : '180px' }" :class="{ 'mobile-cookie-button': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="setCookie('essential')">Essenzielle zulassen</v-btn>
              <v-btn color="success" style="height: 50px; font-size: 12px" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '150px' : '180px' }" :class="{ 'mobile-cookie-button': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="setCookie('all')">Alle zulassen</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </unvergessen-cookie-law>
  </v-app>
</template>

<script>
import NavBar from './components/global/NavBar.vue'
import Footer from './components/global/Footer.vue'
import CookieLaw from 'vue-cookie-law'
var jwtDecode = require('jwt-decode')

require('@/css/globalStyles.css')

export default {
  name: 'App',

  data: () => ({
    cookies: false,
    memorialpost: true,
    cleverPush: true
    //
  }),
  computed: {
    isSet () {
      return this.$cookie.get('unvergessenCookies') || false
    },
    exception () {
      return this.$route.name && (this.$route.name === 'Page' || this.$route.name === 'MemorialDays' || this.$route.name === 'Home' || this.$route.name.includes('PageLanding') || this.$route.name === 'MemorialPostLanding')
    },
    footerException () {
      return this.$route.name && !this.$route.name.includes('Verify') && !this.$route.name.includes('Feedback') && !this.$route.name.includes('PageLanding') && !this.$route.name.includes('MemorialPostAbo') && !this.$route.name.includes('MemorialPost') && !this.$route.name.includes('PageDonation')
    }
  },
  methods: {
    setCookie (type) {
      if (type === 'essential') {
        this.$cookie.set('unvergessenCookies', 'essential', { expires: 'Fri, 31 Dec 9999 23:59:59 GMT' })
        this.cookies = true
        this.$ga.disable()
        window.fbOptout()
      } else {
        this.$cookie.set('unvergessenCookies', 'all', { expires: 'Fri, 31 Dec 9999 23:59:59 GMT' })
        this.cookies = true
        this.$ga.enable()
        window.fbOptin()
      }
    }
  },
  components: {
    'unvergessen-nav-bar': NavBar,
    'unvergessen-footer': Footer,
    unvergessenCookieLaw: CookieLaw
  },
  mounted () {
    const paypalScript = document.createElement('script')
    paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=' + process.env.VUE_APP_PAYPAL_KEY + '&vault=true')
    paypalScript.setAttribute('async', '')
    document.head.appendChild(paypalScript)
  },
  created () {
    if (localStorage.unvergessenToken) {
      const tok = jwtDecode(localStorage.unvergessenToken)
      if (tok.userId === null && tok.exp * 1000 > Date.now()) {
        this.$router.push({ name: 'Page', params: { page_id: tok.pages[0] } })
      }
    } else {
      localStorage.removeItem('unvergessenToken')
    }
    if (this.cleverPush) {
      if (this.$route.name !== 'PageLanding') {
        window.setTimeout(() => {
          var CleverPush = window.CleverPush || []
          CleverPush.push(['triggerOptIn'])
        }, 30000)
      } else {
        window.setTimeout(() => {
          var CleverPush = window.CleverPush || []
          CleverPush.push(['triggerOptIn'])
        }, 90000)
      }
      this.cleverPush = false
    }
  },
  updated () {
    const chatElement = document.getElementsByClassName('umm-19jxf3o')[0]
    if (chatElement) {
      chatElement.style.bottom = (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) && this.$route.name === 'Page' ? '60px' : '2%'
    }
  },
  metaInfo () {
    return {
      title: 'Unvergessen - Gedenkseiten & Traueranzeigen',
      meta: [
        { vmid: 'description', name: 'description', content: 'Bei Unvergessen.de kannst du nach aktuellen und regionalen Trauerfällen suchen oder selbst eine Gedenkseite erstellen.' },
        { vmid: 'og:title', property: 'og:title', content: 'Unvergessen - Gedenkseiten & Traueranzeigen' },
        { vmid: 'og:site_name', property: 'og:site_name', content: 'Unvergessen - Gedenkseiten & Traueranzeigen' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
        { vmid: 'og:image', property: 'og:image', content: 'https://resources.eternio.com/static/home/Erinnerungen_Fotos_in_box.jpg' }
      ]
    }
  }
}
</script>

<style>
  .mobile-cookie {
    font-size: 10px !important
  }

  .mobile-cookie-button {
    font-size: 8px !important;
    margin-top: 15px;
    height: 35px !important
  }

  .mobile-cookie-container {
    padding: 0 0 !important
  }

  div {
    color: var(--v-text-base)
  }

  .v-alert__content {
    color: white
  }

  .v-messages__message {
    font-size: 16px !important;
    line-height: 18px !important;
    color: var(--v-error-base)
  }

  .v-alert--prominent .v-alert__icon:after {
    opacity: 0
  }
</style>
