<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px">
      <!-- Header -->
      <div style="text-align: center; background-image: url('https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Sternenhimmel.jpg')" class="secondary" :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8); padding: 20px 0; border-radius: 10px 10px 0 0">
          Unvergessen.de in Zahlen
        </div>
      </div>

      <div>
        <div class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding: 15px">
          <v-icon style="height: 40px; width: 40px; margin-right: 20px">
            $dove
          </v-icon>
          <span style="font-size: 27px">
            {{ numbers[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </span>
          Besuche
        </div>
        <v-divider class="grey3"></v-divider>
        <div class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding: 15px">
          <v-icon style="height: 40px; width: 40px; margin-right: 20px">$heart</v-icon>
          <span style="font-size: 27px">
            {{ numbers[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
          </span>
          Anteilnahmen
        </div>
        <v-divider class="grey3"></v-divider>
        <div class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding: 15px">
          <v-icon style="width: 40px; height: 60px; margin-right: 20px">$premium-candle</v-icon>
          <span style="font-size: 27px">
            {{ numbers[2].toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
          </span>
          Kerzen
        </div>
        <v-divider class="grey3"></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      numbers: [
        '',
        '',
        ''
      ]
    }
  },
  created () {
    axios.get('/search/numbers')
      .then(res => {
        this.numbers = res.data.numbers
      })
      .catch(error => {})
  }
}
</script>
