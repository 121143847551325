<template>
  <!-- Success -->
  <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="success" max-width="815px" @click:outside="closeDialog()">
    <v-card>
      <div style="width: 98%; padding-top: 2%; position: absolute">
        <v-icon color="primary" style="float: right; cursor: pointer" @click="closeDialog()">mdi-close</v-icon>
      </div>
      <div style="width: 100%;" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '10%' : '4%' }">
        <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="padding-top: 5%" v-if="heading">{{ heading }}</p>
        <p class="font-weight-regular" :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 5% 2% 5%' : '0 14% 2% 14%' }" style="text-align: center" :class="{ 'unvergessen-dialog-subheading': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" v-if="subheading">{{ subheading }}</p>
        <div style="margin: auto; width: 1.5in" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '5%' : '4%' }">
          <v-icon size="1.5in" color="success">mdi-check-circle</v-icon>
        </div>
      </div>
      <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 5% 2% 5%' : '0 14% 2% 14%' }">
        <v-card-text style="padding: 0">
          <p :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" class="font-weight-regular" style="text-align: center; width: 100%; margin: 0.4in 0">
            <slot></slot>
          </p>
          <div style="padding: 0 0.2in">
            <unvergessen-button @click="closeDialog()" :green="true" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin-bottom: 0.2in;">{{ buttonText }}</unvergessen-button>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'buttonText', 'heading', 'specialCase', 'subheading', 'guard'],
  computed: {
    success () {
      return this.dialog
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  },
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        if (this.specialCase) {
          next()
        } else {
          this.$emit('close')
          next(false)
        }
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  }
}
</script>
