<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px" v-ripple="false" @click="$router.push({ name: 'Page', params: { page_id: skeleton.page_id } })">
      <!-- Name -->
      <div class="font-weight-medium" :class="{ title: !$vuetify.breakpoint.xs }" style="padding: 5px 10px">
        {{ skeleton.title }} {{ skeleton.first_name}} {{skeleton.last_name}}
      </div>

      <!-- Page -->
      <div :style="{ 'background-image': 'url(\'' + bannerUrl + '\')' }" style="background: no-repeat center; background-size: cover; ">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8)" :style="{ padding: ($vuetify.breakpoint.xs) ? '0 2px' : '0 5%' }">
          <v-container :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 10px' : '' }">
            <v-row>
              <v-col cols="4" :style="{ 'font-size': $vuetify.breakpoint.xs ? '13px' : '', 'padding-top': $vuetify.breakpoint.xs ? '30px' : '45px' }" style="padding-right: 0">
                <div>{{ skeleton.residence !== ' ' && skeleton.residence !== null ? 'aus ' + skeleton.residence : '' }}</div>
                <div v-if="skeleton['birth_name']">geb. {{ skeleton['birth_name'] }}</div>
              </v-col>
              <v-col cols="4" style="text-align: center">
                <!-- If Image -->
                <v-avatar :size="$vuetify.breakpoint.xs ? '70' : '110'" v-if="skeleton.profile_image" style="border-color: white; border-style: solid; border-width: 2px">
                  <v-img :src="skeleton.profile_image" alt="Profile Picture"></v-img>
                </v-avatar>
                <div :style="{ width: $vuetify.breakpoint.xs ? '70px' : '110px', height: $vuetify.breakpoint.xs ? '70px' : '110px' }" v-else></div>
              </v-col>
              <v-col cols="4" style="text-align: right; padding-left: 0" :style="{ 'font-size': $vuetify.breakpoint.xs ? '13px' : '', 'padding-top': $vuetify.breakpoint.xs ? '30px' : '45px' }">
                <div v-if="skeleton.birth"><span style="margin-left: 3%; margin-right: 5px">*</span> {{ transformDate(skeleton.birth) }}</div>
                <div><v-icon :size="$vuetify.breakpoint.xs ? '14' : '18'" style="margin-top: -5px" color="text">mdi-christianity</v-icon> {{ transformDate(skeleton.obit) }}</div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>

      <!-- Buttons -->
      <div v-ripple style="width: 100%; padding: 4px; text-align: center; cursor: pointer" class="grey4">
        {{ page.type === 'ad' ? 'Zum Trauerfall' : 'Zur Gedenkseite' }}
      </div>

      <!-- <div>
        <div class="smoke"></div>
        <div class="smoke2"></div>
        <div class="candle"></div>
      </div> -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['page'],
  computed: {
    bannerUrl () {
      return this.skeleton.banner || 'https://resources.eternio.com/static/banner/Titelbild_Ast_mit_Schnee.jpg'
    },
    skeleton () {
      return this.page || {}
    }
  },
  methods: {
    transformDate (date) {
      if (!date) return null

      var tmp = date.split('T')[0]
      tmp = tmp.split('-')
      return tmp[2] + '.' + tmp[1] + '.' + tmp[0]
    }
  }
}
</script>

<style scoped>
.candle {
  width: 4%;
  height: 200px;
  z-index: 2;
  background-image: linear-gradient(0deg,rgba(0,0,0,.3) 0,rgba(0,0,0,.2) 30%,rgba(0,0,0,0) 100%),linear-gradient(0deg,rgba(255,255,255,0) 60%,rgba(255,255,255,.45) 90%),linear-gradient(to right,rgba(0,0,0,.08) 0,rgba(0,0,0,0) 40%,rgba(0,0,0,.2) 100%);
  background-color: #fff5f0;
  position: relative;
  border-radius: 900px;
  transform: scaleY(.3333333333) translateY(-100%);
}

.smoke {
  background-image: url(https://elumoo.com/img/candle/smoke.svg);
  background-size: 100% 8.4rem;
  width: 2.8rem;
  opacity: 1;
  height: 8.4rem;
  margin: 0 auto;
  animation-name: smoke;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
