<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      nudge-bottom="20"
      offset-y
      left
      :nudge-width="424"
    >
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon class="unvergessen-nav-bar-icon" style="background-color: rgba(64,64,64,0.3)" v-on="on" @click="loadNotifications()">
          <v-badge :content="notificationCounter" color="red" v-if="notificationCounter > 0">
            <v-icon color="white" large>mdi-bell</v-icon>
          </v-badge>
          <v-icon color="white" large v-if="notificationCounter <= 0">mdi-bell</v-icon>
        </v-app-bar-nav-icon>
      </template>

      <v-card elevation="0">
        <v-list color="secondary" height="48px" style="padding-top: 0 !important;">
          <v-list-item>
            <v-list-item-content style="margin: auto;">
              <v-list-item-title>Benachrichtigungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list style="padding-top: 0px" v-if="loaded">
          <v-list-item style="padding: 0px">
            <v-card
              class="mx-auto"
              max-width="470"
              elevation="0"
              @click="navigate({ read: false })"
            >
              <v-row>
                <v-col lg="2" class="unvergessen-col unvergessen-notification" style="padding-left: 25px">
                  <v-list-item-avatar
                      style="border-radius: 50%"
                      size="50"
                      color="secondary"
                  ><v-icon style="width: 40px; height: 40px" color="primary">$account</v-icon></v-list-item-avatar>
                </v-col>
                <v-col lg="10" class="unvergessen-col unvergessen-notification">
                  <v-list-item>
                    <v-list-item-content style="padding-bottom: 5px; padding-top: 5px">
                      <v-clamp autoresize :max-lines="3">
                        Viele haben es sich gewünscht und bald dürfen wir dir an dieser Stelle deine Benachrichtigungen anzeigen. Du wirst dann zum Beispiel darüber informiert, wenn auf einer deiner Gedenkseite etwas geschrieben wird oder es neue Funktionen bei Unvergessen.de gibt.
                      </v-clamp>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card>
          </v-list-item>
          <!-- <v-list-item v-for="(element, i) in notifications" :key="i"  style="padding: 0px">
            <v-card
              class="mx-auto"
              max-width="470"
              elevation="0"
              @click="navigate(element)"
            >
              <v-row>
                <v-col lg="2" class="unvergessen-col" :class="{'unvergessen-notification': element.read === 'false'}" style="padding-left: 25px">
                  <v-list-item-avatar
                      style="border-radius: 50%"
                      size="50"
                      color="secondary"
                  ><v-icon color="primary">$account</v-icon></v-list-item-avatar>
                </v-col>
                <v-col lg="10" class="unvergessen-col" :class="{'unvergessen-notification': element.read === 'false'}">
                  <v-list-item>
                    <v-list-item-content class="title font-weight-regular" style="padding-bottom: 5px; padding-top: 5px">
                      <v-clamp autoresize :max-lines="2" class="title font-weight-regular">{{ element.msg }}</v-clamp>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card>
          </v-list-item> -->
        </v-list>

        <v-progress-circular
          indeterminate
          color="primary"
          v-if="!loaded"
          style="margin: 20px 45%"
        ></v-progress-circular>

        <v-card-actions>
          <router-link :to="{ name: 'Notifications' }">
            <a>Alle Benachrichtigungen anzeigen</a>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
// import axios from 'axios'

export default {
  data () {
    return {
      notifications: [],
      notificationCounter: 0,
      loaded: false
    }
  },
  methods: {
    loadNotifications () {
      // if (this.notifications.length > 0) return

      // axios.get('/notification')
      //   .then(res => {
      //     this.notifications = res.data
      //     this.loaded = true
      //   })
    },
    navigate (notification) {
      // if message is not read yet
      if (!notification.read) {
        // set read message to true
        notification.read = true
        // decrement messageCounter
        this.notificationCounter--
        // tell backend, that message has been read
        // axios.patch('/notification', { n_id: notification.id })
        //   .then(res => {})
      }
      this.$router.push({ name: 'Notifications', query: { id: 'to_come' } }).catch(error => {})
      // this.$router.push({ name: 'Notifications', query: { id: notification.id } }).catch(error => {})
    }
  },
  created () {
    this.loaded = true
    // axios.get('/notification/unread')
    //   .then(res => {
    //     this.notificationCounter = res.data.unread
    //   })
  },
  components: {
    'v-clamp': VClamp
  }
}
</script>

<style scoped>
  .unvergessen-col {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .unvergessen-notification {
    background-color: #f0f0f0;
    font-style: bold;
  }
</style>
