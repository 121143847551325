import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/store.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Anmeldung',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/Abmelden',
    name: 'OptOut',
    component: () => import('../views/Optout.vue')
  },
  {
    path: '/Registrierung',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/Gedenkseite/erstellen',
    name: 'PageCreate',
    component: () => import('../views/PageCreate.vue')
  },
  {
    path: '/Gedenkseite/:page_id',
    name: 'Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Page.vue')
  },
  {
    path: '/Gedenkseite/:page_id/Spende',
    name: 'PageDonation',
    component: () => import('../views/PageDonationAmount.vue')
  },
  // {
  //   path: '/Gedenkseite/:page_id/Spende/Beitrag',
  //   name: 'PageDonationAmount',
  //   component: () => import('../views/PageDonationAmount.vue')
  // },
  {
    path: '/Gedenkseite/:page_id/Spende/Abschluss',
    name: 'PageDonationCheckout',
    component: () => import('../views/PageDonationCheckout.vue')
  },
  {
    path: '/Gedenkseite/Eva_Stark/Kilic',
    redirect: '/Gedenkseite/Eva_Stark%2FKilic'
  },
  {
    path: '/Gedenkseite/:page_id/Einstellungen',
    name: 'PageSettings',
    component: () => import('../views/PageSettings.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/Gedenkseite/:page_id/Zahlungseinstellungen',
    name: 'PagePayment',
    component: () => import('../views/PagePayments.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/email',
    name: 'Email',
    component: () => import('../views/Email.vue')
  },
  {
    path: '/:page_id/Galerie',
    name: 'MobileFootage',
    component: () => import('../components/page/FootagePage.vue')
  },
  {
    path: '/Gedenkseiten/Kürzlich',
    name: 'ExploreRecent',
    component: () => import('../views/ExploreRecent.vue')
  },
  {
    path: '/Gedenkseiten/Sternenkinder',
    name: 'ExploreKids',
    component: () => import('../views/ExploreKids.vue')
  },
  {
    path: '/Gedenkseiten/Bekannte-Persönlichkeiten',
    name: 'ExploreFamous',
    component: () => import('../views/ExploreFamous.vue')
  },
  {
    path: '/Gedenkseiten/Neue-Gedenkseiten',
    name: 'ExploreNewPages',
    component: () => import('../views/ExploreNewPages.vue')
  },
  {
    path: '/Gedenkseiten/Gedenktage',
    name: 'ExploreMemorialDays',
    component: () => import('../views/ExploreMemorialDays.vue')
  },
  {
    path: '/Blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/Blog/:article_id',
    name: 'BlogArticle',
    component: () => import('../views/BlogArticle.vue')
  },
  {
    path: '/Häufige-Fragen',
    name: 'FAQ',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/Über-Uns',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/Suche',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    props: true
  },
  {
    path: '/Jobs',
    name: 'Jobs',
    component: () => import('../views/Jobs.vue')
  },
  {
    path: '/Jobs/:job_title',
    name: 'Job',
    component: () => import('../views/Job.vue')
  },
  // {
  //   path: '/Preise',
  //   name: 'Pricing',
  //   component: () => import('../views/Pricing.vue')
  // },
  {
    path: '/AGB',
    name: 'AGB',
    component: () => import('../views/AGB.vue')
  },
  {
    path: '/Nutzungsbedingungen',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/Hilfe-finden',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/Widerrufsbelehrung',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal.vue')
  },
  {
    path: '/Impressum',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/Datenschutz',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/Neues-Passwort',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/Gedenkseite/:page_id/Verifizierung',
    name: 'Verify',
    component: () => import('../views/page_verify/Verify2.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next('/')
      } else {
        store.dispatch('loadUser')
          .then(res => {
            let found = false
            store.getters.userPages.forEach(element => {
              if (to.params.page_id === element.page_id) found = true
            })
            if (found) {
              next()
            } else {
              next('/')
            }
          })
          .catch(error => {
            next('/')
          })
      }
    }
  },
  {
    path: '/Gedenktage/:page_id',
    name: 'MemorialDays',
    component: () => import('../views/MemorialDays.vue')
  },
  {
    path: '/Gedenkseite',
    name: 'PageLanding',
    component: () => import('../views/PageLanding.vue')
  },
  {
    path: '/Gedenk-Seite',
    name: 'PageLandingFacebook',
    component: () => import('../views/PageLandingFacebook.vue')
  },
  {
    path: '/Rückmeldung',
    name: 'Feedback',
    component: () => import('../views/Feedback.vue')
  },
  {
    path: '/Gedenkseite/:page_id/Verifizierung/2',
    name: 'Verify2',
    component: () => import('../views/page_verify/Verify2.vue')//,
    // beforeEnter: (to, from, next) => {
    //   if (!store.getters.isLoggedIn) {
    //     next('/')
    //   } else {
    //     store.dispatch('loadUser')
    //       .then(res => {
    //         let found = false
    //         store.getters.userPages.forEach(element => {
    //           if (to.params.page_id === element.page_id) found = true
    //         })
    //         if (found) {
    //           next()
    //         } else {
    //           next('/')
    //         }
    //       })
    //       .catch(error => {
    //         next('/')
    //       })
    //   }
    // }
  },
  {
    path: '/Partner-werden',
    name: 'PartnerRegistration',
    component: () => import('../views/PartnerRegistration.vue')
  },
  {
    path: '/Gedenk-Post',
    name: 'MemorialPostLanding',
    redirect: '/'
    // component: () => import('../views/MemorialPost.vue')
  },
  {
    path: '/Gedenk-Post/Spenden',
    name: 'MemorialPostDonation',
    redirect: '/'
    // component: () => import('../views/MemorialPostDonation.vue')
  },
  {
    path: '/Gedenk-Post/Spenden/Anmelden',
    name: 'MemorialPostCheckout',
    redirect: '/'
    // component: () => import('../views/MemorialPostCheckout.vue')
  },
  {
    path: '/Gedenk-Post/Gemeinde-Auswahl',
    name: 'MemorialPost_2',
    redirect: '/'
    // component: () => import('../views/MemorialPost_2.vue')
  },
  {
    path: '/Gedenk-Post/Empfehlung',
    name: 'MemorialPostRec',
    redirect: '/'
    // component: () => import('../views/MemorialPostRec.vue')
  },
  {
    path: '/Gedenk-Post/1',
    name: 'MemorialPostLanding1',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding1.vue')
  },
  {
    path: '/Gedenk-Post/1/Gemeinde-Auswahl',
    name: 'MemorialPostLanding1_2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding1_2.vue')
  },
  {
    path: '/Gedenk-Post/1/Danke',
    name: 'MemorialPostFinish1',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostFinish1.vue')
  },
  {
    path: '/Gedenk-Post/2',
    name: 'MemorialPostLanding2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding2.vue')
  },
  {
    path: '/Gedenk-Post/2/Landkreis-Auswahl',
    name: 'MemorialPostLanding2_2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding2_2.vue')
  },
  {
    path: '/Gedenk-Post/2/Gemeinde-Auswahl',
    name: 'MemorialPostLanding2_3',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding2_3.vue')
  },
  {
    path: '/Gedenk-Post/3',
    name: 'MemorialPostLanding3',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding3.vue')
  },
  {
    path: '/Gedenk-Post/3/Gemeinde-Auswahl',
    name: 'MemorialPostLanding3_2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding3_2.vue')
  },
  {
    path: '/Gedenk-Post/4',
    name: 'MemorialPostLanding4',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding4.vue')
  },
  {
    path: '/Gedenk-Post/4/Gemeinde-Auswahl',
    name: 'MemorialPostLanding4_2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostLanding4_2.vue')
  },
  {
    path: '/Gedenk-Post/Anmelden',
    name: 'MemorialPostAbo',
    redirect: '/'
    // component: () => import('../views/MemorialPostAbo.vue')
  },
  {
    path: '/Gedenk-Post/1/Anmelden',
    name: 'MemorialPostAbo1',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo1.vue')
  },
  {
    path: '/Gedenk-Post/2/Anmelden',
    name: 'MemorialPostAbo2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo2.vue')
  },
  {
    path: '/Gedenk-Post/3/Anmelden',
    name: 'MemorialPostAbo3',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo3.vue')
  },
  {
    path: '/Gedenk-Post/4/Anmelden',
    name: 'MemorialPostAbo4',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo4.vue')
  },
  {
    path: '/Gedenk-Post/1/Anmelden',
    name: 'MemorialPostAbo1',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo1.vue')
  },
  {
    path: '/Gedenk-Post/2/Anmelden',
    name: 'MemorialPostAbo2',
    redirect: '/'
    // component: () => import('../views/memorial_post/MemorialPostAbo2.vue')
  },
  {
    path: '/Partner/:page_id/Verifizierung',
    name: 'PartnerVerify',
    component: () => import('../views/PartnerVerify.vue')
  },
  {
    path: '/Partner/:page_id/Abschluss',
    name: 'PartnerCheckout',
    component: () => import('../views/PartnerCheckout.vue')
  },
  {
    path: '/Newsletter-Anmeldung',
    name: 'NewsletterLanding',
    component: () => import('../views/NewsletterLanding.vue')
  },
  {
    path: '/Newsletter-Abmeldung',
    name: 'CancelNewsletter',
    component: () => import('../views/CancelNewsletter.vue')
  },
  {
    path: '/Spende',
    name: 'UnvergessenDonation',
    component: () => import('../views/UnvergessenDonation.vue')
  },
  {
    path: '/Konto',
    component: () => import('../views/Account.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next('/')
      } else {
        next()
      }
    },
    children: [
      {
        path: '/',
        name: 'Account',
        component: () => import('../views/subviews/AccountBasic.vue')
      },
      {
        path: '/Konto/Nachrichten',
        name: 'Messages',
        component: () => import('../views/subviews/AccountMessages.vue')
      },
      {
        path: '/Konto/Einstellungen',
        name: 'AccountSettings',
        component: () => import('../views/subviews/AccountSettings.vue')
      },
      {
        path: '/Konto/Profil',
        name: 'Profile',
        component: () => import('../views/subviews/AccountProfile.vue')
      },
      {
        path: '/Konto/Benachrichtigungen',
        name: 'Notifications',
        component: () => import('../views/subviews/AccountNotifications.vue')
      }
    ]
  },
  {
    path: '/Unvergessen-Bärchen',
    name: 'Bears',
    component: () => import('../views/shop/Bears.vue')
  },
  // Redirects & Shortened Links
  {
    path: '/Erinnerungsbärchen',
    redirect: '/Unvergessen-Bärchen'
  },
  {
    path: '/Acht-Neujahrsvorsätze',
    redirect: '/Blog/Acht-Neujahrsvorsätze-für-Trauernde-positiv-in-die-Zukunft'
  },
  {
    path: '/Fragen-zu-Lebzeiten-klären',
    redirect: '/Blog/Sieben-Fragen-die-du-mit-deiner-Familie-noch-zu-Lebzeiten-klären-solltest'
  },
  {
    path: '/David_Bowie',
    redirect: '/Gedenkseite/David_Bowie'
  },
  {
    path: '/Prinz_Philip',
    redirect: '/Gedenkseite/Prinz_Philip'
  },
  {
    path: '/Siegfried_Fischbacher',
    redirect: '/Gedenkseite/Siegfried_Fischbacher'
  },
  {
    path: '/Astrid_Lindgren',
    redirect: '/Gedenkseite/Astrid_Lindgren'
  },
  {
    path: '/Weltkrebstag',
    redirect: '/Gedenktage/Weltkrebstag'
  },
  {
    path: '/Kinderkrebstag',
    redirect: '/Gedenktage/Kinderkrebstag'
  },
  {
    path: '/Valentinstag',
    redirect: '/Gedenktage/Valentinstag'
  },
  {
    path: '/Corona-Gedenktag',
    redirect: '/Gedenktage/Corona-Gedenktag'
  },
  {
    path: '/Tiere',
    redirect: '/Gedenktage/Tiere'
  },
  {
    path: '/Ostern',
    redirect: '/Gedenktage/Ostern'
  },
  {
    path: '/Kerze-anzünden',
    redirect: '/Gedenktage/Kerze-anzünden'
  },
  {
    path: '/Krebs-Trauerbewältigung',
    redirect: '/Blog/Krebs-Trauerbewältigung-nach-einem-langen-Abschied'
  },
  {
    path: '/Blog/Weltkrebstag-Trauerbewältigung-nach-einem-langen-Abschied',
    redirect: '/Blog/Krebs-Trauerbewältigung-nach-einem-langen-Abschied'
  },
  {
    path: '/6-Ideen-Umgang-Trauer',
    redirect: '/Blog/Sechs-Ideen-für-einen-leichteren-Umgang-mit-deiner-Trauer'
  },
  {
    path: '/Von-Trauerphasen-lernen',
    redirect: '/Blog/Es-ist-nie-zu-spät-für-Trauerbewältigung-Lerne-von-den-Trauerphasen'
  },
  {
    path: '/Dein-Sternenkind',
    redirect: '/Blog/Dein-Sternenkind-Positive-Glaubenssätze-in-schweren-Zeiten'
  },
  {
    path: '/Was-Trauernde-nicht-hören-wollen',
    redirect: '/Blog/Dreizehn-Dinge-die-Trauernde-nicht-hören-wollen'
  },
  {
    path: '/Gründe-Gedenkseiten',
    redirect: '/Blog/Ein-Überblick-5-Gründe-für-und-gegen-Gedenkseiten'
  },
  {
    path: '/Trauerkarten-Alternative',
    redirect: '/Blog/Drei-erstaunlich-schöne-Alternativen-zu-klassischen-Trauerkarten'
  },
  // 404 & Default
  {
    path: '/Technische-Probleme',
    name: 'OfflinePage',
    component: () => import('../views/Offline.vue')
  },
  {
    path: '/Seite-Wurde-Nicht-Gefunden',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    redirect: '/Seite-Wurde-Nicht-Gefunden'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
