<template>
  <v-card style="margin: auto; width: 100%;" class="unvergessen-shadow flex d-flex flex-column grey4">
    <v-list class="grey4" style="padding: 4% 2%">
      <v-list-item v-if="!hideHeadline">
        <div class="title font-weight-bold text--text">
          Du benötigst Unterstützung?
        </div>
      </v-list-item>
      <!-- <v-list-item three-line>
        <v-list-item-content>
          <div>
            Telefon
          </div>
          <div class="title">0681 965 938 80</div>
          <div>
            Mo. - Fr. 9.00 - 18.00 Uhr
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <div>
            E-Mail
          </div>
          <div class="title">hallo@unvergessen.de</div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
            Weitere Kontaktmöglichkeiten
          </div>
        </v-list-item-content>
      </v-list-item> -->
      <div style="width: 100%; max-width: 370px; margin: auto; padding: 0 20px">
        <v-row>
          <v-col cols="6" align="center">
            <v-card color="white" class="help-btn" width="100%" @click="navigate('https://wa.me/message/5USW3C3ANO32F1')">
              <v-responsive :aspect-ratio="1">
                <div style="display: block; padding-top: 4px">
                  <img src="@/assets/whatsapp.svg" loading="lazy" width="40" style="display: block; margin: 10px auto">
                  <div>
                    <b>WhatsApp</b>
                  </div>
                  <p style="font-size: 12px">
                    Antwort innerhalb<br>kürzester Zeit
                  </p>
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <v-card color="white" class="help-btn" width="100%" @click="navigate('https://m.me/UnvergessenDuFehlst')">
              <v-responsive :aspect-ratio="1">
                <div style="display: block; padding-top: 4px">
                  <img src="@/assets/messenger.svg" loading="lazy" width="40" style="display: block; margin: 10px auto">
                  <div>
                    <b>Messenger</b>
                  </div>
                  <p style="font-size: 12px">
                    Antwort innerhalb<br>weniger Stunden
                  </p>
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <v-card color="white" class="help-btn" width="100%" @click="navigate('mailto:hallo@unvergessen.de?subject=Ich brauche Hilfe')">
              <v-responsive :aspect-ratio="1">
                <div style="display: block; padding-top: 4px">
                  <v-icon style="width: 45px; height: 45px; margin-bottom: 7px; margin-top: 14px">$mail</v-icon>
                  <div>
                    <b>E-Mail</b>
                  </div>
                  <p style="font-size: 12px">
                    Antwort innerhalb<br>von 24 Stunden
                  </p>
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <!-- Help Dialog -->
            <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" width="850px" v-model="dialog">
              <template v-slot:activator="{ on }">
                <v-card color="white" class="help-btn" width="100%" v-on="on">
                  <v-responsive :aspect-ratio="1">
                    <div style="display: block">
                      <v-icon style="width: 45px; height: 45px; margin-bottom: 7px; margin-top: 18px">$flame</v-icon>
                      <div>
                        <b>Kontakt</b>
                      </div>
                      <p style="font-size: 12px">
                        Antwort innerhalb<br>von 24 Stunden
                      </p>
                    </div>
                  </v-responsive>
                </v-card>
              </template>
              <unvergessen-contact-form @close="dialog = false"></unvergessen-contact-form>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import ContactForm from '@/components/global/ContactForm.vue'
export default {
  props: ['hideHeadline'],
  data () {
    return {
      dialog: false
    }
  },
  components: {
    unvergessenContactForm: ContactForm
  },
  methods: {
    navigate (target) {
      window.open(target, '_blank')
    }
  },
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        this.dialog = false
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  }
}
</script>

<style scoped>
  .help-btn {
    text-transform: none;
    font-size: 14px
  }
</style>
