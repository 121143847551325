<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      nudge-bottom="20"
      offset-y
      left
      :nudge-width="300"
    >
      <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon class="unvergessen-nav-bar-icon" style="background-color: rgba(64,64,64,0.3)" v-on="on" @click="loadMessages()">
            <v-badge :content="messageCounter" color="red" v-if="messageCounter > 0">
              <v-icon style="width: 32px; height: 32px">$mail-white</v-icon>
            </v-badge>
            <v-icon style="width: 32px; height: 32px" v-if="messageCounter <= 0">$mail-white</v-icon>
          </v-app-bar-nav-icon>
      </template>
      <v-card elevation="0">
        <v-list color="secondary" height="48px" style="padding-top: 0 !important;">
          <v-list-item>
            <v-list-item-content style="margin: auto;">
              <v-list-item-title>Nachrichten</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list style="padding-top: 0px" v-if="loaded">
          <v-list-item v-for="element in messages" :key="element.id"  style="padding: 0px">
            <unvergessen-message-skeleton :message="element" :maxWidth="350"></unvergessen-message-skeleton>
          </v-list-item>
        </v-list>

        <v-progress-circular
          indeterminate
          color="primary"
          v-if="!loaded"
          style="margin: 20px 45%"
        ></v-progress-circular>

        <v-card-actions>
          <router-link :to="{ name: 'Messages' }">
            <a>Alle Nachrichten anzeigen</a>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import MessageSkeleton from './menu_dropdowns/MessageSkeleton.vue'
import axios from 'axios'

export default {
  data () {
    return {
      numberDisplayedMessages: 4,
      loaded: false
    }
  },
  components: {
    'unvergessen-message-skeleton': MessageSkeleton
  },
  methods: {
    loadMessages () {
      if (this.messages.length > 0) {
        this.loaded = true
        return
      }
      axios.get('/message')
        .then(res => {
          this.$store.dispatch('setMessages', res.data)
          this.loaded = true
        })
        .catch(error => {})
    }
  },
  created () {
    this.$store.dispatch('loadUnreadMessages')
  },
  computed: {
    messageCounter () {
      return this.$store.getters.messageCounter
    },
    messages () {
      // Set "preview" to 4 messages only
      var messages = this.$store.getters.messages
      var data = []
      if (messages.length > this.numberDisplayedMessages) {
        for (var i = 0; i < this.numberDisplayedMessages; i++) {
          data[i] = messages[i]
        }
      } else {
        data = messages
      }
      return data
    }
  }
}
</script>

<style scoped>
  .unvergessen-avatar {
    border-radius: 100px !important;
  }
</style>
