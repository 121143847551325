<template>
  <div>
    <v-card class="unvergessen-shadow" style="border-radius: 10px" @click="pushToPage">
      <v-container>
        <v-row>
          <v-col cols="1" v-if="!$vuetify.breakpoint.xs"></v-col>
          <v-col cols="3" sm="2" class="px-3">
            <div style="margin: 0; position: relative; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);">
              <unvergessen-premium-candle :size="$vuetify.breakpoint.xs ? '60px' : '90px'" :candle="comment" :color="comment.color" :type="comment.type" :blockTooltip="true" :active="comment.active"></unvergessen-premium-candle>
            </div>
          </v-col>
          <v-col cols="1" v-if="!$vuetify.breakpoint.xs"></v-col>
          <v-col cols="9" sm="7" style="text-align: left" class="pl-0">
            <div style="height: 100%; position: relative">
              <div style="margin: 0; position: relative; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);">
                <div class="font-weight-bold" :style="{ 'font-size': $vuetify.breakpoint.xs ? '14px' : '20px' }">
                  {{ comment.type === "free" ? 'Wochenkerze für ' : comment.type === 'premium1' ? 'Jahreskerze für ' : comment.type === 'premium2' ? 'Monatskerze für ' : 'Ewige Kerze für ' }}{{ comment.name }}
                  <div class="font-weight-regular" :style="{ 'font-size': $vuetify.breakpoint.xs ? '10px' : '14px' }">
                    angezündet <span v-if="minutes === 0">gerade eben</span><span v-else>vor <span v-if="hours > 0"> {{ hours }} Stunde<span v-if="hours > 1">n</span> und </span>{{ minutes % 60 }} Minuten</span>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PremiumCandle from '@/components/page/PremiumCandle.vue'

export default {
  props: ['comment'],
  components: {
    unvergessenPremiumCandle: PremiumCandle
  },
  methods: {
    pushToPage () {
      var target = this.comment.pageType === 'memorial_day' ? '/Gedenktage/' + this.comment.pid : '/Gedenkseite/' + this.comment.pid
      this.$ga.event('Candles', 'Click_on_Candle_Home', this.target, 0)
      this.$router.push(target)
    },
    calcMinutes () {
      var diff = new Date() - new Date(this.comment.ct)
      return parseInt(diff / (1000 * 60))
    }
  },
  computed: {
    minutes () {
      return this.calcMinutes()
    },
    hours () {
      return Math.floor(this.minutes / 60)
    }
  }
}
</script>
