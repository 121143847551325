<template>
  <div style="padding: 0% 10%; overflow: visible">
    <v-hover
      v-slot:default="{ hover }"
    >
      <v-card
        light
        :elevation="hover ? 16 : 6"
        :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '15% 10% 5% 10%' : '10%', height: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '355px' }"
      >
        <v-img
          :src="require('@/assets/customIcons/QuotationMarks.png')"
          alt=""
          style="position: absolute;"
          :style="{
            top: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '-115px' : '-130px',
            width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '80px' : '120px',
            left: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '-20px' : '-40px'
          }"
        ></v-img>
        <p class="font-weight-regular" style="white-space: pre-wrap">{{ quote.text }}
        </p>
        <div style="position: absolute; left: 10%; bottom: 10%" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          <div class="font-weight-medium">
            {{ quote.institution}}
          </div>
        </div>
        <div v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin-bottom: 7%">
          <div class="font-weight-medium">
            {{ quote.institution}}
          </div>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 10%' : '0' }">
          <v-img
            :src="quote.logo" alt="logo"
            :style="{ width: quote.logoWidth, position: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'relative' : 'absolute', right: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '10%', bottom: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '10%' }"
            style="right: 10%; bottom: 10%; margin: auto"
          ></v-img>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: ['quote'],
  methods: {
    navigate () {
      window.open(this.quote.link, '_blank')
    }
  }
}
</script>
