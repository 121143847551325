<template>
  <div>
    <!-- CTA -->

    <!-- Product -->
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px; margin-bottom: 20px" v-if="type === 'product'" @click="pushToExtern(target)">
      <!-- Header -->
      <div style="text-align: center; background-size: cover; background-image: url('https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Sonnenuntergang_Strand_Meer.jpg')" class="font-weight-medium title">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8); padding: 20px 0; border-radius: 10px 10px 0 0">
          {{ headline }}
        </div>
      </div>

      <v-container style="padding: 10px 30px 0">
        <v-row>
          <v-col :cols="images.length > 3 ? 6 : ''" v-for="(image, i) in images" :key="i">
            <v-img aspect-ratio="1" style="border-radius: 15px" :src="image"></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- Info -->
      <div style="text-align: center; padding: 5px 0">
        <div style="font-weight: 600">
          {{ text }}
        </div>
        <p class="caption">
          {{ subtext }}
        </p>
      </div>

      <!-- Button -->
      <div :style="{ padding: $vuetify.breakpoint.md ? '0 5px 10px' : '0px 50px 10px' }">
        <unvergessen-button class="secondary text--text" :mobile="true" style="min-height: 60px !important; text-transform: none" @click="pushToExtern(target)">
          {{ btnText || 'Mehr erfahren' }}
        </unvergessen-button>
      </div>

      <div style="width: 100%; text-align: right; font-size: 9px;" class="px-2 pt-2 pb-1">
        <span style="cursor: pointer" @click.stop="openDeleteProductPreview">
          Vorschau entfernen
        </span>
      </div>
    </v-card>

    <!-- Bears -->
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px; margin-bottom: 20px" v-else-if="type === 'bears'" @click="pushTo(target)">
      <!-- Header -->
      <div style="text-align: center; background-size: cover" :style="{ 'background-image': banner ? 'url(\'' + banner + '\')' : 'url(\'https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Wald_Himmel.jpg\')' }" class="font-weight-medium title">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8); padding: 20px 0; border-radius: 10px 10px 0 0">
          {{ headline }}
        </div>
      </div>

      <v-container style="padding: 20px 40px 0">
        <v-row>
          <v-col v-for="(image, i) in images" :key="i">
            <v-img aspect-ratio="1" style="border-radius: 15px" :src="image"></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- Info -->
      <div style="text-align: center; padding: 20px 0 0">
        <div style="font-weight: 600">
          {{ text }}
        </div>
        <p class="caption">
          {{ subtext }}
        </p>
      </div>

      <!-- Button -->
      <div :style="{ padding: $vuetify.breakpoint.md ? '0 5px 20px' : '0px 50px 20px' }">
        <unvergessen-button class="secondary text--text" :mobile="true" style="min-height: 60px !important; text-transform: none" @click="pushTo(target)">
          Mehr erfahren
        </unvergessen-button>
      </div>
    </v-card>

    <!-- Default -->
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px; margin-bottom: 20px" v-else>
      <!-- Header -->
      <div style="text-align: center;" :style="{ 'background-image': banner ? 'url(\'' + banner + '\')' : 'url(\'https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Wald_Himmel.jpg\')' }" :class="{ title: !$vuetify.breakpoint.xs }" class="font-weight-medium">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8); padding: 20px 0; border-radius: 10px 10px 0 0">
          {{ headline }}
        </div>
      </div>

      <v-container style="padding: 20px 40px 0" v-if="images">
        <v-row>
          <v-col cols="12" v-for="(image, i) in images" :key="i">
            <v-img aspect-ratio="1" style="border-radius: 15px" :src="image"></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- Info -->
      <div style="padding: 10px 20px 0px" :style="{ 'font-size': $vuetify.breakpoint.xs ? '12px' : '' }">
        <p v-html="text"></p>
      </div>
      <!-- Button -->
      <div :style="{ padding: $vuetify.breakpoint.md ? '0 5px 20px' : '0px 50px 20px' }">
        <unvergessen-button :green="true" :mobile="true" style="min-height: 60px !important; text-transform: none" @click="createNewPage">
          Eine Gedenkseite<br v-if="($vuetify.breakpoint.md)"> erstellen
        </unvergessen-button>
      </div>
    </v-card>

    <!-- Dialogs -->
    <unvergessen-new-page-dialog :dialog="newPage" @close="newPage = false"></unvergessen-new-page-dialog>

    <!-- Delete Product Preview Dialog -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="deleteProductPreviewDialog" @click:outside="closeDeleteProductDialog" max-width="615px">
      <v-card class="pb-8">
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="closeDeleteProductDialog">mdi-close</v-icon>
        </div>

        <div style="text-align: center; padding: 20px 0 10px" class="title">
          Diese Vorschau entfernen?
        </div>

        <v-card-text class="text--text" style="padding: 10px 30px">
          <v-alert type="error" v-model="deleteError">
            Die Vorschau konnte nicht entfernt werden. Bitte versuche es erneut
          </v-alert>

          <v-form ref="deleteProductPreviewForm">
            <v-textarea
              v-model="reason"
              :rules="nonEmptyRule"
              outlined
              placeholder="Bitte erkläre uns hier, warum diese Vorschau entfernt werden soll."
            >
            </v-textarea>
          </v-form>

          <p class="caption">
            Hinweis: Sobald du auf den folgenden Button drückst, wird die Vorschau direkt unsichtbar. Wir prüfen deine Meldung innerhalb von 24 Stunden und löschen den Eintrag bei Vorliegen einer Begründung dauerhaft.
          </p>
        </v-card-text>

        <v-row style="width: 100%; padding: 0 30px; margin: 0">
          <v-col cols="6" class="pl-0 py-0">
            <unvergessen-button class="error" @click="deleteProductPreview" :mobile="true">
              Vorschau entfernen
            </unvergessen-button>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <unvergessen-button :lightBackground="true" @click="closeDeleteProductDialog" :mobile="true">
              Abbrechen
            </unvergessen-button>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <unvergessen-success-dialog :dialog="successDeletePreview" @close="successDeletePreview = false; deleteProductPreviewLocal()" buttonText="OK" heading="Die Vorschau wurde erfolgreich entfernt"></unvergessen-success-dialog>
  </div>
</template>

<script>
import NewPageDialog from '@/components/global/NewPageDialog.vue'
import axios from 'axios'

export default {
  props: ['headline', 'text', 'subtext', 'target', 'images', 'type', 'banner', 'btnText', 'product'],
  data () {
    return {
      newPage: false,
      deleteProductPreviewDialog: false,
      deleteError: false,
      reason: '',
      successDeletePreview: false,
      nonEmptyRule: [v => !!v || 'Bitte gib einen Grund an']
    }
  },
  methods: {
    openDeleteProductPreview () {
      this.deleteProductPreviewDialog = true
    },
    closeDeleteProductDialog () {
      this.deleteProductPreviewDialog = false
      this.deleteError = false
      this.$refs.deleteProductPreviewForm.reset()
    },
    deleteProductPreviewLocal () {
      this.$store.commit('deleteProductPreview')
    },
    deleteProductPreview () {
      if (this.$refs.deleteProductPreviewForm.validate()) {
        axios.delete('/feedback/glassPicture', { data: { page_id: this.$route.params.page_id, reason: this.reason } })
          .then(() => {
            this.successDeletePreview = true
            this.deleteError = false
            this.closeDeleteProductDialog()
          })
          .catch(() => {
            this.deleteError = true
          })
      }
    },
    pushToExtern (target) {
      var device = this.$vuetify.breakpoint.smAndDown ? 'Mobile' : 'PC'
      this.$ga.event(this.product.type, 'Click_on_Product_' + this.product.product + '_' + device + this.isLoggedIn, this.$route.params.page_id, 0)
      window.open(target, '_blank')
    },
    pushTo (target) {
      if (this.$store.getters.skeleton && this.$route.name === 'Page') {
        this.$ga.event('Bears', 'Click_on_Bears', this.$route.name + '_' + this.$store.getters.skeleton.type, 0)
      } else {
        this.$ga.event('Bears', 'Click_on_Bears', this.$route.name, 0)
      }
      this.$router.push({ name: target })
    },
    createNewPage () {
      this.newPage = true
      if (this.$route.name === 'BlogArticle') {
        this.$ga.event('Onboarding', 'ClickOnCreatePageBlog', this.$route.params.article_id, 0)
      }
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn ? '_isLoggedIn' : ''
    }
  },
  components: {
    unvergessenNewPageDialog: NewPageDialog
  }
}
</script>
