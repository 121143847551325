<template>
  <v-card style="margin: auto; width: 100%" :style="{ 'min-height': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '450px': '350px'}" class="unvergessen-shadow flex flex-column grey4">
    <v-list class="grey4" style="padding: 4% 2%">
      <v-list-item>
        <div class="title font-weight-bold text--text">
          Du hast Wünsche?
        </div>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="flex">
          <v-textarea
            solo
            outlined
            :rows="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '9' : '7'"
            background-color="white"
            v-model="input"
            elevation="0"
            :placeholder="placeholder"
          ></v-textarea>
        </v-list-item-content>
      </v-list-item>
      <div style="position: absolute; bottom: 20px; left: 0px; width: 100%">
        <div style="padding: 10px 5%">
          <unvergessen-button @click="send()" :style="{ height: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'auto' : '80px !important' }" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">Wunsch senden</unvergessen-button>
        </div>
      </div>
    </v-list>
    <unvergessen-success-dialog :dialog="success" @close="success = false" heading="Vielen Dank!" subheading="Deine Nachricht wurde erfolgreich abgeschickt" buttonText="OK">
      Wir sammeln nun alle Ideen und werden die häufigsten Wünsche zeitnah umsetzen.
      Mit unserem <span class="primary--text" style="cursor: pointer" @click="newsletterDialog = true;" @dialog="newsletterDialog = false">Newsletter</span> kannst du auf dem Laufenden bleiben.
    </unvergessen-success-dialog>
    <unvergessen-newsletter-dialog :dialog="newsletterDialog" @dialog="newsletterDialog = false"></unvergessen-newsletter-dialog>
  </v-card>
</template>

<script>
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import NewsletterDialog from '@/components/global/NewsletterDialog.vue'
import axios from 'axios'

export default {
  data () {
    return {
      input: '',
      success: false,
      newsletterDialog: false,
      placeholder: 'Wenn du Ideen hast, wie wir Unvergessen.de verbessern können, so freuen wir uns sehr über deine anonyme Nachricht.'
    }
  },
  methods: {
    send () {
      if (this.input !== '') {
        var params = {
          text: this.input,
          author: 'anonym'
        }
        this.success = true
        axios.post('/feedback', params)
          .then(res => {
            this.input = ''
          })
          .catch(error => {})
      }
    }
  },
  components: {
    'unvergessen-success-dialog': SuccessDialog,
    'unvergessen-newsletter-dialog': NewsletterDialog
  }
}
</script>
