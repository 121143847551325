export default function (go, cb) {
  return {
    created () {
      const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
        if (!this[go]) {
          // this[prop] = initialValues[prop]
          this[cb]()
          next()
          return
        }
        next()
      })

      this.$once('hook:destroyed', () => {
        unregisterRouterGuard()
      })
    }
  }
}
