<template>
  <div style="margin-bottom: 30px">
    <div style="color: #552b00" class="font-weight-medium" :class="{ headline: !big && $vuetify.breakpoint.xs && !huge, 'display-1': (!$vuetify.breakpoint.xs || big) && !huge, 'display-2': huge }">
      <slot></slot>
      <span style="position: relative; z-index: 1">
        <slot name="underline"></slot>
      </span>
      <slot name="behind"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['big', 'huge']
}
</script>

<style scoped>
span::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0px;
  width: 100%;
  height: 24px;
  background-color: rgba(247, 238, 230, 1);
  z-index: -1;
}
</style>
