import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import page from './modules/page'
import payment from './modules/payment'
import partnercheckout from './modules/partnercheckout'
import messages from './modules/messages'
import user from './modules/user'
import post from './modules/post'
import offline from './modules/offline'
import memorialdays from './modules/memorialdays'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    page,
    messages,
    user,
    payment,
    partnercheckout,
    post,
    memorialdays,
    offline
  }
})
