var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-bottom":"20","offset-y":"","left":"","nudge-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',{staticClass:"unvergessen-nav-bar-icon",staticStyle:{"background-color":"rgba(64,64,64,0.3)"}},[_c('v-icon',_vm._g({attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.loadInfo()}}},on),[_vm._v("$account-white")])],1)]}}])},[_c('v-card',[_c('div',{staticStyle:{"max-height":"80vh","overflow":"auto"}},[_vm._l((_vm.pages),function(page){return _c('unvergessen-page-settings',{key:page.page_id,attrs:{"page":page}})}),_c('v-divider'),_c('v-list',{staticClass:"unvergessen-list",attrs:{"flat":"","dense":""}},[_c('v-list-item',{staticClass:"unvergessen-list-item"},[_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v("Mein Konto")])],1)],1),_c('v-divider'),_c('v-list-item-group',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'unvergessen-hover' : '',attrs:{"to":{ name: 'Account' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$account")])],1),_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v(" Zu meinem Konto ")])],1)],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'unvergessen-hover' : '',on:{"click":function($event){return _vm.addMemorialPage()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-circle")])],1),_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v(" Neue Gedenkseite erstellen ")])],1)],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'unvergessen-hover' : '',attrs:{"to":{ name: 'AccountSettings' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$cog")])],1),_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v(" Kontoeinstellungen ")])],1)],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'unvergessen-hover' : '',attrs:{"to":{ name: 'Profile' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$lock")])],1),_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v(" Profil & Zugangsdaten ")])],1)],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'unvergessen-hover' : '',on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',{staticClass:"unvergessen-list-item-content"},[_c('v-list-item-title',[_vm._v(" Abmelden ")])],1)],1)]}}])})],1)],1)],2)])],1),_c('unvergessen-new-page-dialog',{attrs:{"dialog":_vm.dialog},on:{"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }