<template>
  <div>
    <!-- Heading -->
    <unvergessen-home-heading>
      Neue <template v-slot:underline>Kerzen</template>
    </unvergessen-home-heading>

    <!-- Candles -->
    <unvergessen-candle-card v-for="(candle, i) in candles" :key="i" :comment="candle" style="margin-bottom: 15px"></unvergessen-candle-card>
    <div style="text-align: center; padding-top: 10px; margin-bottom: 70px">
      <span style="cursor: pointer" v-if="index < maxIndex" @click="loadMore">
        <v-icon color="text" style="margin-right: 10px" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">mdi-plus-circle</v-icon>
        <span style="text-decoration: underline">
          Mehr Kerzen anzeigen
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import HomeHeading from '@/components/home/landing/HomeHeading.vue'
import CandleCard from '@/components/home/landing/CandleCard.vue'
import axios from 'axios'

export default {
  data () {
    return {
      candles: [],
      split: [],
      index: 0,
      maxIndex: 4
    }
  },
  created () {
    axios.get('/search/candleFeed')
      .then(res => {
        var complete = res.data
        this.split = this.chunkArray(complete, 3)
        this.candles = this.split[this.index]
      })
      .catch(() => {})
  },
  components: {
    unvergessenHomeHeading: HomeHeading,
    unvergessenCandleCard: CandleCard
  },
  methods: {
    chunkArray (myArray, chunkSize) {
      var results = []
      while (myArray.length) {
        results.push(myArray.splice(0, chunkSize))
      }
      return results
    },
    loadMore () {
      this.index++
      this.candles = this.candles.concat(this.split[this.index])
    }
  }
}
</script>
