<template>
  <div>
    <!-- FAQ on Ads -->
    <div v-if="(isPage && this.$vuetify.breakpoint.xs) || (isPage && skeleton.type === 'ad')">
      <unvergessen-wave background="var(--v-grey4-base)" fill="var(--v-secondary-base)"></unvergessen-wave>
      <div class="secondary" style="padding: 40px 12px; margin-top: -20px">

        <!-- Product CTA -->
        <div v-if="comments.content && comments.content.length <= 3">
          <unvergessen-cta
            class="hidden-sm-and-up"
            style="margin: 60px 0"
            banner="https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Sonnenuntergang_Meer.jpg"
            :headline="product.headline"
            :images="[product.image]"
            :text="product.text"
            :subtext="product.subtext"
            :btnText="product.btnText"
            :target="product.link"
            type="product"
            :product="product"
            v-for="(product, i) in products"
            :key="i"
          ></unvergessen-cta>
        </div>

        <!-- Bear CTA -->
        <unvergessen-cta
          class="hidden-sm-and-up"
          style="margin: 60px 0"
          banner="https://eternio-static-loads.s3.eu-central-1.amazonaws.com/banner/Titelbild_Sonnenuntergang_Meer.jpg"
          headline="Das Unvergessen Bärchen"
          :images="['https://resources.eternio.com/static/recent/Unvergessenbaerchen_1.jpg']"
          text="Handarbeit aus der Kleidung Verstorbener"
          subtext="Individuell ・ Mit Liebe handgemacht ・ Hochwertig"
          target="Bears"
          type="bears"
        ></unvergessen-cta>

        <div style="max-width: 1000px; margin: auto" v-if="skeleton.type === 'ad'">
          <div class="font-weight-medium" style="text-align: center; margin-bottom: 14px" :class="{ title: ($vuetify.breakpoint.xs), 'display-1': !$vuetify.breakpoint.xs }">
            Transparenz
          </div>
          <v-expansion-panels style="padding: 1% 0">
            <v-expansion-panel
              v-for="(item,i) in block.items"
              :key="i"
              style="margin-bottom: 0.5%"
              class="unvergessen-shadow"
            >
              <v-expansion-panel-header class="faq-header" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), 'mobile-faq': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="$ga.event('FAQ', 'ClickOnFaqOnAd', item.heading, 0)" expand-icon="mdi-plus-circle" disable-icon-rotate>{{ item.heading }}</v-expansion-panel-header>
              <v-expansion-panel-content class="font-weight-regular" style="padding: 1% 1%; white-space: pre-wrap" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), mobile: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">{{ item.text }}
                <span class="primary--text" style="cursor: pointer" @click="$router.push(item.link.page)" v-if="item.link">{{ item.link.text }}</span>
                <span v-if="item.after">
                  {{ item.after }}
                </span>
                <v-list v-if="item.list">
                  <v-list-item v-for="(listitem, i) in item.list" :key="i" style="white-space: pre-wrap">
                    <b>{{ listitem.heading }}</b> {{ listitem.text }}
                  </v-list-item>
                </v-list>
                <div v-if="item.button">
                  <unvergessen-button :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" @click="$router.push(item.button.page)" v-if="!item.button.type">{{ item.button.text }}</unvergessen-button>
                  <unvergessen-button :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" @click="feedbackDialog = true" v-if="item.button.type === 'feedback'">{{ item.button.text }}</unvergessen-button>
                  <unvergessen-button :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" @click="loginDialog = true" v-if="item.button.type === 'password'">{{ item.button.text }}</unvergessen-button>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div style="text-align: center;">
            <p style="margin-top: 30px; text-decoration: underline; cursor: pointer; font-size: 14px" @click="openDelete">
              Ich bin Angehöriger und möchte diese Seite löschen
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Wave -->
    <unvergessen-wave
      :background="$route.name && ($route.name === 'Bears' || $route.name === 'Page' || $route.name === 'MemorialDays' || $route.name === 'Jobs') ? (isPage && $vuetify.breakpoint.xs) || (isPage && skeleton.type === 'ad') ? 'var(--v-secondary-base)' : 'var(--v-grey4-base)' : 'transparent'" fill="#e8e8e8"
    ></unvergessen-wave>

    <div class="footer grey3" :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '10% 0' : '2% 10%'}" style="margin-top: -10px">
      <v-col lg="12">
        <h2 style="text-align: center;" :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" color="text">Unvergessen - die Gesellschaft für Erinnerungskultur</h2>
      </v-col>
      <v-container>
        <v-row class="my-2">
          <v-col cols="12" lg="4" class="pa-3 d-flex flex-column" style="width: 100%">
            <unvergessen-help></unvergessen-help>
          </v-col>
          <v-col cols="12" class="pa-3 flex-column hidden-lg-and-up" style="width: 100%">
            <unvergessen-facebook-follow></unvergessen-facebook-follow>
          </v-col>
          <v-col cols="12" lg="4" class="pa-3 d-flex flex-column" style="width: 100%">
            <unvergessen-improvements></unvergessen-improvements>
          </v-col>
          <v-col cols="12" lg="4" class="pa-3 d-flex flex-column" style="width: 100%">
            <unvergessen-newsletter></unvergessen-newsletter>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="grey3" :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0' :  '0 20%' }" v-if="!loggedIn">
      <v-container>
        <v-row>
          <v-col cols="1" v-if="!($vuetify.breakpoint.xs)"></v-col>
          <v-col cols="4" sm="2">
            <img src="@/assets/BMWi.svg" loading="lazy" alt="Exist Logos" class="center" style="width: 60%; height: 100%">
          </v-col>
          <v-col cols="4" sm="2">
            <img src="@/assets/EXIST.svg" loading="lazy" alt="Exist Logos" class="center" style="width: 90%; height: 100%" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '60%' : '90%' }">
          </v-col>
          <v-col cols="4" sm="2">
            <img src="@/assets/ESF.svg" loading="lazy" alt="Exist Logos" class="center" style="width: 100%; height: 100%" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '60%' : '100%' }">
          </v-col>
          <v-col cols="2" v-if="($vuetify.breakpoint.xs)"></v-col>
          <v-col cols="4" sm="2">
            <img src="@/assets/EU.svg" loading="lazy" alt="Exist Logos" class="center" style="width: 60%; height: 100%">
          </v-col>
          <v-col cols="4" sm="2">
            <img src="@/assets/Claim.svg" loading="lazy" alt="Exist Logos" class="center" style="width: 100%; height: 100%" :style="{ width: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '60%' : '100%' }">
          </v-col>
          <v-col cols="1" v-if="!($vuetify.breakpoint.xs)"></v-col>
        </v-row>
      </v-container>
      <div style="padding: 20px 5% 30px 5%">
        <p class="text-center" style="margin: auto" :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
          Unvergessen.de wurde im Rahmen des EXIST-Programms durch das Bundesministerium für Wirtschaft und Energie und den Europäischen Sozialfonds gefördert.
        </p>
      </div>
    </div>
    <unvergessen-subfooter class="subfooter"></unvergessen-subfooter>

    <!-- Delete Ad -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="deletedAd" width="700px">
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute; position: absolute">
          <v-icon color="primary" size="30" style="float: right; cursor: pointer" @click="deletedAd=false">mdi-close</v-icon>
        </div>
        <div style="padding: 20px">
          <div style="text-align: center" class="headline">
            Diese Seite löschen
          </div>
          <div style="margin-top: 20px">
            <v-textarea outlined v-model="deleteAdText" placeholder="Bitte erkläre uns hier, wieso diese Seite gelöscht werden soll."></v-textarea>
            <div style="margin-bottom: 20px">
              <b>Wichtiger Hinweis:</b> Wenn du möchtest, dass wir dich darüber informieren, sobald die Seite gelöscht wurde, hinterlasse bitte deine E-Mail-Adresse.
            </div>
            <unvergessen-button :mobile="true" :disabled="deleteAdText === ''" @click="feedbackAd">
              Nachricht abschicken
            </unvergessen-button>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Delete Ad Success -->
    <unvergessen-success-dialog :specialCase="true" :dialog="successAdDelete" @close="successAdDelete = false; deletedAd = false" buttonText="OK" heading="Danke für deine Nachricht">
      Wir bearbeiten deine Anfrage innerhalb von 24 Stunden
    </unvergessen-success-dialog>
  </div>
</template>

<script>
import HelpFooter from '@/components/footer/HelpFooter.vue'
import ImprovementsFooter from '@/components/footer/ImprovementsFooter.vue'
import NewsletterFooter from '@/components/footer/NewsletterFooter.vue'
import FacebookFollow from '@/components/footer/FacebookFollow.vue'
import Subfooter from '@/components/footer/Subfooter.vue'
import CTABlog from '@/components/global/CTABlog.vue'
import axios from 'axios'

export default {
  data () {
    return {
      block: {},
      deletedAd: false,
      deleteAdText: '',
      successAdDelete: false
    }
  },
  methods: {
    openDelete () {
      this.deletedAd = true
    },
    feedbackAd () {
      var params = {
        text: 'Seite löschen: ' + this.$route.params.page_id + '\n   Nachricht: ' + this.deleteAdText,
        author: 'anonym'
      }
      axios.post('/feedback', params)
        .then(res => {
          this.deleteAdText = ''
          this.successAdDelete = true
        })
        .catch(error => {})
    }
  },
  created () {
    axios.get('/memorialMail/faq')
      .then(res => {
        this.block = res.data
      })
      .catch(() => {})
  },
  components: {
    'unvergessen-subfooter': Subfooter,
    'unvergessen-help': HelpFooter,
    'unvergessen-improvements': ImprovementsFooter,
    'unvergessen-newsletter': NewsletterFooter,
    unvergessenFacebookFollow: FacebookFollow,
    unvergessenCta: CTABlog
  },
  computed: {
    loggedIn () {
      return this.$store.getters.isLoggedIn
    },
    skeleton () {
      return this.$store.getters.skeleton || {}
    },
    products () {
      return this.$store.getters.products
    },
    comments () {
      return this.$store.getters.comments
    },
    isPage () {
      return this.$route.name === 'Page'
    }
  }
}
</script>

<style scoped>
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  * >>> .v-expansion-panel-header--active {
    background-color: var(--v-secondary-base);
    font-weight: 500
  }

  .faq-header >>> .v-icon{
    font-size: 60px;
  }

  .mobile {
    font-size: 13px
  }

  .mobile-faq {
    font-size: 13px
  }

  .mobile-faq >>> .v-icon {
    font-size: 30px !important
  }

  * >>> .v-expansion-panel-header--active .v-icon {
    transform: rotate(-45deg);
  }
</style>
