<template>
  <div>
    <!-- Heading -->
    <unvergessen-home-heading>
      Bewegende <template v-slot:underline>Gedenkseiten</template>
    </unvergessen-home-heading>

     <!-- Loader -->
    <div v-if="pages.length <= 0">
      <unvergessen-page-preview-loader style="margin-bottom: 10px" v-for="i in 3" :key="i"></unvergessen-page-preview-loader>
    </div>
    <div v-for="(page, i) in pages" :key="i">
      <unvergessen-page-preview-big :page="page" style="margin-bottom: 10px" v-if="page.last_name !== 'HITLER'"></unvergessen-page-preview-big>
    </div>
    <div style="text-align: center; padding-top: 10px; margin-bottom: 70px">
      <span style="cursor: pointer" v-if="index < maxIndex && pages.length > 0" @click="loadMore">
        <v-icon color="text" style="margin-right: 10px" v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">mdi-plus-circle</v-icon>
        <span style="text-decoration: underline">
          Mehr Gedenkseiten anzeigen
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import HomeHeading from '@/components/home/landing/HomeHeading.vue'
import PagePreviewBig from '@/components/search/SearchSkeleton2.vue'
import PagePreviewLoader from '@/components/home/landing/PagePreviewLoader.vue'
import axios from 'axios'

export default {
  data () {
    return {
      pages: [],
      split: [],
      index: 0,
      maxIndex: 4
    }
  },
  created () {
    axios.get('/search/popular')
      .then(res => {
        var complete = res.data
        this.split = this.chunkArray(complete, 3)
        this.pages = this.split[this.index]
      })
      .catch(() => {})
  },
  components: {
    unvergessenHomeHeading: HomeHeading,
    unvergessenPagePreviewBig: PagePreviewBig,
    unvergessenPagePreviewLoader: PagePreviewLoader
  },
  methods: {
    chunkArray (myArray, chunkSize) {
      var results = []
      while (myArray.length) {
        results.push(myArray.splice(0, chunkSize))
      }
      return results
    },
    loadMore () {
      this.index++
      this.pages = this.pages.concat(this.split[this.index])
    }
  }
}
</script>
