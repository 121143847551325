const state = {
  memorialDay: {}
}

const getters = {
  memorialDay: state => {
    return state.memorialDay
  },
  memorialDayCandles: state => {
    return state.memorialDay.candles
  }
}

const mutations = {
  setMemorialDay: (state, payload) => {
    state.memorialDay = payload
  },
  addMemorialDayPremiumCandle: (state, payload) => {
    state.memorialDay.candles.unshift(payload)
  },
  setMemorialDayCandles: (state, payload) => {
    state.memorialDay.candles = payload
  },
  incMemorialDaySympathy: state => {
    state.memorialDay.sympathy++
  },
  addMemorialDayComment: (state, payload) => {
    state.memorialDay.comments.content.unshift(payload)
  },
  loadMemorialDayComments: (state, payload) => {
    state.memorialDay.comments.content = state.memorialDay.comments.content.concat(payload)
  }
}

const actions = {
  setMemorialDay: (context, payload) => {
    context.commit('setMemorialDay', payload)
    context.commit('clearPage')
  },
  loadMemorialDayComments: (context, payload) => {
    context.commit('loadMemorialDayComments', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
