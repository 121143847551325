<template>
  <div>
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="dialog" persistent max-width="700px" style="min-height: 700px !important" @click:outside="persistent ? $emit('void') : $emit('closeParent')">
      <template v-slot:activator="{ on }" v-if="!propsdialog">
        <a class="primary---text" style="cursor: pointer;" v-on="on" v-if="here">hier</a>
        <p class="font-weight-regular primary--text" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="cursor: pointer" v-on="on" v-else>
          Registrieren
        </p>
      </template>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="persistent ? $emit('void') : $emit('closeParent'); dialog = persistent ? true : false">mdi-close</v-icon>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '1% 5% 20% 5%' : '0 10% 1% 10%' }">
          <v-card-title style="padding: 5% 0 1% 0">
            <p :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), headline: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" :style="{ 'text-align': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'left' : 'center' }" style="width: 100%; padding: 0.1in 0;" v-if="persistent && !$route.query.ad">Die Gedenkseite für<br><b>{{ firstname }}</b> wurde erstellt</p>
            <p :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" :style="{ 'text-align': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'left' : 'center', padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '10px 0 0 0' : '0.1in 0' }" style="width: 100%; padding: 0.1in 0;" v-if="persistent && $route.query.ad">Bitte verifiziere dich mit einer<br>Registrierung als Angehöriger</p>
            <p :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="text-align: center; width: 100%; padding: 0.1in 0" v-if="!persistent">Registrieren</p>
          </v-card-title>

          <v-card-text style="padding: 0">
            <p v-if="persistent" class="font-weight-regular" :style="{ 'text-align': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'left' : 'center' }" style="-bottom: 4%" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
              {{ $route.query.ad ? 'Bei Unvergessen.de dürfen Gedenkseiten nur von Angehörigen oder mit deren Einwilligung erstellt werden. Bitte registriere dich, um die Gedenkseite zu erweitern.' : 'Bitte registriere dich, um diese Seite zu speichern oder zu bearbeiten. Damit möchten wir sicherstellen, dass niemand eine falsche Gedenkseite erstellt.' }}
            </p>
            <v-alert v-model="registerError" type="error">
              Leider konnte kein Konto erstellt werden. Bitte überprüfe deine E-Mail-Adresse und versuche es erneut
            </v-alert>

            <!-- Login With Facebook -->
          <!-- <v-facebook-login
            :app-id="appId"
            logout="Abmelden"
            working="Bitte warten..."
            @sdk-init="handleSdkInit"
            @login="fbLogin($event)"
            @logout="fbLogout($event)"
            style="width: 100%"
            class="elevation-9"
          >
            <template v-slot:login>
              Mit Facebook {{ $route.query.ad ? 'fortfahren' : 'anmelden' }}
            </template>
            <template v-slot:logout>
              Abmelden
            </template>
            <template v-slot:working>
              Bitte warten...
            </template>
          </v-facebook-login> -->

          <!-- Login With Google -->
          <!-- <unvergessen-google-login :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></unvergessen-google-login> -->

          <!-- <unvergessen-button :lightBackground="true" style="border-radius: 5px; margin-top: 20px; min-height: 40px !important" :mobile="true" @click="mailRegistration = !mailRegistration">
            Mit E-Mail-Adresse {{ $route.query.ad ? 'fortfahren' : 'registrieren' }}
          </unvergessen-button> -->

          <v-expand-transition>
            <div v-if="mailRegistration || true" style="margin-top: 40px">
              <v-form @submit.prevent="submit" onSubmit="return false;"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <!-- Prename & Surname -->
                <v-row>
                  <v-col cols="6" style="padding: 0 12px">
                    <!-- Prename -->
                    <v-text-field
                      class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      label="Vorname"
                      outlined
                      required
                      :rules="nonEmptyRules"
                      v-model="prename"
                      placeholder="Dein Vorname"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" style="padding: 0 12px">
                    <!-- Surname -->
                    <v-text-field
                      class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      label="Nachname"
                      outlined
                      required
                      :rules="nonEmptyRules"
                      v-model="surname"
                      placeholder="Dein Nachname"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <!-- Birth & Sex -->
                <!-- <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateFormatted"
                      @keydown="menu=false"
                      class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                      label="Geburtsdatum"
                      hint=""
                      placeholder="Dein Geburtsdatum (TT.MM.JJJJ)"
                      outlined
                      required
                      :rules="dateRule"
                      @blur="date = parseDate(dateFormatted)"
                      :max="formatDate(new Date().toISOString().substr(0, 10))"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker min="1000-01-01" ref="picker" locale="de" v-model="date" :max="new Date().toISOString().substr(0, 10)" @change="save"></v-date-picker>
                </v-menu> -->

                <v-text-field
                  class="font-weight-regular"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  label="Email"
                  :rules="emailRules"
                  type="email"
                  outlined
                  required
                  :error-messages="errorList"
                  v-model="email"
                  placeholder="Deine E-Mail-Adresse"
                ></v-text-field>

                <v-text-field
                  class="font-weight-regular"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  label="Passwort"
                  :rules="pwdRules"
                  :append-icon="!showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                  counter
                  outlined
                  required
                  v-model="pwd"
                  placeholder="Passwort"
                ></v-text-field>

                <!-- <v-text-field
                  class="font-weight-regular"
                  :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                  :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  label="Passwort erneut"
                  :rules="pwdRules"
                  :append-icon="!showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                  counter
                  outlined
                  required
                  v-model="pwdAgain"
                  placeholder="Passwort erneut eingeben"
                ></v-text-field> -->

                <!-- <v-radio-group v-model="gender" required :rules="nonEmptyRules" row>
                  <v-radio value="male">
                    <template v-slot:label>
                      <p class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin: auto">Männlich</p>
                    </template>
                  </v-radio>
                  <v-radio value="female">
                    <template v-slot:label>
                      <p class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin: auto">Weiblich</p>
                    </template>
                  </v-radio>
                  <v-radio value="indeterminate">
                    <template v-slot:label>
                      <p class="font-weight-regular"
                      :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }"
                      :dense="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" style="margin: auto">Divers</p>
                    </template>
                  </v-radio>
                </v-radio-group> -->

                <v-checkbox
                  v-model="agb"
                  required
                  :rules="nonEmptyRules"
                  style="margin: 0"
                >
                  <template v-slot:label>
                    <div class="text--text" :style="{ 'font-size': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '12px' : '' }">
                      Ich habe die
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <a target="_blank" class="text--text" style="text-decoration: underline" href="/Nutzungsbedingungen" @click.stop v-on="on">Nutzungsbedingungen</a>
                        </template>
                        <p>
                          Öffnet sich in einem neuen Fenster.
                        </p>
                      </v-tooltip>
                      und die
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <a target="_blank" class="text--text" style="text-decoration: underline" href="/Datenschutz" @click.stop v-on="on">Datenschutzerklärung</a>
                        </template>
                        <p>
                          Öffnet sich in einem neuen Fenster.
                        </p>
                      </v-tooltip>
                      gelesen und stimme diesen zu.
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="newsletter" style="margin: -15px 0 0 0">
                  <template v-slot:label>
                    <p class="text--text" style="padding-top: 15px" :style="{ 'font-size': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '12px' : '' }">
                      Ich möchte über Neuigkeiten informiert werden.
                    </p>
                  </template>
                </v-checkbox>
                <unvergessen-button @click="register()" :green="true" :mobile="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" :style="{ 'margin-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '-20px' : '' }">Registrieren</unvergessen-button>
              </v-form>
            </div>
          </v-expand-transition>
            <v-divider style="margin-top: 0.3in"></v-divider>
            <div style="padding: 0 0.1in">
              <v-row>
                <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
                  Du hast schon ein Konto?
                </p>
                <v-spacer></v-spacer>
                <p class="font-weight-regular primary--text" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="cursor: pointer" @click="dialog = false; (persistent || onpage) ? $emit('change') : $emit('close')">
                  Anmelden
                </p>
              </v-row>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <!-- Nested Dialog -->
    <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="enterAuth" max-width="700px" persistent>
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="closeConfirm()">mdi-close</v-icon>
        </div>
        <div style="padding: 4% 10% 4% 10%">
          <v-card-title>
            <p class="unvergessen-dialog-heading" :class="{ 'display-1': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">Email-Adresse bestätigen</p>
          </v-card-title>
          <v-card-text style="padding: 0">
            <v-form @submit.prevent="submit" onSubmit="return false;"
              ref="AuthcodeForm"
              v-model="valid"
              lazy-validation
            >
              <p class="font-weight-regular" :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" style="text-align: center; margin-bottom: 0.3in">
                Wir haben dir soeben an {{ mail }} eine E-Mail gesendet. Bitte überprüfe deinen E-Mail Posteingang und klicke auf "E-Mail-Adresse bestätigen", um deine E-Mail-Adresse zu bestätigen.
              </p>
              <v-alert type="success" v-model="resendMail">
                Wir haben dir erneut eine E-Mail gesendet
              </v-alert>
              <v-alert type="error" v-model="resendError">
                Wir konnten dir keine E-Mail senden. Bitte versuche es erneut
              </v-alert>
              <div style="padding: 0 1in">
                <unvergessen-button @click="closeConfirm();">Weiter</unvergessen-button>
              </div>
              <p style="text-align: center; margin-top: 0.5in; margin-bottom: 0.1in">
                Du hast keine E-Mail erhalten? Überprüfe bitte, ob die E-Mail-Adresse korrekt geschrieben wurde oder ob die E-Mail vielleicht in deinem Spam-Ordner gelandet ist.
              </p>
              <v-card-actions>
                <a :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="dialog = true; enterAuth = false; registerError = false">E-Mail-Adresse korrigieren</a>
                <v-spacer></v-spacer>
                <a :class="{ title: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" @click="sendMail()">E-Mail erneut senden</a>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'
import axios from 'axios'
export default {
  props: ['propsdialog', 'persistent', 'firstname', 'onpage', 'here'],
  data (vm) {
    return {
      valid: false,
      dialog: false,
      prename: '',
      surname: '',
      email: '',
      pwd: '',
      pwdAgain: '',
      gender: '',
      agb: false,
      newsletter: false,
      enterAuth: false,
      registerError: false,
      resendMail: false,
      resendError: false,
      errorList: [],
      loginData: {},
      mailRegistration: false,
      // date picker
      date: null,
      dateFormatted: null,
      menu: false,
      showPwd: false,
      // Facebook Login
      appId: process.env.VUE_APP_FB_APP_ID,
      model: {},
      // rules
      emailRules: [
        v => !!v || 'E-Mail-Adresse wird benötigt',
        v => /.+@.+\..+/.test(v) || 'Bitte gib eine valide E-Mail-Adresse ein'
      ],
      pwdRules: [
        v => !!v || 'Passwort wird benötigt',
        v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen enthalten'
      ],
      nonEmptyRules: [
        v => !!v || 'Eintrag wird benötigt'
      ]//,
      // dateRule: [
      //   v => {
      //     if (this.date === undefined) return true
      //     if (v !== null) {
      //       var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
      //       if (!format.test(v)) {
      //         return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
      //       }
      //       var today = new Date()
      //       var date = v.split('.')
      //       if (today.getFullYear() - date[2] < 18 && today.getFullYear() - date[2] >= 0) return 'Du musst mindestens 18 Jahre alt sein, um eine Gedenkseite erstellen zu können'
      //       if (date[1] === '02' && parseInt(date[0]) > 29) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
      //       if (date[1] === '02' && parseInt(date[0]) > 28 && parseInt(date[2]) % 4 !== 0) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
      //       if (parseInt(date[0]) > 30 && (date[1] === '04' || date[1] === '06' || date[1] === '09' || date[1] === '11')) return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ)'
      //       date = new Date(date[2], date[1] - 1, date[0])
      //       return today.getTime() - date.getTime() > 0 || 'Bitte gib ein Datum in der Vergangenheit ein'
      //     } else {
      //       return 'Eintrag wird benötigt'
      //     }
      //   }
      // ]
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    FB () {
      return this.$store.getters.fb.FB
    },
    scope () {
      return this.$store.getters.fb.scope
    },
    mail () {
      return this.email !== '' ? this.email : this.$store.getters.email
    },
    createData () {
      return this.$store.getters.createData
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  },
  created () {
    this.dialog = (this.propsdialog) ? this.propsdialog : this.dialog
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    // -----------   Facebook Login   -----------
    handleSdkInit ({ FB, scope }) {
      this.$store.commit('setFb', scope)
      this.$store.commit('setFbScope', scope)
    },
    fbLogin (e) {
      axios.post('/auth/loginFB', { data: e })
        .then(res => {
          if (!res.data.first) {
            var data = {
              u_id: res.data.u_id,
              token: res.data.token,
              keepMeLoggedIn: false
            }
          }

          this.$store.dispatch('setRenewTimer', { time: 7200, keepMeLoggedIn: false })
          if (!res.data.first) {
            this.$store.commit('login', data)
          }

          this.$store.dispatch('loadUser')
          if (this.createData.page_id) {
            axios.post('/page/createFromAd', { page_id: this.createData.page_id })
              .then(res => {
                var tok = jwtDecode(localStorage.unvergessenToken)
                this.$store.dispatch('renewAuth', { page_id: this.createData.page_id, keepMeLoggedIn: tok.exp - tok.iat > 10000 })
                this.$store.dispatch('setCreateData', {})
                this.$store.dispatch('loadUser')
              })
              .catch(error => {
              })
          } else {
            if (res.data.first) {
              this.enterAuth = true
              this.dialog = false
              this.loginData = res.data
              this.registerError = false
              this.errorList = []
            } else {
              if (this.onpage) {
                this.$emit('guard')
                this.$emit('RegisterDialog')
              } else {
                this.$emit('guard')
                this.$router.push({ name: 'Account' }).catch(error => {})
              }
            }
          }
        })
        .catch(error => {
          this.$store.commit('logout')
        })
    },
    fbLogout (e) {
    },
    // ----------------------------------------
    register () {
      this.valid = this.$refs.form.validate()
      if (this.valid && this.agb) {
        var data = {
          prename: this.prename,
          surname: this.surname,
          mail: this.email,
          pwd: this.pwd,
          gender: this.gender,
          subscribe: this.newsletter,
          date: this.date
          // age: 20
        }
        this.$store.dispatch('register', data)
          .then(res => {
            window.fbq('track', 'CompleteRegistration', { currency: 'EUR', value: 0, content_name: 'Registration' })
            this.$ga.event('Onboarding', 'CompleteRegistration', this.email, 0)
            this.dialog = false
            this.loginData = res.data
            this.registerError = false
            this.errorList = []
            if (this.onpage) {
              this.closeConfirm()
            } else {
              this.enterAuth = true
            }
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 409) {
                this.errorList.push('Die E-Mail-Adresse wurde schon einmal verwendet. Bitte wähle eine andere E-Mail-Adresse oder melde dich an')
              } else {
                this.registerError = true
              }
            }
          })
      }
    },
    closeConfirm () {
      this.$store.commit('login', this.loginData)
      if (this.persistent) {
        this.$emit('dialog')
      } else {
        this.$emit('RegisterDialog')
        this.$emit('closeParent')
      }
      this.enterAuth = false
      this.dialog = false
      this.reset()
    },
    reset () {
      this.prename = ''
      this.surname = ''
      this.email = ''
      this.pwd = ''
      this.gender = ''
      this.agb = false
      this.newsletter = false
      this.date = null
    },
    sendMail () {
      this.registerError = false
      axios.post('/auth/resendMail', { mail: this.mail })
        .then(res => {
          this.resendMail = true
          this.resendError = false
        })
        .catch(error => {
          this.resendMail = false
          this.resendError = true
        })
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>
