<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 2560 150.525" :style="{ 'background-color': background }" style="position: relative" preserveAspectRatio="xMaxYMin meet">
    <path :style="{ fill: fill }" d="M0,44.206S462.409-23.5,730.984-23.5c265.349,0,299.659,12.267,877.653,42.567S2560-45.582,2560-45.582v1476.7H0Z" transform="translate(0 45.582)"/>
  </svg>
</template>

<script>
export default {
  props: ['background', 'fill']
}
</script>
