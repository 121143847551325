<template>
  <div>
    <v-card width="100%" class="unvergessen-shadow" style="border-radius: 10px">
      <!-- Header -->
      <div style="text-align: center; background-image: url('https://eternio-static-loads.s3.eu-central-1.amazonaws.com/candle/Kerze_Drei_Teelichter_Color.jpg')" class="secondary" :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm), title: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }">
        <div style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8); padding: 20px 0; border-radius: 10px 10px 0 0">
          Kategorien
        </div>
      </div>

      <div v-for="(category, i) in categories" :key="i" @click="$router.push({ name: category.to })">
        <div class="title font-weight-regular" style="padding: 15px 0; text-align: center; cursor: pointer">
          {{ category.name }}
        </div>
        <v-divider class="grey3"></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      categories: [
        // {
        //   name: 'Aktuelle Trauerfälle',
        //   to: 'ExploreRecent'
        // },
        {
          name: 'Gedenkseiten',
          to: 'ExploreNewPages'
        },
        {
          name: 'Sternenkinder',
          to: 'ExploreKids'
        },
        {
          name: 'Bekannte Persönlichkeiten',
          to: 'ExploreFamous'
        },
        {
          name: 'Gedenktage',
          to: 'ExploreMemorialDays'
        }
      ]
    }
  }
}
</script>
